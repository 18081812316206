ft.app.controller('userProfileController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', 'DataService', 'AppService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, DataService, AppService) {

            var initialized = false;

            $scope.appData = AppService.data;
            $scope.data = {
                user: null
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

                $scope.initializeData();
                $rootScope.$on(ft.app.events.family.get, $scope.initializeData);
            };

            $scope.initializeData = function () {
                $scope.data.user = AppService.getUser($stateParams.user);
            };

            $timeout($scope.initialize);

        }
    ]);


ft.app.controller('userInfoController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', 'DataService', 'AppService', 'ModalService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, DataService, AppService, ModalService) {

            var initialized = false;
            $rootScope.checkForFormChange = true;

            $scope.appData = AppService.data;

            if (!$scope.data) {
                $scope.data = {};
            }

            $scope.data.success = $stateParams.success;
            $scope.data.contactInformation = ft.app.contactInformation;

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $scope.openPasswordChange = function (evt) {
                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'user/passwordchange',
                    controller: 'passwordChangeController'
                }).then(function (modal) {
                });
            };

            $scope.switchLanguage = function (lang) {
                AppService.switchLanguage(lang);
            };

            $timeout($scope.initialize);

        }
    ]);

ft.app.controller('passwordChangeController',
    ['$scope', '$element', 'close', '$timeout', '$translate', 'DataService', 'CheckStateChangeService',
        function ($scope, $element, close, $timeout, $translate, DataService, CheckStateChangeService) {

            var initialized = false;

            CheckStateChangeService.checkFormOnStateChange($scope, 'passwordForm');

            $scope.data = {
                isLoading: false,
                currentPassword: '',
                newPassword: '',
                verifyPassword: '',
                currentPasswordValid: true,
                newPasswordValid: true,
                verifyPasswordValid: true,

                errorText: '',
                successText: '',
                attempts: 0
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $scope.save = function (evt) {

                evt.target.blur();

                var data = $scope.data;
                data.errorText = '';
                data.currentPasswordValid = true;
                data.newPasswordValid = true;
                data.verifyPasswordValid = true;

                var cp = data.currentPassword.toString().trim();
                var np = data.newPassword.toString().trim();
                var vp = data.verifyPassword.toString().trim();

                data.currentPasswordValid = cp;
                data.newPasswordValid = np;
                data.verifyPasswordValid = vp;

                if (np != vp) {
                    data.errorText = $translate.instant('CHANGE-PASSWORD.ERROR-NEW-VERIFY-DIFFERENT');
                    return;
                }

                if (!data.currentPasswordValid || !data.newPasswordValid || !data.verifyPasswordValid) {
                    return;
                }

                data.isLoading = true;
                var prm = DataService.changePasswordAsync(data.currentPassword, data.newPassword);
                prm.then($scope.handleChangePassword, $scope.handleChangePasswordError);
                prm.finally(function () {
                    $scope.data.isLoading = false;
                });

            };

            $scope.handleChangePassword = function (results) {
                $scope.data.successText = $translate.instant('CHANGE-PASSWORD.SAVED');
            };

            $scope.handleChangePasswordError = function (response) {

                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorText = $translate.instant('CHANGE-PASSWORD.ERROR-CONTINUES');
                } else {
                    $scope.data.errorText = $translate.instant('CHANGE-PASSWORD.ERROR-SAVE-PASSWORD');
                }

                $scope.data.errorText += response && !response.result && response.isReadable ? ' ' + response.message : '';

            };

            $scope.close = function () {
                close({});
            };

            $scope.cancel = function () {
                close();
            };

            $timeout($scope.initialize);

        }
    ]);
