ft.app.filter('GetPlanningStatusName',
    ['$translate',
        function ($translate) {
            return function (status) {

                var states = ft.app.planning.states;

                switch (status) {
                    case states.approved:
                        return $translate.instant('PLANNING-FILTER.APPROVED');
                        break;
                    case states.rejected:
                        return $translate.instant('PLANNING-FILTER.REJECTED');
                        break;
                    case states.requestable:
                        return $translate.instant('PLANNING-FILTER.REQUESTABLE');
                        break;
                    case states.requested:
                        return $translate.instant('PLANNING-FILTER.REQUESTED');
                        break;
                }

            };
        }]);

ft.app.filter('GetPlanningTypeName',
    ['$translate',
        function ($translate) {
            return function (type) {

                var types = ft.app.planning.types;

                switch (type) {
                    case types.absent:
                        return $translate.instant('PLANNING-FILTER.ABSENT');
                        break;
                    case types.incidental:
                        return $translate.instant('PLANNING-FILTER.INCIDENTAL');
                        break;
                    case types.planning:
                        return $translate.instant('PLANNING-FILTER.REGULAR');
                        break;
                    case types.study:
                        return $translate.instant('PLANNING-FILTER.STUDY');
                        break;
                    case types.swap:
                        return $translate.instant('PLANNING-FILTER.SWAP');
                        break;
                    case types.vacation:
                        return $translate.instant('PLANNING-FILTER.HOLIDAY');
                        break;
                }
            };
        }]);

ft.app.filter('GetPlanningPresenceName',
    ['$translate',
        function ($translate) {
            return function (presence) {

                var presences = ft.app.planning.presence;

                switch (presence) {
                    case presences.absent:
                        return $translate.instant('PLANNING-FILTER.ABSENT');
                        break;
                    case presences.holiday:
                        return $translate.instant('PLANNING-FILTER.HOLIDAY');
                        break;
                    case presences.sick:
                        return $translate.instant('PLANNING-FILTER.SICK');
                        break;
                    case presences.present:
                        return $translate.instant('PLANNING-FILTER.PRESENT');
                        break;
                }

            };
        }]);
