ft.app.service('CheckStateChangeService', ['$rootScope', '$state', '$translate', 'ModalService',
    function ($rootScope, $state, $translate, ModalService) {

        var addFormCheck = function ($scope, formName) {

            $rootScope.checkForFormChange = true;

            var removeListener = $rootScope.$on('$stateChangeStart', function (evt, toState, toParams, fromState, fromParams) {

                if (!$scope[formName]) {
                    return;
                }

                if ($scope[formName].$pristine || !$rootScope.checkForFormChange) {
                    return;
                }

                ModalService.showModal({
                    templateUrl: 'confirm-modal',
                    controller: 'confirmModalController',
                    inputs: {
                        question: $translate.instant('MODAL-CONFIRM.DATA-CHANGED')
                    }
                }).then(function (modal) {
                    modal.close.then(function (results) {

                        if (results.confirmed) {
                            $rootScope.checkForFormChange = false;
                            $state.go(toState, toParams);
                        } else {
                            return;
                        }

                    });
                });

                evt.preventDefault();

            });

            $scope.$on('$destroy', removeListener);

        };

        return {checkFormOnStateChange: addFormCheck};
    }]);
