import { UrlHelper } from '@js/urlHelper';
import * as moment from 'moment';
import { ft, ng } from '@js/definitions';
import { AppService, IAppData } from '@js/services/FT.app.services.appservice';
import {
    DiaryTimelineResponse, MediaTimelineResponse,
    MediaType,
    PostTimeLineResponse, PresenceTimelineItem, StoryTimelineItem, StoryTimelineResponse
} from '@js/models';
import { IScope, IQService, ITimeoutService, IPromise } from 'angular';
import { DataService } from '../services/FT.app.services.dataservice';
import { MediaReference } from '../models';

export type VideoMediaReference = MediaReference & { videoUrl: string };

enum TimelineTypes { Presence, Post, Diary, Media, Story }

interface Timeline {
    date: Date;
    itemDate: Date;
    childId: string;
    type: TimelineTypes;
    item: any;
}

interface LocalScope extends IScope {
    handleGoto: (anchor: any) => void;
    handleFilterChanged: () => void;
    handleMoreData: () => void;
    toggleShowPresenceItems: () => void;
    toggleMedia: (timelineItem: any) => void;
    toggleComments: (timelineItem: any) => void;
    toggleFavorite: (item: any) => void;
    saveComment: (item: any) => void;
    handleSaveCommentError(reason: any);
    findChild: (childId: string) => string;
    isLoading: boolean;
    appData: IAppData;
    hasMoreItems: boolean;
    showPresenceInTimeline: boolean;
    types: typeof TimelineTypes;
    data: {
        mediaConfig: any;
        activityConfig: any;
        groups: any;
        childId: string;
        items: Timeline[];
        messages: {};
        valid: {};
        errorMessage: string;
    };
    getStoryDownloadLink: (item: StoryTimelineItem) => void;
    storyPdfLinks: string[];
    hasStories: boolean;
}

ft.app.controller('appDashboardController',
    ['$scope', '$q', '$timeout', '$filter', '$translate', 'DataService', 'AppService', '$sessionStorage', '$location', '$anchorScroll',
        function ($scope: LocalScope, $q: IQService, $timeout: ITimeoutService, $filter, $translate, DataService: DataService, AppService: AppService, $sessionStorage, $location, $anchorScroll) {

            var groupByFilter = $filter('GroupByFilter');
            var initialized = false;

            var attempts = 0;
            var maxAttempts = 4;
            var minimalDays = 31;
            var maximumDays = 365 * 2;
            var amountOfDays = 31;
            var start = null;
            var end = null;

            $scope.isLoading = false;
            $scope.appData = AppService.data;
            $scope.hasMoreItems = true;
            $scope.showPresenceInTimeline = false;
            $scope.types = TimelineTypes;
            $scope.storyPdfLinks = [];
            $scope.hasStories = false;

            

            $scope.data = {
                mediaConfig: ft.app.media.types,
                activityConfig: ft.app.activities,
                groups: null,
                childId: null,
                items: [],
                messages: {},
                valid: {},
                errorMessage: ''
            };

            var initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

                getData();

            };

            $scope.handleGoto = function (anchor) {

                $location.hash(anchor);
                $anchorScroll();

            };

            $scope.handleFilterChanged = function () {
                attempts = 0;
                start = null;
                end = null;
                $scope.data.items = [];
                $scope.data.groups = [];
                getData();
            };

            $scope.handleMoreData = function () {
                getData();
            };

            
            

            var getData = function () {

                $scope.isLoading = true;

                end = start ? ng.copy(start) : new Date();
                if (start) {
                    end.setDate(end.getDate() - 1);
                }

                start = ng.copy(end);
                start.setDate(start.getDate() - amountOfDays);

                var mStart = moment(start);
                var mEnd = moment(end);

                const childId = $scope.data.childId || null;

                const prmPostTimeline = DataService.getTimelineForPosts(childId, mStart, mEnd);
                const prmActivities = DataService.getTimelineForMediaItems(childId, mStart, mEnd);
                const prmPresences = DataService.getTimelineForPresences(childId, mStart, mEnd);
                const prmDiaries = DataService.getTimelineForDiaries(childId, mStart, mEnd);
                let prmStories: IPromise<any>;
                if ($scope.hasStories) {
                    prmStories = DataService.getTimelineForStories(childId, mStart, mEnd);
                }

                const prms = $q.all([prmPostTimeline, prmActivities, prmPresences, prmDiaries, prmStories]);
                prms.then(handleGetData, handleGetDataError);
                prms.finally(function () {
                    $scope.isLoading = false;
                });

            };

            var handleGetData = function (results) {
                let data = $scope.data;

                if (!data.groups) {
                    data.groups = [];
                }

                const newItems = new Array<Timeline>();
                const postResult = results[0] as PostTimeLineResponse;
                const mediaResult = results[1] as MediaTimelineResponse;
                const presenceResult = results[2] as PresenceTimelineItem[];
                const diaryResult = results[3] as DiaryTimelineResponse;
                let storyResult: StoryTimelineResponse;
                if (results.length > 4) {
                    storyResult = results[4] as StoryTimelineResponse;
                } 

                if (mediaResult && mediaResult.albums.length) {
                    const albums = mediaResult.albums;
                    const items: Timeline[] = [];

                    for (const album of albums) {
                        album.posterUrl = createUrl(album.posterUrl);

                        (album as any).icon = album.posterUrl;
                        for (const media of album.media) {
                            if (media.type == MediaType.video) {
                                (media as VideoMediaReference).videoUrl = media.url;
                                media.url = createUrl(media.poster);
                            } else {
                                media.url = createUrl(media.url);
                            }
                            media.poster = createUrl(media.poster);
                        }

                        let m = moment(album.on);
                        let date = m.format('YYYY-MM-DD');

                        items.push({
                            date: date as unknown as Date,
                            itemDate: album.on,
                            type: TimelineTypes.Media,
                            item: album,
                            childId: album.media[0].childId
                        });
                    }

                    newItems.push(...items);
                }

                if (storyResult && storyResult.storyEntryItems.length) {
                    let storyEntryItems = storyResult.storyEntryItems;
                    const items: Timeline[] = [];

                    for (const storyEntry of storyEntryItems) {
                        let m = moment(storyEntry.on);
                        let date = m.format('YYYY-MM-DD');

                        items.push({
                            date: date as unknown as Date,
                            itemDate: storyEntry.on,
                            type: TimelineTypes.Story,
                            item: storyEntry,
                            childId: storyEntry.childId
                        });
                        $scope.getStoryDownloadLink(storyEntry);
                    }

                    newItems.push(...items);
                }

                if (diaryResult && diaryResult.diaryEntryItems.length) {
                    let diaryEntryItems = diaryResult.diaryEntryItems;
                    const items: Timeline[] = [];

                    for (const diaryEntry of diaryEntryItems) {
                        let m = moment(diaryEntry.on);
                        let date = m.format('YYYY-MM-DD');

                        items.push({
                            date: date as unknown as Date,
                            itemDate: diaryEntry.on,
                            type: TimelineTypes.Diary,
                            item: diaryEntry,
                            childId: diaryEntry.childId
                        });
                    }

                    newItems.push(...items);
                }

                if (presenceResult) {
                    var presences = convertItems(presenceResult, TimelineTypes.Presence, function (item) {
                        return item.date.split('T')[0] + 'T' + item.startTime;
                    }, null);

                    newItems.push(...presences);
                }

                if (postResult && postResult.posts) {
                    let posts = postResult.posts;
                    const items = [];

                    posts.forEach(function (result) {
                        var m = moment(result.on);
                        const date = m.format('YYYY-MM-DD');

                        items.push({
                            date: date,
                            itemDate: result.on,
                            type: TimelineTypes.Post,
                            item: result,
                            childId: result.childId
                        });
                    });

                    newItems.push(...items);
                }

                // all items
                data.items = data.items.concat(newItems);
                filterGroups();
            };

            var handleGetDataError = function () {
                $scope.data.errorMessage = $translate.instant('CHILD-TIMELINE.ERROR-GET-ITEMS');
            };

            const convertItems = function (results, type: TimelineTypes, fncFormatDate, dateFormat): Timeline[] {

                const items: Timeline[] = [];

                for (const result of results) {

                    const itemDate = fncFormatDate(result);
                    const m = moment(itemDate);
                    const date = m.format(dateFormat || 'YYYY-MM-DD');

                    const obj = {
                        date: date as unknown as Date,
                        itemDate: itemDate,
                        type: type,
                        item: result,
                        childId: result.childId
                    } as Timeline;

                    items.push(obj);
                }

                return items;
            };

            const groupItems = function (items: Timeline[]) {
                if (items == null || items.length === 0) {
                    return;
                }

                var groups = groupByFilter(items, 'date');

                groups.sort((a, b) => ft.dates.compare(b.title, a.title));

                for (const group of groups) {
                    group.items.sort((a, b) => ft.dates.compare(b.itemDate, a.itemDate));
                }

                return groups;
            };

            const filterGroups = function () {
                if ($scope.showPresenceInTimeline) {
                    $scope.data.groups = groupItems($scope.data.items);
                } else {
                    $scope.data.groups = groupItems($scope.data.items.filter(x => x.type != TimelineTypes.Presence));
                }
            };

            $scope.toggleShowPresenceItems = function () {
                $scope.showPresenceInTimeline = !$scope.showPresenceInTimeline;
                filterGroups();
            };

            $scope.toggleMedia = function (timelineItem) {
                timelineItem.mediaChecked = !timelineItem.mediaChecked;
            };

            $scope.toggleComments = function (timelineItem) {
                timelineItem.commentsChecked = !timelineItem.commentsChecked;
            };

            $scope.toggleFavorite = function (item) {

                item.isFavorited = !item.isFavorited;

                $scope.isLoading = true;
                var prm = DataService.toggleFavoriteAsync(item.key, item.id, AppService.data.currentUser.id);
                prm.then(() => {}, () => { item.isFavorited = false; });
                prm.finally(function () {
                    $scope.isLoading = false;
                });
            };

            $scope.saveComment = function (item) {
                var msg = $scope.data.messages[item.id];

                if (!msg) {
                    $scope.data.valid[item.id] = $translate.instant('CHILD-TIMELINE.COMMENT-REQUIRED');
                    return;
                }

                if ($scope.data.valid[item.id]) {
                    $scope.data.valid[item.id] = '';
                }

                DataService.saveActivityCommentAsync(item.key, item.id, AppService.data.currentUser.id, msg).then(() => {}, $scope.handleSaveCommentError);

                if (!item.comments) {
                    item.comments = [];
                }

                item.comments.push({
                    'createdOn': Date.now(),
                    'createdBy': {
                        'fullName': AppService.data.currentUser.name.fullName,
                        'personType': 'parent'
                    },
                    'body': msg
                });

                $scope.data.messages[item.id] = '';
            };

            $scope.handleSaveCommentError = function (reason: any) {
                $scope.data.errorMessage = reason && !reason.result && reason.isReadable ? reason.message : $translate.instant('CHILD-TIMELINE.ERROR-SAVE-COMMENT');
            };

            $scope.findChild = (childId: string): string => {
                if (childId == null || $scope.appData.children == null || $scope.appData.children.length === 0) {
                    return null;
                }

                const child = $scope.appData.children.find(x => x.id === childId);

                if (child == null) {
                    return null;
                }

                return child.name.firstName;
            };

            function createUrl(url: string) {

                const encodedToken = encodeURIComponent($sessionStorage.token.value);

                let absoluteUrl = url;

                if (!UrlHelper.isUrlAbsolute(absoluteUrl)) {
                    absoluteUrl = UrlHelper.createAbsoluteMediaUrl(AppService.data.services['media'], url);
                }

                return `${absoluteUrl}?access_token=${encodedToken}`;
            }

            DataService.getFeatures().then(x => {
                $scope.hasStories = x.features.some(y => y.code === 'stories');

                if ($scope.hasStories) {
                    $scope.getStoryDownloadLink = function(item: StoryTimelineItem) {
                        DataService.getStoryPdf(item.storyId, item.childId, true).then(function(url: string) {
                            $scope.storyPdfLinks[item.storyId] = url;
                        });
                    };
                }
                $timeout(initialize);

            });

        }
    ]);
