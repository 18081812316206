ft.app.directive('ftImageUpload', function () {
    return {
        scope: {
            onSave: '='
        },
        link: function (scope, elm) {
            $(elm).html5imageupload({
                onSave: scope.onSave
            });
        }
    };

});
