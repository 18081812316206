ft.defineNamespace('FortyTwo.dates');

ft.dates.compare = function (a, b) {

    var aDate = new Date(a);
    var bDate = new Date(b);

    if (aDate < bDate) {
        return -1;
    }
    if (aDate > bDate) {
        return 1;
    }
    return 0;

};

ft.dates.isDateInFuture = function (date, includeToday) {
    var input = new Date(date).setHours(0, 0, 0, 0);
    var today = new Date().setHours(0, 0, 0, 0);

    if (includeToday) {
        return input >= today;
    } else {
        return input > today;
    }
};

Date.getFirstPossible = function (possibles, date) {

    var i;

    var today = date ? new Date(date) : new Date();
    today.setHours(0, 0, 0, 0); // only check date, not time

    var result = date ? new Date(date) : new Date();
    result.setHours(0, 0, 0, 0); // only check date, not time

    for (i = 0; i < possibles.length; i++) {
        result.setDate(possibles[i]);

        // date found where result is later then today
        if (today < result) {
            return result;
        }

        // didn't found a first possible this month, check next month and reset possibles
        if (i == possibles.length - 1) {
            result.setMonth(result.getMonth() + 1);
            i = -1;
        }
    }


};
