ft.app.controller('presentModalController',
    ['$scope', '$element', 'close', 'item', '$timeout', '$translate', 'DataService', 'CheckStateChangeService',
        function ($scope, $element, close, item, $timeout, $translate, DataService, CheckStateChangeService) {

            CheckStateChangeService.checkFormOnStateChange($scope, 'presentForm');

            var initialized = false;
            item = item || {};

            $scope.data = {
                attempts: 0,
                saveEnabled: true,
                saving: false,
                format: 'dd-MM-yyyy',
                now: new Date(),
                from: null,
                fromOpened: false,
                fromValid: true,
                dateOptions: {
                    formatYear: 'yy'
                }
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                $scope.data.from = item.from ? new Date(item.from) : null;

                initialized = true;
            };

            $scope.open = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.data.fromOpened = true;
            };

            $scope.save = function () {

                var apiFrom;
                $scope.data.errorMessage = '';
                $scope.data.successMessage = '';
                $scope.data.fromValid = true;

                // if from is not defined or not valid if defined
                if (!$scope.data.from) {
                    $scope.data.fromValid = false;
                } else {
                    apiFrom = moment($scope.data.from).format(ft.app.datetime.formats.toAPIDate);

                    if (!moment(apiFrom).isValid()) {
                        $scope.data.fromValid = false;
                    } else if (new Date(apiFrom).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                        $scope.data.fromValid = false;
                    }
                }

                if (!$scope.data.fromValid) {
                    return;
                }

                // start from today and by today
                var d = new Date($scope.data.from);
                //d.setDate(d.getDate() - 1);
                apiFrom = moment(d).format(ft.app.datetime.formats.toAPIDate);

                $scope.data.saving = true;
                var prm = DataService.savePresentAsync(item.child, apiFrom);
                prm.then($scope.handleSave, $scope.handleSaveError);
                prm.finally(function () {
                    $scope.data.saving = false;
                    $scope.close();
                });
            };

            $scope.handleSave = function (results) {
                $scope.data.saveEnabled = false;
                $scope.data.attempts = 0;
                $scope.data.successMessage = $translate.instant('PLANNING-PRESENT.SAVED');
            };

            $scope.handleSaveError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('PLANNING-PRESENT.ERROR-CONTINUES');
                } else {
                    $scope.data.errorMessage = $translate.instant('PLANNING-PRESENT.ERROR-SAVE-PRESENT');
                }

                if (response && !response.result && response.isReadable) {
                    $scope.data.errorMessage += ' ' + response.message;
                }
            };

            $scope.close = function () {
                if (!$scope.data.saving) {
                    ($scope.data.saveEnabled) ? close() : close({});
                }
            };

            $scope.cancel = function () {
                if (!$scope.data.saving) {
                    close();
                }
            };

            $timeout($scope.initialize);

        }]);