ft.app.controller('messagesController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$filter', '$translate', 'DataService', 'AppService', 'LoginService', '$uibModal',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $filter, $translate, DataService, AppService, LoginService, $uibModal) {
            var initialized = false;
            var lastFetchDate = null;

            $scope.isLoading = false;
            $scope.loginService = LoginService;

            $scope.conversations = [];
            $scope.messageLimit = 3;
            $scope.messageListView = true;
            $scope.selectedConversation = null;
            $scope.replyComment = null;

            $scope.message = '';

            var initialize = function () {
                if (initialized) {
                    return;
                }

                getData();
                initialized = true;
            };

            function getData() {
                $scope.isLoading = true;

                var promises = [];
                promises.push(DataService.getMessageAudiencesAsync());
                promises.push(getMessagesAsync(false));

                var promise = $q.all(promises);
                promise.then(handleGetData, handleGetDataError);
                promise.finally(function () {
                    $scope.isLoading = false;
                });
            }

            var getMessagesAsync = function (loadMore) {
                var dateTime = moment().utc().format('YYYY-MM-DDTHH-mm-ss');
                if (!lastFetchDate) {
                    lastFetchDate = dateTime;
                    return DataService.getMessagesAsync(lastFetchDate);
                }

                if (loadMore) {
                    var lastConvo = $scope.conversations[$scope.conversations.length - 1];
                    return DataService.getMessagesAsync(lastConvo.lastUpdatedUTC);
                }

                var returnValue = DataService.getMessagesSinceAsync(lastFetchDate);
                lastFetchDate = dateTime;
                return returnValue;
            };

            var handleGetData = function (results) {
                // Set message audiences
                $scope.messageAudiences = results[0].groups;

                // Process messages
                handleGetMessages(results[1]);
            };

            var handleGetDataError = function () {

            };

            var handleGetMessages = function (results) {
                if (!results.posts) {
                    results = results[0];
                }

                if ($scope.conversations.length === 0) {
                    $scope.conversations = results.posts;
                } else {
                    var posts = results.posts;

                    if (posts.length === 0) {
                        console.log('There were no new messages...');
                        return;
                    }

                    if ($scope.selectedConversation) {
                        $scope.sendNotification = false;
                        doCommentsCall($scope.selectedConversation);
                    }

                    // Send a notification that there are new messages
                    if ($scope.sendNotification) {
                        $rootScope.$broadcast(
                            'ft-notifications-add',
                            {
                                title: 'Nieuwe berichten!',
                                message: 'Er zijn ' + posts.length + ' nieuwe berichten!'
                            }
                        );
                    }

                    posts.forEach(function (post) {
                        var postExists = $scope.conversations.find(x => x.postId == post.postId);

                        if ($scope.sendNotification) {
                            post.read = false;
                        }

                        if (postExists) {
                            var index = $scope.conversations.indexOf(postExists);
                            $scope.conversations[index] = post;
                        } else {
                            $scope.conversations.push(post);
                        }
                    });

                }
            };

            var doCommentsCall = function (conversation) {
                DataService.getCommentsForMessage(conversation).then(
                    setComments,
                    handleGetDataError
                );
            };

            $scope.showMoreMessages = function () {
                getMessagesAsync(true)
                    .then(addConversationsToList, handleGetDataError)
                    .finally(function () {
                        $scope.messageLimit += 3;
                    });
            };

            var addConversationsToList = function (results) {
                results.posts.forEach(function (conversation) {
                    $scope.conversations.push(conversation);
                });
            };

            var setComments = function (conversation) {
                conversation.comments.forEach(function (comment) {
                    var replies = conversation.comments.filter(x => x.replyToCommentId == comment.commentId);

                    comment.hasReplies = replies.length > 0;
                    replies.forEach(function (reply) {
                        reply.isReply = true;
                    });
                });

                $scope.selectedConversation = getCommentReplies(conversation);
                markAsRead(conversation);
            };

            var getCommentReplies = function (selectedConversation) {
                selectedConversation.comments.forEach(function (comment) {
                    var comments = selectedConversation.comments.filter(x => x.replyToCommentId == comment.commentId);

                    var otherIndex = selectedConversation.comments.indexOf(comment);
                    comments.forEach(function (comment) {
                        var index = selectedConversation.comments.indexOf(comment);
                        selectedConversation.comments.splice(index, 1);

                        selectedConversation.comments.splice(otherIndex + 1, 0, comment);
                        otherIndex++;
                    });
                });

                selectedConversation.comments.forEach(function (comment) {
                    var isLeft = true;
                    if (comment.isReply) {
                        isLeft = false;
                    }

                    comment.isLeft = isLeft;
                });

                return selectedConversation;
            };

            var markAsRead = function (conversation) {
                var index = $scope.conversations.indexOf(conversation);
                conversation.read = true;

                $scope.conversations[index] = conversation;
            };

            $scope.answerComment = function (comment) {
                $scope.replyComment = comment;
            };

            $scope.backToListView = function () {
                $scope.messageListView = true;
                $scope.replyComment = null;
                $scope.selectedConversation = null;

                $state.go('app.child.messages');
            };

            $scope.sendMessage = function (event, message) {
                $scope.isLoading = true;
                $scope.message = message;

                var prm = DataService.answerToPostAsync($scope.selectedConversation, $scope.replyComment, message);
                prm.then(handleSaveAnswerToPost, handleGetDataError);
                prm.finally(function () {
                    $scope.isLoading = false;
                    $scope.replyComment = null;
                    event.message = '';

                    $rootScope.$emit('newPostMade', false);
                });
            };

            $scope.getReplyer = function(selectedConversation, comment) {
                return selectedConversation.comments.find(x => x.commentId == comment.replyToCommentId);
            };

            $rootScope.getAvatarInitials = function (fullName) {
                var matches = fullName.match(/\b(\w)/g);
                if (matches.length < 2) {
                    return fullName.slice(0, 2).toUpperCase();
                }

                matches = matches.slice(0, 2);
                return matches.join('').toUpperCase();
            };

            var handleSaveAnswerToPost = function (result) {
                doCommentsCall($scope.selectedConversation);
            };

            $scope.loadMessage = function (conversation) {
                $state.go('app.child.messagesSpecific', {message: conversation.postId});
            };

            $scope.openCreateConverstationModal = function () {
                $uibModal.open({
                    animation: true,
                    templateUrl: 'partials/messages/message-modal.html',
                    controller: 'MessageModalController',
                    size: 'lg',
                    backdrop: true,
                    keyboard: true,
                    resolve: {
                        audiences: function () {
                            return ng.copy($scope.messageAudiences);
                        },
                        selectedChild: function () {
                            return ng.copy($state.params.child);
                        }
                    }
                });
            };

            if ($stateParams.message) {
                doCommentsCall({postId: $stateParams.message});
                $scope.messageListView = false;
            }

            $timeout(initialize());
        }
    ]);
