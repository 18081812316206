ft.app.controller('planningListController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$translate', 'AppService', 'DataService', 'ModalService', 'PlanningModalService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $translate, AppService, DataService, ModalService, PlanningModalService) {

            var initialized = false;

            var loadingKeyPlanning = 'planning';
            var loadingKeyActivities = 'activities';
            var loadingKeyEmployees = 'employees';

            var addLoading = function (key) {
                if ($scope.data.loading.indexOf(key) < 0) {
                    $scope.data.loading.push(key);
                }
            };

            var removeLoading = function (key) {
                var idx = $scope.data.loading.indexOf(key);
                if (idx >= 0) {
                    $scope.data.loading.splice(idx, 1);
                }
            };

            $scope.data = {
                child: null,
                loading: [],
                message: '',
                planningConfig: ft.app.planning,
                selectedYear: new Date().getFullYear(),
                selectedMonth: new Date().getMonth(),
                weekdays: [''],
                weeks: [],
                daysInWeek: 5,
                activities: [],
                employees: [],
                requestables: [],
                selectedRequestables: {},
                hasRequestables: false,
                isInBulkMode: false
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

                $scope.data.child = AppService.getUser($stateParams.child);

                if ($stateParams.year || $stateParams.month) {
                    $scope.setPeriod($stateParams.year, $stateParams.month);
                } else if (AppService.getSelectedMonthAndYear()) {
                    var obj = AppService.getSelectedMonthAndYear();
                    $scope.setPeriod(obj.year, obj.month);
                }

                $rootScope.$on(ft.app.events.family.get, function () {
                    if (!$scope.data.weeks) {
                        $scope.getData();
                    }
                });

                $scope.getData();

            };

            $scope.getFirstStudyBlock = (day) => {
                return day.blocks.filter(x => x.type == $scope.data.planningConfig.types.study)[0];
            };

            $scope.setPeriod = function (year, month) {

                if (year) {
                    $scope.data.selectedYear = parseInt(year);
                }

                if (month || month == 0) {
                    $scope.data.selectedMonth = parseInt(month);
                }

                AppService.setSelectedMonthAndYear(month, year);

            };

            $scope.getData = function () {
                $scope.getPlanning();
                $scope.getActivities();
            };

            $scope.getPlanning = function () {
                $scope.data.message = '';

                addLoading(loadingKeyPlanning);
                var prm = DataService.getPlanningAsync($stateParams.child, $scope.data.selectedYear, $scope.data.selectedMonth);
                prm.then($scope.handleGetPlanning, $scope.handleGetPlanningError);
                prm.finally(function () {
                    removeLoading(loadingKeyPlanning);
                });
            };

            $scope.handleGetPlanning = function (results) {

                $scope.data.hasRequestables = false;
                $scope.data.selectedRequestables = {};
                var requestables = $scope.data.requestables = {};

                if (results.weeks) {
                    for (let week of results.weeks) {
                        for (let day of week.days) {

                            // get all future requestables (including today)
                            if (!$scope.isInThePast(day.date)) {

                                for (let block of day.blocks) {
                                    // if it is a requestable that requires action
                                    if (block.type != ft.app.planning.types.absent && block.status == ft.app.planning.states.requestable) {
                                        $scope.data.hasRequestables = true;

                                        const blockId = $scope.createUniqueBlockId(day.date, block.planningId || block.id, block.timeslotId);
                                        requestables[blockId] = {
                                            date: day.date,
                                            dayType: day.type,
                                            block: block
                                        };
                                    }
                                }
                            }


                        }
                    }
                }

                $scope.data.daysInWeek = results.daysInWeek;
                $scope.data.weeks = results.weeks;
            };

            $scope.handleGetPlanningError = function (response) {

                $scope.data.weeks = null;
                $scope.data.message = $translate.instant('PLANNING.ERROR-NO-PLANNING-FOUND');

                if (response && !response.result && response.isReadable) {
                    $scope.data.message += ' ' + response.message;
                }
            };

            $scope.getActivities = function () {

                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                var firstDay = new Date(y, m, 1);
                var lastDay = new Date(y, m + 1, 0);
                firstDay = firstDay.setDate(firstDay.getDate() - 7);
                lastDay = lastDay.setDate(lastDay.getDate() + 7);

                var start = moment(firstDay).format(ft.app.datetime.formats.toAPIDate);
                var end = moment(lastDay).format(ft.app.datetime.formats.toAPIDate);

                addLoading(loadingKeyActivities);

                var prm = DataService.getActivitiesByRangeAsync($stateParams.child, start, end);
                prm.then($scope.handleGetActivities, $scope.handleGetActivitiesError);
                prm.finally(function () {
                    removeLoading(loadingKeyActivities);
                });

            };

            $scope.handleGetActivities = function (results) {
                $scope.data.activities = results;
            };

            $scope.handleGetActivitiesError = function (results) {
            };

            $scope.getEmployees = function () {

                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                var firstDay = new Date(y, m, 1);
                var lastDay = new Date(y, m + 1, 0);
                firstDay = firstDay.setDate(firstDay.getDate() - 7);
                lastDay = lastDay.setDate(lastDay.getDate() + 7);

                var start = moment(firstDay).format(ft.app.datetime.formats.toAPIDate);
                var end = moment(lastDay).format(ft.app.datetime.formats.toAPIDate);

                addLoading(loadingKeyEmployees);

                var prm = DataService.getEmployeesByRangeAsync($stateParams.child, start, end);
                prm.then($scope.handleGetEmployees, $scope.handleGetEmployeesError);
                prm.finally(function () {
                    removeLoading(loadingKeyEmployees);
                });

            };

            $scope.handleGetEmployees = function (results) {
                $scope.data.employees = results;
            };

            $scope.handleGetEmployeesError = function (results) {
            };

            $scope.getActivitiesForDay = function (date) {
                var m = moment(date).format(ft.app.datetime.formats.toAPIDate);

                var results = $scope.data.activities.filter(function (item) {
                    return item.date == m;
                });

                if (results && results.length) {
                    return results[0].activities || [];
                }

                return [];
            };

            $scope.getEmployeesForDay = function (date) {
                var m = moment(date).format(ft.app.datetime.formats.toAPIDate);

                var results = $scope.data.employees.filter(function (item) {
                    return item.date == m;
                });

                if (results && results.length) {
                    return results[0].employees || [];
                }

                return [];
            };

            $scope.dateIsThisMonth = function (date) {
                return new Date(date).getMonth() == $scope.data.selectedMonth;
            };

            $scope.isInThePast = function (date) {

                return ft.dates.isDateInFuture(date, true) == false;

            };

            $scope.isFirstDayOfMonthShown = function (week, day) {

                var previousDay = $scope.getPreviousDay(week, day);
                var result;

                if (!previousDay) {
                    result = false; // not interrested if this day is the actual first day on the calendar
                } else {
                    var month = new Date(previousDay.date).getMonth();
                    result = month != $scope.data.selectedMonth;
                }

                return result;
            };

            $scope.isLastDayOfMonthShown = function (week, day) {

                var nextDay = $scope.getNextDay(week, day);
                var result;

                if (!nextDay) {
                    result = true;
                } else {
                    var month = new Date(nextDay.date).getMonth();
                    result = month != $scope.data.selectedMonth;
                }

                return result;

            };

            $scope.getPreviousDay = function (week, day) {

                var weekIdx = $scope.data.weeks.indexOf(week);
                var dayIdx = week.days.indexOf(day);
                var result;

                if (weekIdx == 0 && dayIdx == 0) {
                    result = null;
                } else if (dayIdx == 0) {
                    var previousWeek = $scope.data.weeks[weekIdx - 1];
                    result = previousWeek.days[previousWeek.days.length - 1];
                } else {
                    result = week.days[dayIdx - 1];
                }

                return result;

            };

            $scope.getNextDay = function (week, day) {

                var weekIdx = $scope.data.weeks.indexOf(week);
                var dayIdx = week.days.indexOf(day);
                var result;

                if (weekIdx == $scope.data.weeks.length - 1 && dayIdx == week.days.length - 1) {
                    result = null;
                } else if (dayIdx == week.days.length - 1) {
                    var nextWeek = $scope.data.weeks[weekIdx + 1];
                    result = nextWeek.days[0];
                } else {
                    result = week.days[dayIdx + 1];
                }

                return result;

            };

            $scope.navigateToMonthOfToday = function () {
                var today = new Date();
                var month = today.getMonth().toString();
                var year = today.getFullYear().toString();

                $scope.setPeriod(year, month);
                $scope.getData();
            };

            $scope.navigateToPreviousMonth = function () {
                var data = $scope.data;
                var month = data.selectedMonth == 0 ? 11 : data.selectedMonth - 1;
                var year = data.selectedMonth == 0 ? data.selectedYear - 1 : data.selectedYear;

                $scope.setPeriod(year.toString(), month.toString());
                $scope.getData();
            };

            $scope.navigateToNextMonth = function () {
                var data = $scope.data;
                var month = data.selectedMonth == 11 ? 0 : data.selectedMonth + 1;
                var year = data.selectedMonth == 11 ? data.selectedYear + 1 : data.selectedYear;

                $scope.setPeriod(year.toString(), month.toString());
                $scope.getData();
            };

            $scope.handleActionButton = function (evt, key) {

                evt.stopPropagation();
                evt.preventDefault();

                var id = $stateParams.child;

                switch (key) {
                    case 'absent':
                        $scope.openAbsentModal(evt, id);
                        break;
                    case 'sick':
                        $scope.openSickModal(evt, id);
                        break;
                    case 'present':
                        $scope.openPresentModal(evt, id);
                        break;
                    case 'swap':
                        $scope.openSwapModal(evt, id);
                        break;
                    case 'incidental':
                        $scope.openIncidental(evt, id);
                        break;
                }
            };

            $scope.openAbsentModal = function (evt, id) {
                PlanningModalService.openAbsentModal(evt, id)
                    .then(function (modal) {

                        modal.close.then(function () {

                            $scope.getData();

                        }, function () {
                        });

                    });
            };

            $scope.openSickModal = function (evt, id) {
                PlanningModalService.openAbsentModal(evt, id, null, null, null, null, ft.app.planning.presence.sick)
                    .then(function (modal) {

                        modal.close.then(function () {

                            $scope.getData();

                        }, function () {
                        });

                    });
            };

            $scope.openPresentModal = function (evt, id) {
                PlanningModalService.openPresentModal(evt, id)
                    .then(function (modal) {

                        modal.close.then(function () {

                            $scope.getData();

                        }, function () {
                        });

                    });
            };

            $scope.openSwapModal = function (evt, id) {
                PlanningModalService.openSwapModal(evt, id)
                    .then(function (modal) {

                        modal.close.then(function () {

                            $scope.getData();

                        }, function () {
                        });

                    });
            };

            $scope.openIncidental = function (evt, id) {
                PlanningModalService.openIncidentalModal(evt, id)
                    .then(function (modal) {

                        modal.close.then(function () {

                            $scope.getData();

                        }, function () {
                        });

                    });
            };

            $scope.openChoice = function (evt, day) {

                evt.stopPropagation();
                evt.preventDefault();

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'planning/choice',
                    controller: 'planningChoiceController',
                    inputs: {
                        child: $stateParams.child,
                        item: {
                            day: day
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function () {

                        $scope.getData();

                    }, function () {
                    });

                });

            };

            $scope.openPlanningBlock = function (evt, day, block) {

                evt.stopPropagation();
                evt.preventDefault();

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'planning/item',
                    controller: 'planningItemController',
                    inputs: {
                        child: $stateParams.child,
                        item: {
                            day: day,
                            block: block
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function () {

                        $scope.getData();

                    }, function () {
                    });

                });

            };

            $scope.openActivities = function (evt, day) {

                evt.stopPropagation();
                evt.preventDefault();

                var activities = $scope.getActivitiesForDay(day.date);

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    style: '.activities-modal-settings',
                    templateUrl: 'planning/activities-modal',
                    controller: 'planningItemActivitiesController',
                    inputs: {
                        item: {
                            child: $stateParams.child,
                            day: day,
                            activities: activities
                        }
                    }
                }).then(function (modal) {
                });

            };

            $scope.openEmployees = function (evt, day, employees) {

                evt.stopPropagation();
                evt.preventDefault();

                //var employees = $scope.getEmployeesForDay(day.date);

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'planning/employees-modal',
                    controller: 'planningItemEmployeesController',
                    inputs: {
                        item: {
                            day: day,
                            employees: employees
                        }
                    }
                }).then(function (modal) {
                });

            };

            $scope.openMonthPicker = function (evt) {

                evt.stopPropagation();
                evt.preventDefault();

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    style: '.month-picker-modal-settings',
                    templateUrl: 'month-picker-modal',
                    controller: 'monthPickerController',
                    inputs: {
                        selectedDate: {
                            year: $scope.data.selectedYear,
                            month: $scope.data.selectedMonth
                        }
                    }
                }).then(function (modal) {

                    // handle close
                    modal.close.then(function (result) {
                        console.log(result);
                        if (!result) {
                            return;
                        }

                        $scope.setPeriod(result.year, result.month);
                        $scope.getData();

                    });

                });

            };

            $scope.openLegenda = function (evt) {

                evt.stopPropagation();
                evt.preventDefault();

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'planning/legenda-modal',
                    controller: 'dummyModalController'
                });

            };

            $scope.toggleMode = function () {

                $scope.data.isInBulkMode = !$scope.data.isInBulkMode;

            };

            $scope.toggleSelectedRequestable = function (key) {

                var selectedRequestables = $scope.data.selectedRequestables;
                selectedRequestables[key] = !selectedRequestables[key];
            };

            $scope.openRequestModal = function (evt) {

                var selected = [];
                var requestables = $scope.data.requestables;
                var selectedRequestables = $scope.data.selectedRequestables;

                for (var key in selectedRequestables) {

                    if (selectedRequestables[key] == true) {
                        selected.push(requestables[key]);
                    }

                }

                // sort on date
                selected.sort(function (a, b) {

                    var aDate = new Date(a.date);
                    var bDate = new Date(b.date);

                    return aDate > bDate;

                });

                PlanningModalService.openRequestModal(evt, $stateParams.child, selected, false)
                    .then(function (modal) {

                        // handle close
                        modal.close.then(function (result) {

                            $scope.getData();

                        });

                    });

            };

            $scope.createUniqueBlockId = function (date, id, timeslotId) {
                return date + '-' + id + '-' + timeslotId;
            };

            $timeout($scope.initialize);

        }
    ]);