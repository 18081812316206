/*
 * GroupByFilter
 * Purpose: A filter that groups the items by given groupBy property
 */
ft.app.filter('GroupByFilter', function () {

    return function (items, groupBy, fncFormatValue) {

        var arrGroups = [];

        // object used for storing keys and their groups
        var objGroups = {};

        for (var i = 0; i < items.length; i++) {

            var item = items[i];

            // make sure it is his own property
            if (item.hasOwnProperty(groupBy)) {
                var val = fncFormatValue ? fncFormatValue(item[groupBy]) : item[groupBy]; // groupBy = 'Name', val = 'Bart' or other value.

                // make new group if there is none and set title 'Name'
                if (!objGroups[val]) {
                    objGroups[val] = new ft.models.Group({title: val});
                }

                // push item
                objGroups[val].items.push(item);

            }

        }

        // convert key-object to array so we can loop on binding
        // add the all-item if wanted
        // { 'prop1' : Group, 'prop2': Group } --> [Group, Group]
        for (var prop in objGroups) {

            if (objGroups.hasOwnProperty(prop)) {
                arrGroups.push(objGroups[prop]);
            }

        }

        return arrGroups;
    };

});

/*
 * MakeRange
 * Purpose: A filter that groups the items by given groupBy property
 */
ft.app.filter('MakeRange', function () {

    return function (input) {
        var min, max;

        switch (input.length) {
            case 1:
                min = 0;
                max = parseInt(input[0]) - 1;
                break;
            case 2:
                min = parseInt(input[0]);
                max = parseInt(input[1]);
                break;
            default:
                return input;
        }

        var result = [];

        for (var i = min; i <= max; i++) {
            result.push(i);
        }

        return result;
    };

});