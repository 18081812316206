ft.app.controller('MessageModalController',
    ['$rootScope', '$scope', '$state', '$q', '$uibModalInstance', 'audiences', 'DataService', '$stateParams', 'selectedChild',
        function ($rootScope, $scope, $state, $q, $uibModalInstance, audiences, DataService, $stateParams, selectedChild) {
            $scope.selectedAudienceId = null;
            $scope.selectedAudience = null;
            $scope.audiences = audiences;
            $scope.selectedChild = selectedChild;
            $scope.valid = true;
            $scope.message = {};
            $scope.audienceIds = [];

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $scope.$watch('selectedAudienceId', function (newId) {
                if (newId === null || newId == 'Select an Option') {
                    $scope.selectedAudience = null;
                    return;
                }

                $scope.selectedAudience = $scope.audiences[newId];
                if ($scope.selectedAudience.audiences.length > 0) {
                    $scope.allAudiencesSelected = false;
                    $scope.selectedAudience.audiences.forEach(function (audience) {
                        audience.checked = false;
                    });
                }

                if ($scope.selectedAudience.audiences.length === 1) {
                    $scope.toggleSelectAudience($scope.selectedAudience.audiences[0]);
                }
            });

            $scope.toggleSelectionAudiences = function () {
                if (!$scope.audiences || !$scope.audiences.length) {
                    return;
                }

                var selected = $scope.selectedAudience.audiences.filter(x => x.checked == true);
                var checked = $scope.selectedAudience.audiences.filter(x => x.isAllAudience == false).length === selected.length;

                $scope.allAudiencesSelected = !checked;
                $scope.selectedAudience.audiences.forEach(function (item) {
                    if (!item.isAllAudience) {
                        item.checked = !checked;
                    }
                });

            };

            $scope.toggleSelectAudience = function (audience) {
                audience.checked = !audience.checked;
                $scope.allAudiencesSelected = false;

                var amountOfAudiences = $scope.selectedAudience.audiences.filter(x => x.isAllAudience == false).length;
                var checkedAmount = $scope.selectedAudience.audiences.filter(x => x.checked == true).length;

                if (amountOfAudiences === checkedAmount) {
                    $scope.allAudiencesSelected = true;
                }
            };

            $scope.hasAudiencesSelected = function () {
                if ($scope.selectedAudience && $scope.selectedAudience.audiences) {
                    return $scope.selectedAudience.audiences.filter(x => x.checked == true).length > 0;
                }
            };

            $scope.selectAudience = function (index) {
                $scope.selectedAudience = audiences[index];
            };

            $scope.saveConversation = function () {
                $scope.isLoading = true;
                $scope.errorMessage = '';

                $scope.valid = true;
                var audiences = $scope.selectedAudience.audiences.filter(x => x.checked == true);
                var audienceIds = [];

                if ($scope.message.content === null || $scope.message.content === '') {
                    $scope.valid = false;
                }

                if ($scope.message.title === null || $scope.message.title === '') {
                    $scope.valid = false;
                }

                if (!audiences || !audiences.length) {
                    $scope.valid = false;
                } else {
                    audienceIds = audiences.map(function (item) {
                        return item.id;
                    });
                }

                if (!$scope.valid) {
                    $scope.audienceIds = audienceIds;
                    return;
                }

                var amountOfAudiences = $scope.selectedAudience.audiences
                    .filter(x => x.isAllAudience == false);
                var hasAllAudience = $scope.selectedAudience.audiences
                    .find(x => x.isAllAudience == true);

                var expectedAudienceIds = [];
                if (amountOfAudiences.length === audienceIds.length && hasAllAudience) {
                    expectedAudienceIds.push({id: hasAllAudience.id});
                } else {
                    audienceIds.forEach(function (audienceId) {
                        expectedAudienceIds.push({id: audienceId});
                    });
                }

                var prm = DataService.saveConversationAsync($scope.message, expectedAudienceIds, $scope.selectedChild);
                prm.then(handleSaveConversation, handleSaveConversationError);
                prm.finally(function () {
                    $scope.isLoading = false;
                });
            };

            var handleSaveConversation = function () {
                $scope.toggleSelectionAudiences();
                $scope.message.content = '';
                $scope.message.title = '';

                $rootScope.$emit('newPostMade', false);
                $uibModalInstance.dismiss();
                swal({
                    title: 'Verzonden!',
                    text: 'Het bericht is verzonden!',
                    timer: 2000,
                    type: 'success'
                });
            };

            var handleSaveConversationError = function () {
                $uibModalInstance.dismiss();
                swal({
                    title: 'Oops!',
                    text: 'Er ging iets mis bij het verzenden!',
                    timer: 2500,
                    type: 'error'
                });
            };
        }
    ]
);
