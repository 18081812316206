import moment, { Moment, Duration } from 'moment';
import { ft } from '@js/definitions';
import { IScope } from 'angular';
import { Params } from '@js/services/FT.app.services.planningmodalservice';
import { DataService } from '@js/services/FT.app.services.dataservice';
import angular from 'angular';
import { AbsenceType } from '@js/models';

enum SickEnd {
    today = 'today',
    tomorrow = 'tomorrow',
    unknown = 'unknown',
}

function formatTime(duration: moment.Duration): string {
    return moment.utc(duration.as('milliseconds')).format('HH:mm');
}

interface FromData {
    tillTime: string;
    fromTime: string;
    till: Date;
    from: Date;
    attempts: number;
    planningConfig: any;
    saving: boolean;
    comments: string;

    format: string;
    minDate: Date;

    fromOpened: boolean;
    tillOpened: boolean;
    sickEnd: SickEnd;
    fromValid: boolean;
    tillValid: boolean;
    fromTimeValid: boolean;
    tillTimeValid: boolean;

    dateOptions: any;

    reason: AbsenceType;
    reasons: Array<{ key: AbsenceType; title: string }>;
    errorMessage: string;
    successMessage: string;
}

interface Scope extends IScope {
    data: FromData;
    cancel: () => void;
    save: () => void;
    open: (from: boolean, $event: MouseEvent) => void;
    isSick: () => boolean;
    close: () => void;
    canSave: () => boolean;
    isValid: (field: string) => boolean;
}

export class AbsentModalController {
    public static $inject = ['$scope', 'close', 'params', '$translate', 'DataService', 'CheckStateChangeService'];
    private $scope: Scope;
    private closeDialog: (param?: any) => void;
    private params: Params;
    private $translate: angular.translate.ITranslateService;
    private dataService: DataService;
    private checkStateChangeService: any;

    public constructor($scope: Scope, close: () => void, params: Params, $translate: angular.translate.ITranslateService, dataService: DataService, checkStateChangeService) {
        this.$scope = $scope;
        this.closeDialog = close;
        this.params = params;
        this.$translate = $translate;
        this.dataService = dataService;
        this.checkStateChangeService = checkStateChangeService;

        this.$scope.close = this.close.bind(this);
        this.$scope.isSick = this.isSick.bind(this);
        this.$scope.open = this.open.bind(this);
        this.$scope.save = this.save.bind(this);
        this.$scope.cancel = this.cancel.bind(this);
        this.$scope.canSave = this.canSave.bind(this);
        this.$scope.isValid = this.isValid.bind(this);

        this.initialize();
    }

    private initialize() {

        this.checkStateChangeService.checkFormOnStateChange(this.$scope, 'absentForm');

        this.$scope.data = {
            attempts: 0,
            planningConfig: ft.app.planning,
            saving: false,
            comments: '',
            format: 'dd-MM-yyyy',
            minDate: new Date(),
            from: null,
            till: null,
            fromTime: null,
            tillTime: null,
            fromOpened: false,
            tillOpened: false,
            sickEnd: SickEnd.today,
            fromValid: true,
            tillValid: true,
            fromTimeValid: true,
            tillTimeValid: true,
            dateOptions: {
                formatYear: 'yy'
            },
            reason: this.params.presence ?? AbsenceType.absent,
            reasons: [
                { key: AbsenceType.absent, title: this.$translate.instant('PLANNING-ABSENT.ABSENT') },
                { key: AbsenceType.sick, title: this.$translate.instant('PLANNING-ABSENT.SICK') }
            ],
            errorMessage: '',
            successMessage: ''
        };

        this.$scope.data.from = this.params.from.toDate();
        this.$scope.data.till = this.params.till.toDate();

        this.$scope.data.fromTime = formatTime(this.params.fromTime);
        this.$scope.data.tillTime = formatTime(this.params.tillTime);
    }

    private open(from: boolean, $event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();

        if (from) {
            this.$scope.data.fromOpened = true;
        } else {
            this.$scope.data.tillOpened = true;
        }
    }

    private isSick() {
        return this.$scope.data.reason == AbsenceType.sick;
    }

    private async save(): Promise<void> {
        var data = this.$scope.data;
        data.errorMessage = '';
        data.successMessage = '';

        const dates: { from?: Moment; till?: Moment } = {};
        const validation = this.validate(data, dates);

        data.fromValid = validation['fromDate'] ?? true;
        data.tillValid = validation['tillDate'] ?? true;
        data.fromTimeValid = validation['fromTime'] ?? true;
        data.tillTimeValid = validation['tillTime'] ?? true;

        if (Object.keys(validation).length > 0) {
            return;
        }

        if (data.reason == AbsenceType.sick) {
            if (data.sickEnd === SickEnd.tomorrow) {
                dates.till = moment().add(1, 'day').endOf('day');
            } else if (data.sickEnd === SickEnd.today) {
                dates.till = moment().endOf('day');
            } else if (data.sickEnd === SickEnd.unknown) {
                dates.till = undefined;
            }
        }

        data.saving = true;
        let response: any;
        try {
            const format = moment.HTML5_FMT.DATE + ' ' + moment.HTML5_FMT.TIME;
            response = await this.dataService.saveAbsentAsync(this.params.childId, dates.from.format(format), dates.till?.format(format), data.reason, data.comments);

            data.attempts = 0;
            data.successMessage = this.$translate.instant('PLANNING-ABSENT.SAVED');
        } catch (error) {
            this.handleSaveError(response);
        } finally {
            data.saving = false;
            this.close();
        }
    }

    private validateDate(date?: Date): Moment {
        if (date == null) {
            return null;
        }

        const converted = moment(date);
        if (!converted.isValid()) {
            return null;
        }

        return converted;
    }

    private validateDuration(time?: string): Duration {
        if (time == null) {
            return null;
        }

        const converted = moment.duration(time);
        if (!converted.isValid()) {
            return null;
        }

        return converted;
    }

    private isValid(field: string): boolean {
        const validation = this.validate(this.$scope.data);
        return validation[field] ?? true;
    }

    private validate(data: FromData, dateResults?: { from?: Moment; till?: Moment }): { [key: string]: boolean } {
        const result = { };
        if (dateResults == null) {
            dateResults = {};
        }

        if (data.reason === AbsenceType.sick) {
            dateResults.from = moment();

            return result;
        }

        const fromDate = this.validateDate(data.from);
        if (fromDate == null) {
            result['fromDate'] = false;
        }

        const tillDate = this.validateDate(data.till);
        if (tillDate == null || fromDate.isAfter(tillDate)) {
            result['tillDate'] = false;
        }

        const fromTime = this.validateDuration(data.fromTime);
        if (fromTime == null) {
            result['fromTime'] = false;
        }

        const tillTime = this.validateDuration(data.tillTime);
        if (tillTime == null) {
            result['tillTime'] = false;
        }

        const from = fromDate.clone().startOf('day').add(fromTime);
        const till = tillDate.clone().startOf('day').add(tillTime);

        if (from.isSameOrAfter(till)) {
            result['tillTime'] = false;
        }

        dateResults.from = from;
        dateResults.till = till;

        return result;
    }

    private handleSaveError(response) {
        var data = this.$scope.data;

        data.attempts += 1;

        if (data.attempts > 3) {
            data.errorMessage = this.$translate.instant('PLANNING-ABSENT.ERROR-CONTINUES');
        } else {
            data.errorMessage = this.$translate.instant('PLANNING-ABSENT.ERROR-SAVE-ABSENT');
        }

        if (response && !response.result && response.isReadable) {
            data.errorMessage += ' ' + response.message;
        }
    }

    private close() {
        if (!this.$scope.data.saving) {
            this.canSave() ? this.closeDialog() : this.closeDialog({});
        }
    }

    private cancel() {
        if (!this.$scope.data.saving) {
            this.closeDialog();
        }
    }

    private canSave(): boolean {
        if (this.$scope.data.saving) {
            return false;
        }

        return Object.keys(this.validate(this.$scope.data)).length === 0;
    }
}

ft.app.controller('absentModalController', AbsentModalController);