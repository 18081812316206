ft.app.filter('GetContractStatusName',
    ['$translate',
        function ($translate) {
            return function (status) {

                var states = ft.app.contract.states;

                switch (status) {
                    case states.received:
                        return $translate.instant('CONTRACTS.RECEIVED');
                        break;
                    case states.active:
                        return $translate.instant('CONTRACTS.ACTIVE');
                        break;
                    case states.approved:
                        return $translate.instant('CONTRACTS.APPROVED');
                        break;
                    case states.rejected:
                        return $translate.instant('CONTRACTS.REJECTED');
                        break;
                    case states.offered:
                        return $translate.instant('CONTRACTS.OFFERED');
                        break;
                    case states.sign:
                        return $translate.instant('CONTRACTS.SIGN');
                        break;
                    case states.cancelled:
                        return $translate.instant('CONTRACTS.CANCELLED');
                        break;
                    case states.ended:
                        return $translate.instant('CONTRACTS.ENDED');
                        break;
                }

            };
        }]);
