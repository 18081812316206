/*
 * GetPhoneNumber
 * Purpose: A filter that returns the first or all phone numbers
 */
ft.app.filter('GetPhoneNumber', function () {

    return function (info, category, emergency, all) {

        if (!info) {
            return;
        }

        var items = info.filter(function (item) {
            return item.type == ft.app.contactInformation.types.phone && ((item.category && category && item.category == category) || !category) && ((emergency == true && item.isEmergency == true) || !emergency);
        });

        if (items && items.length) {
            return (all) ? items : items[0];
        }

    };

});

/*
 * GetMobilePhoneNumber
 * Purpose: A filter that returns the first or all phone numbers
 */
ft.app.filter('GetMobilePhoneNumber', function () {

    return function (info, category, emergency, all) {

        if (!info) {
            return;
        }

        var items = info.filter(function (item) {
            return item.type == ft.app.contactInformation.types.mobile && ((item.category && category && item.category == category) || !category) && ((emergency == true && item.isEmergency == true) || !emergency);
        });

        if (items && items.length) {
            return (all) ? items : items[0];
        }

    };

});

/*
 * GetEmailAddress
 * Purpose: A filter that returns the first or all e-mail address
 */
ft.app.filter('GetEmailAddress', function () {

    return function (info, category, emergency, all) {

        if (!info) {
            return;
        }

        var items = info.filter(function (item) {
            return item.type == ft.app.contactInformation.types.email && ((item.category && category && item.category == category) || !category) && ((emergency == true && item.emergency == true) || !emergency);
        });

        if (items && items.length) {
            return (all) ? items : items[0];
        }

    };

});

/*
 * GetEmailAddress
 * Purpose: A filter that returns the first or all e-mail address
 */
ft.app.filter('GetAddress', function () {

    return function (info, category, all) {

        if (!info) {
            return;
        }

        var items = info.filter(function (item) {
            return item.type == category;
        });

        if (items && items.length) {
            return (all) ? items : items[0];
        }

    };

});
