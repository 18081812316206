ft.app.directive('ftFamilyChildSelection',
    function () {
        return {
            scope: {
                children: '=',
                handler: '='
            },
            templateUrl: ft.app.getTemplateUrl('family/childselection')
        };
    });

ft.app.directive('ftChildWidget', function () {

    return {
        templateUrl: ft.app.getTemplateUrl('child/widget'),
        scope: {
            child: '=',
        },
        controller: 'childWidgetController',
        link: function (scope, elm, attrs) {
        }
    };
});

ft.app.directive('ftAddress', function () {

    return {
        templateUrl: ft.app.getTemplateUrl('family/address'),
        scope: {
            address: '=',
            singleLine: '='
        }
    };
});
