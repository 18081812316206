ft.defineNamespace('FortyTwo.ui.managers');

ft.ui.managers.zIndexManager = (function () {

    var _start = 1000;
    var _stack = [_start];

    var mngr = function () {
    };

    mngr.prototype = {
        get index() {

            _stack.push(_stack[_stack.length - 1] + 1);
            return _stack[_stack.length - 1];

        },
        get stack() {
            return _stack;
        }
    };

    mngr.prototype.remove = function (index) {

        for (var i = _stack.length - 1; i > 0; i--) {
            if (_stack[i] == index) {
                _stack.splice(i, 1);
            }
        }

    };

    return new mngr();

})();
