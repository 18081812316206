ft.app.directive('ftMorphModal', ['$rootScope', '$compile', '$parse', '$controller', 'TemplateHandler',
    function ($rootScope, $compile, $parse, $controller, TemplateHandler) {

        var $body = $('#wrapper');
        var layerManager = ft.ui.managers.zIndexManager;
        var standardSizeClass = 'overlay-standard-size';
        var openClass = 'overlay-open';
        var modalPlaceholderClass = 'modal-placeholder';
        var modalClass = 'overlay';
        var shadowClass = 'shadow';
        var noTransitionClass = 'no-transition';
        var modalSizeAttr = 'modalSize';

        return {
            restrict: 'A',
            scope: true,
            link: function (scope, elm, attrs) {

                var settings = scope.$eval(attrs.ftMorphModal);
                var max = 1000000;
                var id = 'modal-' + ft.getRandom(1, max) + '-' + ft.getRandom(max * 2, max * 3);

                var $elm = $(elm);
                var $wrapper = $('<div class="' + modalPlaceholderClass + '" style="display: none;"></div>');
                var $modal = $('<div class="' + modalClass + '"></div>');
                var $shadow = $('<div class="' + shadowClass + '"></div>');

                $wrapper.attr('id', id);
                $elm.attr('modal', id);

                $wrapper.append($modal);
                $wrapper.append($shadow);
                $body.append($wrapper);

                $elm.on('click', function () {

                    var position = $elm.offset();

                    $modal.empty();
                    TemplateHandler
                        .get(ft.app.getTemplateUrl(settings.templateUrl))
                        .then(compile)
                        .then(function (result) {
                            $modal.append(result);
                            $modal.find('.back').on('click', close);
                        });

                    var modalSize;
                    if (!$modal.data(modalSizeAttr)) {
                        modalSize = getSizeAndPositionFromCss(settings.style);
                        modalSize = !modalSize ? getSizeAndPositionFromCss(standardSizeClass) : modalSize;
                        $modal.data(modalSizeAttr, modalSize);
                    } else {
                        modalSize = $modal.data(modalSizeAttr);
                    }

                    $modal.addClass(noTransitionClass);
                    $modal.css('left', position.left);
                    $modal.css('right', $body.outerWidth() - (position.left + $elm.outerWidth()));
                    $modal.css('top', position.top);
                    $modal.css('width', elm.outerWidth());
                    $modal.css('height', elm.outerHeight());
                    $modal.removeClass(noTransitionClass);

                    $shadow.css('zIndex', layerManager.index);
                    $modal.css('zIndex', layerManager.index);

                    $wrapper.css('display', 'block');

                    setTimeout(function () {

                        $elm.addClass(openClass);
                        $wrapper.addClass(openClass);

                        if (!modalSize.top) {
                            var top = Math.floor((parseInt($body.outerHeight()) - parseInt(modalSize.height)) / 2);
                            $modal.css('top', top + 'px');
                        }

                        $modal.css(modalSize);

                    }, 400);

                });

                $shadow.on('click', function () {

                    close();

                });

                // bind the template contents with the current scope and returns the result
                var compile = function (results) {
                    if (settings.controller) {
                        var inst = $controller(settings.controller, {
                            $rootScope: $rootScope,
                            $scope: scope,
                            closeHandler: close
                        });
                    }

                    var template = results.data ? results.data : results;
                    return $compile(template)(scope);
                };

                // get properties from css rule for height, width and top
                // makes custom sizes possible, but standard is given
                var getSizeAndPositionFromCss = function (style) {

                    var sheets = document.styleSheets;

                    for (var prop in sheets) {

                        var sheet = sheets[prop];

                        if (sheet && sheet.href && sheet.href.indexOf(ft.app.cssFile)) {
                            var rules = sheet.cssRules ? sheet.cssRules : sheet.rules;

                            for (var i = 0; i < rules.length; i++) {
                                var rule = rules[i];

                                if (rule.selectorText && rule.selectorText.indexOf(style) >= 0) {
                                    return {height: rule.style.height, width: rule.style.width, top: rule.style.top};
                                }
                            }
                        }

                    }

                };

                // mainly closes the modal
                var close = function () {

                    layerManager.remove($shadow.css('zIndex'));
                    layerManager.remove($modal.css('zIndex'));

                    var position = $elm.offset();

                    $elm.removeClass(openClass);
                    $wrapper.removeClass(openClass);

                    $modal.css('top', position.top);
                    $modal.css('left', position.left);
                    $modal.css('right', $body.outerWidth() - (position.left + $elm.outerWidth()));
                    $modal.css('width', elm.outerWidth());
                    $modal.css('height', elm.outerHeight());

                    setTimeout(function () {
                        $wrapper.css('display', 'none');
                    }, 800);
                };

            }
        };
    }]);
