ft.app.controller('waitinglistModalController',
    ['$scope', '$element', 'close', 'childId', 'items', '$timeout', '$translate', 'DataService', 'AppService',
        function ($scope, $element, close, childId, items, $timeout, $translate, DataService, AppService) {

            var initialized = false;

            var waitinglistActions = {
                none: 'none',
                approved: 'approved',
                rejected: 'rejected'
            };

            $scope.data = {
                items: items,
                waitinglistActions: [],
                saving: false,
                saveEnabled: true,
                attempts: 0,
                successMessage: '',
                errorMessage: ''
            };


            $scope.save = function () {

                var data = $scope.data;
                data.successMessage = '';
                data.errorMessage = '';

                var approved = data.items.filter(x => x.action == waitinglistActions.approved).map(function (item) {
                    return item.waitingListItemId;
                });
                var rejected = data.items.filter(x => x.action == waitinglistActions.rejected).map(function (item) {
                    return item.waitingListItemId;
                });

                if (!approved.length && !rejected.length) {
                    return;
                }

                data.saving = true;
                var prm = DataService.submitWaitinglistItemsAsync(childId, approved, rejected);
                prm.then($scope.handleSave, $scope.handleSaveError);
                prm.finally(function () {
                    data.saving = false;
                });

            };

            $scope.handleSave = function (results) {
                $scope.data.saveEnabled = false;
                $scope.data.attempts = 0;
                $scope.data.successMessage = $translate.instant('REQUESTS.WAITINGLIST-ITEMS-SAVED');
            };

            $scope.handleSaveError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('REQUESTS.ERROR-CONTINUES');
                } else {
                    $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('REQUESTS.ERROR-SAVE-WAITINGLIST-ITEMS');
                }
            };

            $scope.cancel = function () {
                close();
            };

            $scope.close = function () {
                close({
                    success: !$scope.data.saveEnabled
                });
            };

            var getData = function () {

                var data = $scope.data;

                data.items.forEach(function (item) {
                    item.action = data.waitinglistActions[0].id;
                });

            };

            var initialize = function () {

                var data = $scope.data;

                if (initialized) {
                    return;
                }

                initializeWaitinglistActions();
                getData();

                initialized = true;

            };

            var initializeWaitinglistActions = function () {

                var actions = $scope.data.waitinglistActions;
                actions.push({id: waitinglistActions.none, name: 'REQUESTS.WAITINIGLIST-ACTIONS-NONE'});
                actions.push({id: waitinglistActions.approved, name: 'REQUESTS.WAITINIGLIST-ACTIONS-APPROVED'});
                actions.push({id: waitinglistActions.rejected, name: 'REQUESTS.WAITINIGLIST-ACTIONS-REJECTED'});

            };

            $timeout(initialize);

        }
    ]);
