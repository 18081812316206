// solution for format problem with Angular UI Bootstrap
// PROBLEM:
//      when selecting a date in popup, the format will be copied.
//      when (in case of a wizard) a rewrite of the field happens, the format will be neclected.
ft.app.directive('datepickerPopup',
    ['dateFilter', 'datepickerPopupConfig', function (dateFilter, datepickerPopupConfig) {
        return {
            restrict: 'A',
            priority: 1,
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var dateFormat = attr.datepickerPopup || datepickerPopupConfig.datepickerPopup;
                ngModel.$formatters.push(function (value) {
                    return dateFilter(value, dateFormat);
                });
            }
        };
    }]);
