/*
 * isDebug
 * Purpose: Show if debug
 * 
 */
ft.app.directive('ftShowOnDebug', function () {
    return {
        link: function (scope, elm, attrs) {
            $(elm).css('display', ft.DEBUG ? 'block' : 'none');
        }
    };
});

// ftBindController: bind controller (and view) to element
// usage: <div ft-bind-controller="{controller: 'name of controller', view: 'url of view'}" init="variable = value; variable 2 = value 2"></div>
// result: 
/*
 *  <div ft-bind-controller="{controller: 'name of controller', view: 'url of view'}" init="variable = value; variable 2 = value 2">
 *      <div ng-include="' url of view '" ng-controller="name of controller" ng-init="variable = value; variable 2 = value 2"></div>
 *  </div>
 */
ft.app.directive('ftBindController',
    ['$compile', '$parse',
        function ($compile, $parse) {
            var init = false;
            var create = function (element, scope, data, init) {

                var view = ft.app.getTemplateUrl(data.view);
                var elm = angular.element('<div ng-include="\'' + view + '\'" ' + ((data.controller) ? 'ng-controller="' + data.controller + '"' : '') + ' ' + ((init) ? 'ng-init="' + init + '"' : '') + '></div>');

                element.empty(); // remove previous content
                $compile(elm)(scope);
                element.append(elm);

            };

            return {
                scope: false,
                compile: function (tElm, tAttrs) {
                    var initialized = false;
                    var attrObj = tAttrs.ftBindController;
                    var attrInit = tAttrs.init;
                    var fnObj = $parse(attrObj);

                    var linkFunction = function (scope, elm, attrs) {

                        if (!initialized) {
                            scope.$watch(attrObj, function (newValue, oldValue) {

                                if (newValue != oldValue) {
                                    linkFunction(scope, elm, attrs);
                                }

                            });
                        }

                        var obj = fnObj(scope.$parent);
                        create(elm, scope, obj, attrInit);

                        initialized = true;

                        elm.on('$destroy', function () {
                            scope.$destroy();
                        });

                    };

                    return linkFunction;
                }
            };

        }]);

// ftBindObject: the purpose of this directive is to reduce the duplication of the object's name

/* FROM:
 * <div>{{user.name.first}}</div>
 * <div>{{user.name.last}}</div>
 */

/* TO:
 * <div ft-bind-object="user.name">
 *    <div>{{first}}</div>
 *    <div>{{last}}</div>
 * </div>
 */

ft.app.directive('ftBindObject',
    ['$parse',
        function ($parse) {
            return {
                scope: true,
                compile: function (tElm, tAttrs) {
                    var init = false;
                    var attr = tAttrs.ftBindObject;
                    var fn = $parse(attr);

                    var linkFunction = function (scope, elm, attrs) {

                        if (!init) {
                            scope.$watch(attr, function (newValue, oldValue) {

                                linkFunction(scope, elm, attrs);

                            });
                        }

                        var property = fn(scope.$parent);
                        property = angular.isObject(property) ? property : {};
                        angular.extend(scope, property);

                        init = true;

                    };

                    return linkFunction;
                }
            };
        }]);

ft.app.directive('ftBackImage', function () {
    return {
        link: function (scope, elm, attrs) {
            attrs.$observe('ftBackImage', function (value) {

                elm.removeClass('default-header text-center');
                elm.find('.camera').remove();

                if (value) {
                    elm.css('background-image', 'url(' + value + ')');
                } else {
                    elm.addClass('default-header text-center');
                    elm.append('<div class="camera"><div><i class="mdi-image-photo-camera"></i></div></div>');
                }
            });
        }
    };
});

// ftEnter: do action when user hits enter-key
ft.app.directive('ftEnter', function () {
    return function (scope, element, attrs) {
        element.bind('keypress', function (event) {

            if (event.which === 13) {
                event.preventDefault();
                event.stopPropagation();
                scope.$eval(attrs.ftEnter);
                scope.$apply();
            }

        });
    };
});

ft.app.directive('ftRequiredFieldIndication',
    function () {
        return {
            templateUrl: ft.app.getTemplateUrl('required-field-indication'),
        };
    });
