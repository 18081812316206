import {UrlHelper} from '@js/urlHelper';

ft.app.controller('dummyController', function () {
});

ft.app.controller('dummyModalController',
    ['$scope', 'close',
        function ($scope, close) {

            $scope.close = function () {
                close();
            };

        }]);

ft.app.controller('appController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$location', '$timeout', 'AppService', 'LoginService',
        function ($rootScope, $scope, $state, $stateParams, $q, $location, $timeout, AppService, LoginService) {

            var offEventsActive, offEventsClear;

            $scope.isInitialized = false;
            $scope.showSplash = true;
            $scope.isLoading = false;
            $scope.hasConnection = true;
            $scope.supported = !ft.browser || ft.browser >= 10; // non IE or IE >= 10
            $scope.backgroundImageUrl = ft.app.mainBackgroundImageUrl;

            //#region init

            var initialize = function () {

                if ($scope.isInitialized) {
                    return;
                }

                offEventsActive = $rootScope.$on(ft.app.events.requests.active, handleEventsActive);
                offEventsClear = $rootScope.$on(ft.app.events.requests.none, handleEventsClear);
                $scope.$on('$destroy', handleDestroy);

                getData();

                $scope.isInitialized = true;

            };

            var initRoute = function () {

                // if logged in and then refresh
                // preserve pre-login states, like registration and set password

                if ($state.current.name == '') {

                    var url = $location.absUrl();
                    var qs = null;

                    // set url to obj with key > value
                    if (url.indexOf('?') >= 0) {

                        var arr = url.split('?')[1].split('#')[0].split('&');

                        qs = arr.reduce(function (obj, part, idx) {

                            var key = part.split('=')[0].toLowerCase();
                            var val = decodeURIComponent(part.split('=')[1]);

                            obj[key] = val;

                            return obj;

                        }, {});

                    }

                    // determine mode
                    if (qs && qs.action) {

                        switch (qs.action.toLowerCase()) {
                            case 'registration':
                                $state.go('registration.new');
                                break;
                            case 'impersonate':
                                window.location.href = $state.href('impersonate', {
                                    accesskey: qs.accesskey,
                                    id: qs.id
                                }, {reload: true});
                                break;
                            case 'setpassword':
                                window.location.href = $state.href('setPassword', {accesskey: qs.accesskey}, {absolute: true});
                                break;
                            default:
                                $state.go('login');
                        }

                    } else {

                        LoginService.isLoggedInAsync().then(
                            function () {
                                $state.go('app.start');
                            },
                            function () {
                                // trim ending slash
                                if (window.location.origin == window.location.href.slice(0, -1)) {
                                    $state.go('login');
                                }
                            }
                        );

                    }

                } else if ($state.current.name.indexOf('app.') == 0) {
                    LoginService.isLoggedInAsync().then(
                        function () {
                        },
                        function () {
                            $state.go('login');
                        }
                    );
                }

            };

            //#endregion

            //#region get data

            var getData = function () {

                $scope.isLoading = true;

                var prm = AppService.getConfigAsync();
                prm.then(handleGetConfig, handleGetConfigError);
                prm.finally(function () {
                    $scope.isLoading = false;
                });

            };

            //#endregion

            //#region handlers

            var handleGetConfig = function (results) {

                $scope.showSplash = false;
                initRoute();

            };

            var handleGetConfigError = function (response) {
            };

            var handleEventsActive = function () {
                $scope.isLoading = true;
            };

            var handleEventsClear = function () {
                $scope.isLoading = false;
            };

            var handleDestroy = function () {

                if (offEventsActive) {
                    offEventsActive();
                }
                if (offEventsClear) {
                    offEventsClear();
                }

            };

            //#endregion

            $timeout(initialize);

        }
    ]);

ft.app.controller('confirmModalController',
    ['$scope', '$element', 'close', 'question', '$timeout',
        function ($scope, $element, close, question, $timeout) {

            var initialized = false;

            $scope.data = {
                question: question,
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $scope.close = function (confirmed) {
                close({
                    confirmed: confirmed
                });
            };

            $timeout($scope.initialize);

        }]);