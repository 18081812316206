ft.defineNamespace('FortyTwo.models');

//#region Group

/*
 * Group
 * purpose: Object to use as placeholder for grouped items.
 */
ft.models.Group = (function () {

    var getCount = function () {
        var count = 0;

        for (var i = 0; i < this.items.length; i++) {
            count += parseInt(this.items[i].count);
        }

        return count;
    };

    var obj = function (options) {

        options = options || {};

        this.title = options.title;
        this.items = [];

        ft.defineBindableGetter(this, 'count', getCount);

    };

    obj.prototype = {
        //get count() {

        //    var count = 0;

        //    for (var i = 0; i < this.items.length; i++)
        //        count += parseInt(this.items[i].count);

        //    return count;

        //},
        get checked() {

            var checked = true;

            for (var i = 0; i < this.items.length; i++) {
                if (!this.items[i].checked) {
                    checked = false;
                }
            }

            return checked;

        },
        set checked(val) {

            for (var i = 0; i < this.items.length; i++) {
                this.items[i].checked = val;
            }

        }
    };

    return obj;

})();

//#endregion

//#region Timeline

ft.models.Timeline = (function () {

    var obj = function (options) {

        options = options || {};

        this.date = options.date;
        this.itemDate = options.itemDate;
        this.type = options.type;
        this.item = options.item;

    };

    return obj;

})();
//#endregion
