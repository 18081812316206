ft.app.controller('contractEndModalController',
    ['$scope', '$element', 'close', 'item', '$timeout', '$translate', 'DataService', 'AppService',
        function ($scope, $element, close, item, $timeout, $translate, DataService, AppService) {

            var initialized = false;

            $scope.data = {
                item: item,
                end: false,
                endValid: true,
                date: moment(item.contract.cancelDate).format(ft.app.datetime.formats.toAPIDate),
                now: new Date(),
                format: 'dd-MM-yyyy',
                dateOpened: false,
                dateValid: true,
                dateOptions: {
                    initDate: moment(item.contract.cancelDate).toDate(),
                    minDate: moment(item.contract.cancelDate).toDate(),
                    formatYear: 'yy'
                },
                comments: '',
                saving: false,
                saveEnabled: true,
                attempts: 0,
                successMessage: '',
                errorMessage: ''
            };

            $scope.initialize = function () {

                var data = $scope.data;

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $scope.save = function () {
                var data = $scope.data;
                data.successMessage = '';
                data.errorMessage = '';

                if (data.end == false) {
                    data.endValid = false;
                }

                if (!data.date) {
                    data.dateValid = false;
                } else {
                    var date = moment(data.date).format(ft.app.datetime.formats.toAPIDate);

                    if (!moment(date).isValid()) {
                        data.dateValid = false;
                    } else {
                        date = new Date(date);
                        date.setHours(0, 0, 0, 0);

                        var cancelDate = new Date(data.item.contract.cancelDate);
                        cancelDate.setHours(0, 0, 0, 0);

                        if (date < cancelDate) {
                            data.dateValid = false;
                        }
                    }
                }

                if (!data.endValid || !data.dateValid) {
                    return;
                }

                data.saving = true;

                var prm = DataService.endContractAsync(item.child, item.contract.id, data.comments, data.date);
                prm.then($scope.handleSave, $scope.handleSaveError);
                prm.finally(function () {
                    data.saving = false;
                });
            };

            $scope.handleSave = function (results) {
                $scope.data.saveEnabled = false;
                $scope.data.attempts = 0;
                $scope.data.successMessage = $translate.instant('CONTRACTS-END.END-SAVED');
            };

            $scope.handleSaveError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('CONTRACTS-END.ERROR-CONTINUES');
                } else {
                    $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('CONTRACTS-END.ERROR-SAVE-END');
                }
            };

            $scope.open = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.data.dateOpened = true;
            };

            $scope.cancel = function () {
                close();
            };

            $scope.close = function () {
                close({
                    success: !$scope.data.saveEnabled
                });
            };

            $timeout($scope.initialize);

        }
    ]);
