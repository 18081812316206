ft.app.controller('LoadingController', ['$rootScope', '$scope',
    function ($rootScope, $scope) {

        $scope.data = {
            isLoading: false
        };

        $scope.handleActive = function () {
            $scope.data.isLoading = true;
        };

        $scope.handleNone = function () {
            $scope.data.isLoading = false;
        };

        $rootScope.$on(ft.app.events.requests.active, $scope.handleActive);
        $rootScope.$on(ft.app.events.requests.none, $scope.handleNone);

    }]);
