import { ChildCare } from '@js/data-models';
import { ft } from '@js/definitions';
import { DataService } from '@js/services/FT.app.services.dataservice';
import { IScope, ITimeoutService } from 'angular';
import { IStateParamsService } from 'angular-ui-router';

type ChildActivityProgram = ChildCare.Definitions.Activity.ChildActivityProgram;

interface Scope extends IScope {
    data: {
        errorMessage?: string;
        items?: ChildActivityProgram[];
        hasItems: boolean;
    };
    isLoading: boolean;
}

class ActivitiesController {
    public static $inject = ['$scope', '$stateParams', '$timeout', '$translate', 'DataService'];

    private $scope: Scope;
    private dataService: DataService;
    private childId: string;
    private errorMessage: string;

    constructor($scope: Scope, $stateParams: IStateParamsService, $timeout: ITimeoutService, $translate: any, dataService: DataService) {
        this.$scope = $scope;
        this.dataService = dataService;

        this.childId = $stateParams.child;
        this.errorMessage = $translate.instant('CONTRACTS.ERROR-GET-CONTRACTS');

        this.$scope.data = {
            items: [],
            hasItems: false
        };

        $timeout(this.initialize.bind(this));
    }

    private async initialize(): Promise<void> {
        this.$scope.isLoading = true;

        try {
            const results = await this.dataService.getChildActivities(this.childId);

            this.$scope.data.hasItems = results?.programs?.length > 0 ?? false;
            this.$scope.data.items = results?.programs ?? [];
        } catch (error) {
            this.$scope.data.errorMessage = error?.message ?? this.errorMessage;
        } finally {
            this.$scope.isLoading = false;
            this.$scope.$apply();
        }
    }
}

ft.app.controller('childActivitiesController', ActivitiesController);