ft.app.controller('impersonateController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$translate', 'DataService', 'LoginService',
        function ($rootScope, $scope, $state, $stateParams, $timeout, $translate, DataService, LoginService) {

            var initialized = false;

            $scope.isLoading = false;
            $scope.data = {
                errorMessage: '',
                form: null
            };

            //#region init

            var initialize = function () {

                if (initialized) {
                    return;
                }

                getData();

                initialized = true;

            };

            //#endregion

            //#region get data

            var getData = function () {

                $scope.isLoading = true;

                var prm = LoginService.doImpersonateAsync($stateParams.accesskey, $stateParams.id);
                prm.then(handleDoImpersonate, handleDoImpersonateError);
                prm.finally(function () {
                    $scope.isLoading = false;
                });

            };

            //#endregion

            //#region handlers

            var handleDoImpersonate = function (results) {

                if (results) {
                    window.location.replace(window.location.origin);
                }

            };

            var handleDoImpersonateError = function (response) {

                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('IMPERSONATE.ERROR-LOGIN-AS-PARENT');

            };

            //#endregion

            $timeout(initialize);

        }
    ]);

ft.app.controller('setPasswordController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$translate', 'AppService', 'DataService', 'LoginService',
        function ($rootScope, $scope, $state, $stateParams, $timeout, $translate, AppService, DataService, LoginService) {

            var initialized = false;

            $scope.isLoading = false;
            $scope.appData = AppService.data;
            $scope.data = {
                username: '',
                password: '',
                validPassword: true,
                errorMessage: ''
            };

            //#region init

            var initialize = function () {

                if (initialized) {
                    return;
                }

                getData();

                if ($stateParams.lang) {
                    $scope.switchLanguage($stateParams.lang);
                }

                initialized = true;

            };

            //#endregion

            //#region screen handlers

            $scope.switchLanguage = function (lang) {
                AppService.switchLanguage(lang);
            };

            $scope.handleSave = function () {

                setPassword();

            };

            //#endregion

            //#region data

            var getData = function () {

                $scope.isLoading = true;

                var prm = DataService.setPasswordAsync(encodeURIComponent($stateParams.accesskey));
                prm.then(handleGetData, handleGetDataError);
                prm.finally(function () {
                    $scope.isLoading = false;
                });

            };

            var setPassword = function () {

                $scope.data.errorMessage = '';
                $scope.data.validPassword = true;

                var pw = $scope.data.password.toString().trim();

                if (!pw) {
                    $scope.data.validPassword = false;
                    ;
                    return;
                }

                $scope.isLoading = true;

                var prm = DataService.setPasswordAsync(encodeURIComponent($stateParams.accesskey), $scope.data.password);
                prm.then(handleSetPassword, handleSetPasswordError);
                prm.finally(function () {
                    $scope.isLoading = false;
                });

            };

            //#endregion

            //#region handlers

            var handleGetData = function (results) {
                $scope.data.username = results.name;
            };

            var handleGetDataError = function (response) {

                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('SET-PASSWORD.ERROR-GET-PERSONNAME');

            };

            var handleSetPassword = function (results) {

                if (results) {
                    $state.go('login');
                }

            };

            var handleSetPasswordError = function (response) {

                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('SET-PASSWORD.ERROR-SAVE-PASSWORD');

            };

            //#endregion

            $timeout(initialize);

        }
    ]);

ft.app.controller('logoutController',
    ['$rootScope', '$scope', '$state', '$stateParams', 'AppService', 'LoginService',
        function ($rootScope, $scope, $state, $stateParams, AppService, LoginService) {

            LoginService.doLogout();
            AppService.data.currentUser = null;
            $state.go('login');

        }]);
