import {IAngularStatic, IModule, IHttpPromise, IRootScopeService, IPromise} from 'angular';
import { ChildCare } from './data-models';

type UserModel = ChildCare.Definitions.Account.UserModel;

declare module 'angular' {
    interface IModule {
        animation: (element: string, options: any) => void;
        settings: IFortyTwoFrameworkSettings;
        levels: any;
        events: any;
        navigation: any;
        exceptions: any;
    }
}

declare global {

    interface Window {
        ft: IFortyTwoStatic | any;
        FortyTwo: IFortyTwoStatic | any;
        ng: IAngularStatic;
        $: JQueryStatic;
        jquery: JQueryStatic;
        angular: IAngularStatic;
        appModule: IModule | any;
    }
}

window.ft = window.FortyTwo;
window.ng = window.angular;

export interface IFortyTwoStatic {
    DEBUG: boolean;
    isMobile: boolean;
    app: IModule | any;
    models: IFortyTwoModels;
    framework: IFortyTwoFramework;
    dates: any;

    log: (level: string, ...args: Array<any>) => void;
    createDelegate: (ctx: any, fnc: any) => Function;
    defineNamespace: (namespace: string) => any;
    defineBindableProperty: (obj: any, prop: any, getter: any, setter?: any) => void;
    dontPropagate: (evt: any) => void;
    getRandom: (min?: number, max?: number) => number;
    getInterval: (min: number, max: number) => number;
    getQueryParam: (name: string) => string;
    removeQueryParam: (name: string, href: string) => string;
    hasValue: (val: any) => boolean;
}

export interface IFortyTwoRootScopeService extends IRootScopeService {
    configuration: any;
}

export interface IFortyTwoFramework
{
    module: IModule;
}

export interface IFortyTwoFrameworkSettings
{
    datetime: any;
    keys: any;
    sorting: any;
}

export interface IFortyTwoModels
{
    User: IFortyTwoUser;
    Group: (options: any) => IFortyTwoGroup;
}

export interface IFortyTwoUser
{
    hasRole: (role: string) => boolean;
    obj: (options: any) => any;
}

export interface IFortyTwoGroup
{
    getCount: () => number;
}

export interface IFortyTwoRequestService {
    config: any;
    requestAsync: <TData = any>(request: IFortyTwoRequest, useAuthorization?: boolean, ignoreContentType?: boolean, triggerEvents?: boolean) => IPromise<TData>;
}

export interface TokenData {
    refreshToken: string;
    token: {
        expires: string;
        value: string;
    };
}

export interface FortyTwoLoginService {
    doLogout(): void;
    getCurrentUserAsync(): IPromise<UserModel>;
    isLoggedInAsync(): IPromise<boolean>;
    getStoredUsername(): string;
    wantsToStayLoggedIn(): boolean;
    hasValidTokenAsync(): IPromise<boolean>;
    hasRefreshTokenAsync(): IPromise<boolean>;
    doLoginAsync(username: string, password: string, stayLoggedIn: boolean): IPromise<boolean>;
    refreshLoginAsync(): IPromise<boolean>;
    getTokenData(): TokenData;
}

export interface IFortyTwoRequest {
    url?: string;
    path: string;
    headers?: Map<string, string> | { [key: string]: string };
    method?: string;
    data?: any;
}

export interface FortyTwoRequest {
    url?: string;
    path: string;
    headers?: any;
    method?: 'POST' | 'GET' | 'PUT' | 'DELETE';
    data?: any;
}