export class UrlHelper {
    public static isUrlAbsolute(url: string): boolean {
        return new RegExp('^(?:[a-z]+:)?//', 'i').test(url);
    }
    
    public static createAbsoluteMediaUrl(mediaServiceUrl: string, relativeUrl: string): string {

        const tenantPart = mediaServiceUrl.lastIndexOf("/");

        mediaServiceUrl = mediaServiceUrl.substring(0, tenantPart);

        return `${mediaServiceUrl}/${relativeUrl}`;
    }
}