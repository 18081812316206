console.log(`🚀 Version ${env.buildnumber || '<no-build>'} build from ${env.commit || '<no-commit>'} and currently running in ${env.mode} mode`);

import '@js/update';

import '../content/fortytwo/all.less';

import './00.FT';
import './FortyTwoFramework';

import './01.FT.app';
import './02.FT.app.models';

import './controllers';
import './directives';
import './filters';
import './services';

import './FT.app.factories.templatehandler';
import './FT.app.config.datepicker';
import './FT.date';
import './FT.ui.zIndexManager';
