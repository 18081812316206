ft.app.controller('planningItemController',
    ['$scope', '$element', 'close', 'child', 'item', '$timeout', 'AppService', 'PlanningModalService',
        function ($scope, $element, close, child, item, $timeout, AppService, PlanningModalService) {

            var initialized = false;

            $scope.data = {
                child: null,
                planningConfig: ft.app.planning,
                hasActions: false,
                item: item
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                $scope.determineHasActions();
                $scope.data.child = AppService.getUser(child);
                initialized = true;

            };

            $scope.getStartDate = function () {

                if (!item.day.date || !item.block.start) {
                    return;
                }

                var m = moment(item.day.date).format(ft.app.datetime.formats.toAPIDate);
                var date = new Date(m + ' ' + item.block.start);

                return date;
            };

            $scope.getEndDate = function () {

                if (!item.day.date || !item.block.end) {
                    return;
                }

                var m = moment(item.day.date).format(ft.app.datetime.formats.toAPIDate);
                var date = new Date(m + ' ' + item.block.end);

                return date;

            };

            $scope.isCurrentlyActive = function () {

                var now = new Date();
                var start = $scope.getStartDate();
                var end = $scope.getEndDate();

                if (!start || !end) {
                    return false;
                }

                return start <= now && now <= end;
            };

            $scope.maySwapInThePast = function () {
                return AppService.data.allowSwapdaysFromPast;
            };

            $scope.isToday = function () {
                return new Date().setHours(0, 0, 0, 0) == new Date(item.day.date).setHours(0, 0, 0, 0);
            };

            $scope.isInThePast = function () {

                var m = moment(item.day.date).format(ft.app.datetime.formats.toAPIDate);
                var end = item.block.end ? new Date(m + 'T' + item.block.end) : new Date(m);
                var now = new Date();

                return end < now;
            };

            $scope.isClosedDay = function () {
                return $scope.data.item.day.type == ft.app.planning.days.closed;
            };

            $scope.isAbsenceBlock = function () {

                var block = $scope.data.item.block;
                var config = ft.app.planning;

                return (block.status == config.states.approved) &&
                    (
                        block.type == config.types.planning ||
                        block.type == config.types.incidental ||
                        block.type == config.types.swap ||
                        block.type == config.types.absent
                    ) && (
                        block.presence == config.presence.sick ||
                        block.presence == config.presence.holiday ||
                        block.presence == config.presence.absent ||
                        block.presence == config.presence.other
                    );
            };

            $scope.determineHasActions = function () {

                var actions = ['absent', 'present', 'swap', 'cancel', 'request']; // TODO: add action 'edit'
                $scope.data.hasActions = false;

                var possible = actions.filter(function (action) {
                    return $scope.mayShowActionButton(action, true);
                });

                $scope.data.hasActions = possible && possible.length > 0;
            };

            $scope.mayShowActionButton = function (key, bypass) {

                var result = false;
                var block = $scope.data.item.block;
                var config = ft.app.planning;

                if (!$scope.data.hasActions && !bypass) {
                    return false;
                }

                // if block is rejected
                if (block.status == config.states.rejected) {
                    return false;
                }

                // if block is a swap traded from day
                if (block.type == config.types.registration && block.registrationType == config.registrations.swap && block.presence == config.presence.traded) {
                    return false;
                }

                if ($scope.isInThePast()) {
                    switch (key) {
                        case 'swap':
                            if ($scope.maySwapInThePast() && ($scope.isClosedDay() || $scope.isAbsenceBlock())) {
                                result = true;
                            }

                            break;
                    }
                } else {

                    if ($scope.data.item.day.type == config.days.closed && key != 'swap') {
                        return false;
                    }

                    switch (key) {
                        case 'absent':
                            // if not already absent and the child is expected
                            result = block.type != config.types.absent && block.presence == config.presence.present && block.status == config.states.approved;
                            break;
                        case 'present':
                            // if the child is sick during this block
                            result = block.presence == config.presence.sick;
                            break;
                        case 'swap':
                            result = block.type == config.days.closed || 
                                    (block.presence != config.presence.traded && block.status == config.states.approved && 
                                        ((block.type == config.types.planning && block.packageSupportsSwapdays) || 
                                            (block.type == config.types.registration && block.registrationType == config.registrations.incidental) || 
                                            (block.type == config.types.registration && block.registrationType == config.registrations.swap) || 
                                            block.type == config.types.absent));
                            break;
                        case 'cancel':        
                            result = block.type != config.types.absent && block.status == config.states.requested;
                            break;
                        case 'request':
                            result = block.type != config.types.absent && block.status == config.states.requestable;
                            break;
                        case 'edit':
                            result = block.type != config.types.absent && block.status == config.states.rejected;
                            break;
                    }
                }

                return result;
            };

            $scope.handleActionButton = function (evt, key) {

                evt.stopPropagation();
                evt.preventDefault();

                switch (key) {
                    case 'absent':
                        $scope.openAbsentModal(evt);
                        break;
                    case 'sick':
                        $scope.openSickModal(evt);
                        break;
                    case 'present':
                        $scope.openPresentModal(evt);
                        break;
                    case 'swap':
                        $scope.openSwapModal(evt);
                        break;
                    case 'incidental':
                        $scope.openIncidental(evt);
                        break;
                    case 'request':
                        $scope.openRequestModal(evt, false);
                        break;
                    case 'cancel':
                        $scope.openRequestModal(evt, true);
                        break;
                }
            };

            $scope.openAbsentModal = function (evt) {
                var item = $scope.data.item;

                PlanningModalService.openAbsentModal(evt, child, item.day.date, item.day.date, item.block.start, item.block.end)
                    .then(function (modal) {
                    });
            };

            $scope.openSickModal = function (evt) {
                PlanningModalService.openAbsentModal(evt, child, $scope.data.item.day.date, null, null, null, ft.app.planning.presence.sick)
                    .then(function (modal) {
                    });
            };

            $scope.openPresentModal = function (evt) {
                PlanningModalService.openPresentModal(evt, child, $scope.data.item.day.date)
                    .then(function (modal) {
                    });
            };

            $scope.openSwapModal = function (evt) {
                var data = $scope.data;
                var block = data.item.block;

                PlanningModalService.openSwapModal(evt, child, $scope.data.item.day.date, null, block.planningId, block.timeslotId)
                    .then(function (modal) {
                    });
            };

            $scope.openIncidental = function (evt) {
                PlanningModalService.openIncidentalModal(evt, child, $scope.data.item.day.date)
                    .then(function (modal) {
                    });
            };

            $scope.openRequestModal = function (evt, cancellation) {
                var data = $scope.data.item;

                var item = {
                    date: data.day.date,
                    dayType: data.day.type,
                    block: data.block
                };

                PlanningModalService.openRequestModal(evt, child, [item], cancellation)
                    .then(function (modal) {
                    });
            };

            $scope.close = function () {
                close({});
            };

            $timeout($scope.initialize);

        }
    ]);