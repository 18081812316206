ft.app.controller('planningItemActivitiesController',
    ['$scope', '$element', 'close', 'item', '$timeout', 'AppService', 'ModalService',
        function ($scope, $element, close, item, $timeout, AppService, ModalService) {

            var initialized = false;

            $scope.data = {
                child: null,
                item: item,
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

                $scope.data.child = AppService.getUser($scope.data.item.child);

            };

            $scope.openActivity = function (evt, activity) {
                var modal = ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'planning/activity-modal',
                    controller: 'planningItemActivityController',
                    inputs: {
                        item: {
                            activity: activity
                        }
                    }
                });
            };

            $scope.close = function () {
                close();
            };

            $timeout($scope.initialize);

        }
    ]);