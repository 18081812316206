ft.app.service('LanguageService', ['$rootScope', '$q', 'AppService', 'DataService',
    function ($rootScope, $q, AppService, DataService) {

        var initialized = false;
        var promises = {};
        var content = {};

        this.initialize = function () {

            if (initialized) {
                return;
            }

            $rootScope.$watch(function () {
                return AppService.data.currentLanguage;
            }, function (newValue, oldValue) {

                if (!content[newValue]) {
                    content[newValue] = {};
                }

            });

            initialized = true;

        };

        this.getContentAsync = function (code) {

            if (promises[code]) {
                return promises[code];
            }

            if (!content[AppService.data.currentLanguage]) {
                content[AppService.data.currentLanguage] = {};
            }

            if (content[AppService.data.currentLanguage][code]) {
                var dfd = $q.defer();
                dfd.resolve([content[AppService.data.currentLanguage][code]]);
                return dfd.promise;
            }

            promises[code] = DataService.getAppContent(AppService.data.currentLanguage, [code]);
            promises[code].then(function (results) {

                content[AppService.data.currentLanguage][results[0].code] = results[0];

                return content[AppService.data.currentLanguage][results[0].code];

            }, function () {
                return $q.reject(false);
            }).finally(function () {
                delete promises[code];
            });

            return promises[code];
        };

        this.initialize();

    }]);
