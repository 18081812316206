ft.app.service('LoginService', ['$sessionStorage', '$localStorage', '$cookieStore', '$q', 'RequestService',
    function ($sessionStorage, $localStorage, $cookieStore, $q, RequestService) {

        // token data = session token, refresh token and expire date
        // session token and expires is always saved in sessionstorage
        // refresh token and 'stay logged in' are stored in localstorage

        /*
            // First time log in
            User supplies username and password to log in. The user also determines if he wants to stay logged in.
            Username and password are used to get token data. 'Stay logged in' is stored.

            // Nth time log in (user opens new window, new session)
            Session token and expires is deleted from sessionstorage. 'Stay logged in' is questioned to determine to use refreshtoken to get new token data

            // Get data
            Use sessiontoken in header to get new data
        */

        // for now 'stay logged in' is not implemented due to 'Wet Bescherming Persoonsgegevens', therefore the refresh token is stored in session storage
        var storageRefreshToken = $sessionStorage;
        var refreshPromise;

        this.initialize = function () {

            // lets see if the user comes from an impersonation
            if ($cookieStore.get('impersonate')) {
                this.storeTokenData($cookieStore.get('impersonate'));
                $cookieStore.remove('impersonate');
            }

        };

        this.getStoredUsername = function () {
            return $localStorage.username;
        };

        this.wantsToStayLoggedIn = function () {
            return true; // $localStorage.stayLoggedIn;
        };

        this.isLoggedInAsync = function () {
            var that = this;

            return that.hasValidTokenAsync().then(
                function () {

                    return true;

                }.bind(this),
                function () {

                    // no, there isn't a valid token, try to refresh

                    return that.hasRefreshTokenAsync().then(
                        function () {

                            // refresh login
                            return that.refreshLoginAsync();

                        }, function () {

                            // there isn't a refresh token, user is not logged in at all
                            return $q.reject(false);

                        });

                }.bind(this));

        };

        this.hasValidTokenAsync = function () {

            // validates if a session token is available and determines if expires is within range

            var dfd = $q.defer();
            var token = $sessionStorage.token;

            if (!token) {
                dfd.reject(false);
                return dfd.promise;
            }

            var expiresOn = new Date(token.expires);
            var now = new Date();

            if (token.value != '' && expiresOn > now) {
                dfd.resolve(true);
            } else {
                dfd.reject(false);
            }

            return dfd.promise;

        };

        this.hasRefreshTokenAsync = function () {

            // validates if a refresh token is available

            var dfd = $q.defer();
            var token = storageRefreshToken.refreshToken;

            if (!token) {
                dfd.reject(false);
            } else {
                dfd.resolve(true);
            }

            return dfd.promise;

        };

        this.doLoginAsync = function (username, password, stayLoggedIn) {

            // use username and password to retrieve token data through dataservice
            $localStorage.stayLoggedIn = stayLoggedIn;
            $localStorage.username = username;

            var path = 'account/token';
            var b64 = window.btoa(username + ':' + password);

            // set header
            var headers = {
                'Authorization': 'Basic ' + b64
            };

            return RequestService.requestAsync({
                path: path,
                headers: headers
            }).then(
                this.handleDoLogin.bind(this),
                this.handleDoLoginError.bind(this)
            );

        };

        this.handleDoLogin = function (results) {
            ft.app.log('login done');

            // succesful login
            this.storeTokenData(results);
            return true;
        };

        this.handleDoLoginError = function (response) {
            ft.app.log('login fail');

            // failed to login                
            return handleError(response);

        };

        this.refreshLoginAsync = function () {

            if (refreshPromise) {
                return refreshPromise;
            }

            ft.app.log('refresh token');

            var path = 'account/token';

            // set header
            var headers = {
                'Authorization': 'Bearer ' + storageRefreshToken.refreshToken
            };

            refreshPromise = RequestService.requestAsync({
                path: path,
                headers: headers
            }).then(
                this.handleRefreshLogin.bind(this),
                this.handleRefreshLoginError.bind(this)
            );

            return refreshPromise;

        };

        this.handleRefreshLogin = function (results) {

            refreshPromise = null;

            // new token data
            this.storeTokenData(results);
            return true;

        };

        this.handleRefreshLoginError = function (response) {

            refreshPromise = null;

            // failed to get new token data
            return handleError(response);

        };

        this.doImpersonateAsync = function (key, id) {

            var method = 'POST';
            var path = 'account/impersonate';
            var data = {id: id};

            // set header
            var headers = {
                'Authorization': 'Bearer ' + key
            };

            return RequestService.requestAsync({
                path: path,
                headers: headers,
                method: method,
                data: data
            }).then(
                function (results) {

                    $cookieStore.put('impersonate', {
                        refreshToken: results.refreshToken,
                        accessToken: results.accessToken,
                        expires: results.expires
                    });

                    return this.handleDoLogin(results);

                }.bind(this),
                function (response) {

                    return handleError(response);

                }.bind(this)
            );

        };

        this.doLogout = function () {

            $sessionStorage.token = null;
            storageRefreshToken.refreshToken = null;
            $localStorage.stayLoggedIn = false;
            $localStorage.username = null;

        };

        this.storeTokenData = function (data) {

            storageRefreshToken.refreshToken = data.refreshToken;
            $sessionStorage.token = {
                value: data.accessToken,
                expires: data.expires
            };

        };

        this.getTokenData = function () {

            return {
                refreshToken: storageRefreshToken.refreshToken,
                token: $sessionStorage.token
            };

        };

        var handleError = function (response) {

            var message = '';
            var isReadable = false;
            var exceptions = ft.app.exceptions.types;

            if (response.data.exceptionType == exceptions.invalidOperationException ||
                response.data.exceptionType == exceptions.providerException ||
                response.data.exceptionType == exceptions.unauthorizedAccessException) {
                isReadable = true;
                message = response.data.exceptionMessage;
            }

            // fail
            return $q.reject({
                result: false,
                isReadable: isReadable,
                message: message
            });

        };

        this.initialize();

    }]);