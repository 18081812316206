import { ChildCare } from '@js/data-models';
import { ft } from '@js/definitions';
import { AppService } from '@js/services/FT.app.services.appservice';
import { DataService } from '@js/services/FT.app.services.dataservice';
import { IScope } from 'angular';
import moment from 'moment';

interface Scope extends IScope {
    save: () => void;
    sign: () => void;
    cancel: () => void;
    close: () => void;
    data: ScopeData;
}

interface ScopeData {
    accountNumber: string;
    childBorn: boolean;
    accountCity: string;
    requireDirectDebit: boolean;
    directDebitNotPossible: boolean;
    payByDirectDebit: boolean;
    payByDirectDebitPossible: boolean;
    allowChangePayByDirectDebit: boolean;
    allowChangeChildSsn: boolean;
    allowChangeResponsibleIban: boolean;
    successMessage: string;
    errorMessage: string;
    accountNumberValid: boolean;
    accountCityValid: boolean;
    nameOnContractValid: boolean;
    payByDirectDebitValid: boolean;
    iAgreeToTheTermsAndConditionsValid: boolean;
    ssnChildValid: boolean;
    nameOnContract: any;
    iAgreeToTheTermsAndConditions: boolean;
    saving: boolean;
    ssnChild: any;
    saveEnabled: boolean;
    inviteUrl: any;
    item: Item;
    contractants: ContractantState[];
}

interface ContractantState extends ChildCare.Definitions.Contracts.ContractantDetails {
    ssnValid: boolean;
}

interface Item {
    child: string;
    contract: ChildCare.Definitions.Contracts.ContractModel;
}

type Close = (result?: any, delay?: any) => void;

class ContractActivateModalController {
    public static $inject = ['$scope', 'close', 'item', '$translate', 'DataService', 'AppService'];

    private $scope: Scope;
    private item: Item;
    private $translate: angular.translate.ITranslateService;
    private dataService: DataService;
    private appService: AppService;
    private data: ScopeData;
    private attempts = 0;

    public constructor($scope: Scope, close: Close, item: Item, $translate: angular.translate.ITranslateService, dataService: DataService, appService: AppService) {
        this.$scope = $scope;
        this.item = item;
        this.$translate = $translate;
        this.dataService = dataService;
        this.appService = appService;

        this.$scope.close = (() => close({ success: !this.data.saveEnabled })).bind(this);
        this.$scope.cancel = close;
        this.$scope.sign = (() => window.open(this.data.inviteUrl, '_blank')).bind(this);
        this.$scope.save = this.save.bind(this);

        this.data = this.$scope.data = {
            item: item,
            allowChangePayByDirectDebit: false, // show account input-fields when unknown
            allowChangeChildSsn: false,
            allowChangeResponsibleIban: false,
            childBorn: true,
            accountNumber: '',
            accountCity: '',
            nameOnContract: '',
            payByDirectDebit: false,
            iAgreeToTheTermsAndConditions: false,
            accountNumberValid: true,
            accountCityValid: true,
            nameOnContractValid: true,
            payByDirectDebitValid: true,
            iAgreeToTheTermsAndConditionsValid: true,
            ssnChildValid: true,
            requireDirectDebit: this.appService.data.requireDirectDebit,
            directDebitNotPossible: this.appService.data.directDebitNotPossible,
            saving: false,
            saveEnabled: true,
            successMessage: '',
            errorMessage: '',
            ssnChild: '',
            inviteUrl: null,
            contractants: null,
            payByDirectDebitPossible: true
        };

        this.initialize();
    }

    private initialize(): void {
        const { accountNumber, birthDate, accountCity, nameOnContract, payByDirectDebit, allowChangePayByDirectDebit, allowChangeChildSsn, allowChangeResponsibleIban, contractants } = this.item.contract;

        const parsedBirthDate = moment(birthDate);
        if (parsedBirthDate.isValid) {
            this.data.childBorn = parsedBirthDate.isBefore(moment());
        }

        this.data.accountNumber = accountNumber;
        this.data.accountCity = accountCity;
        this.data.nameOnContract = nameOnContract;
        this.data.payByDirectDebit = this.appService.data.requireDirectDebit || payByDirectDebit;
        if (this.appService.data.directDebitNotPossible) {
            this.data.payByDirectDebit = false;
        }
        this.data.allowChangePayByDirectDebit = allowChangePayByDirectDebit;
        this.data.allowChangeChildSsn = allowChangeChildSsn;
        this.data.allowChangeResponsibleIban = allowChangeResponsibleIban;
        this.data.contractants = [...contractants.map(x => ({ ...x, ssnValid: true }) as ContractantState)];
    }

    private validate(): boolean {
        this.data.successMessage = '';
        this.data.errorMessage = '';

        this.data.accountNumberValid = true;
        this.data.accountCityValid = true;
        this.data.nameOnContractValid = true;
        this.data.payByDirectDebitValid = !this.data.allowChangePayByDirectDebit || !this.appService.data.requireDirectDebit || this.data.payByDirectDebit
            || !this.data.payByDirectDebitPossible;
        this.data.iAgreeToTheTermsAndConditionsValid = this.data.iAgreeToTheTermsAndConditions;
        this.data.ssnChildValid = !this.data.allowChangeChildSsn || !this.data.childBorn || (this.data.ssnChild != null && this.data.ssnChild.trim() != '');

        let allSsnValid = true;
        for (const contractant of this.data.contractants.filter(x => x.allowChangeSSN)) {
            contractant.ssnValid = contractant.ssn != null && contractant.ssn.trim() != '';
            allSsnValid = allSsnValid && contractant.ssnValid;
        }

        if (this.data.allowChangePayByDirectDebit && this.data.payByDirectDebit && this.data.payByDirectDebitPossible) {
            if (this.data.allowChangeResponsibleIban) {
                if (!this.data.accountNumber.trim()) {
                    this.data.accountNumberValid = false;
                }

                if (!this.data.accountCity.trim()) {
                    this.data.accountCityValid = false;
                }
            }

            if (!this.data.nameOnContract.trim()) {
                this.data.nameOnContractValid = false;
            }
        }

        const { accountNumberValid, accountCityValid, nameOnContractValid, payByDirectDebitValid, iAgreeToTheTermsAndConditionsValid, ssnChildValid } = this.data;
        const isValid =
            accountNumberValid &&
            accountCityValid &&
            nameOnContractValid &&
            payByDirectDebitValid &&
            iAgreeToTheTermsAndConditionsValid &&
            ssnChildValid &&
            allSsnValid;

        return isValid;
    }

    private async save(): Promise<void> {
        if (!this.validate()) {
            return;
        }

        this.data.saving = true;
        try {
            const results = await this.dataService.approveContractAsync(this.item.child, this.item.contract.id, {
                accountNumber: this.data.accountNumber,
                accountCity: this.data.accountCity,
                nameOnContract: this.data.nameOnContract,
                payByDirectDebit: this.data.payByDirectDebit && this.data.payByDirectDebitPossible,
                iAgreeToTheTermsAndConditions: this.data.iAgreeToTheTermsAndConditions,
                ssnChild: this.data.ssnChild,
                contractants: this.data.contractants
            });

            this.data.saveEnabled = false;
            this.attempts = 0;
            if (results.isApproved) {
                this.data.successMessage = this.$translate.instant('CONTRACTS-ACTIVATE.ACTIVATION-SAVED');
            }
            else if (results.inviteUrl) {
                this.data.successMessage = results.message;
                this.data.inviteUrl = results.inviteUrl;
            }
            else if (results.message) {
                this.data.errorMessage = results.message;
            }
            else {
                this.data.successMessage = this.$translate.instant('CONTRACTS-ACTIVATE.ACTIVATION-SAVED');
            }
        } catch (error) {
            this.attempts += 1;

            if (this.attempts > 3) {
                this.data.errorMessage = this.$translate.instant('CONTRACTS-ACTIVATE.ERROR-CONTINUES');
            } else {
                this.data.errorMessage = error && !error.result && error.isReadable ? error.message : this.$translate.instant('CONTRACTS-ACTIVATE.ERROR-SAVE-ACTIVATION');
            }
        } finally {
            this.data.saving = false;
            this.$scope.$apply();
        }
    }
}

ft.app.controller('contractActivateModalController', ContractActivateModalController);