/* eslint-disable @typescript-eslint/camelcase */
import { ft, ng } from '../definitions';
import { IQService, IPromise, IHttpService } from 'angular';
import { FortyTwoLoginService, IFortyTwoRequestService, FortyTwoRequest } from '@js/FortyTwoFramework';
import moment, { Moment } from 'moment';
import angular from 'angular';
import { ngStorage } from 'ngstorage';
import { StrippenkaartBalanceResponse } from '@js/models';
import { ChildCare } from '@js/data-models';

type ChildActivitiesResponse = ChildCare.Definitions.Activity.ChildActivitiesResponse;

export class DataService {
  public static $inject = ['$q', '$sessionStorage', 'RequestService', 'LoginService', '$http'];

  private $q: IQService;
  private $sessionStorage: ngStorage.StorageService;
  private RequestService: IFortyTwoRequestService;
  private LoginService: FortyTwoLoginService;
  private $http: IHttpService;
  private secondaryApiUrl: string;

  constructor($q: IQService, $sessionStorage: ngStorage.StorageService, RequestService: IFortyTwoRequestService, LoginService: FortyTwoLoginService, $http: IHttpService) {
    this.$q = $q;
    this.$sessionStorage = $sessionStorage;
    this.RequestService = RequestService;
    this.LoginService = LoginService;
    this.$http = $http;

    this.secondaryApiUrl = '';
  }

  public setSecondaryApiUrl(url: string) {
    this.secondaryApiUrl = url;
  }

  public getMainNavigation() {
    var url = '/DummyServiceData/';
    var path = 'navigation.json';

    return this.RequestService.requestAsync({
      url: url,
      path: path
    }).then((results) => results, (status) => status);
  }

  private doLoggedInRequest(obj) {
    return this.LoginService.isLoggedInAsync().then(
      () => {
        obj.headers = { 'Authorization': 'Bearer ' + this.$sessionStorage.refreshToken };
        return this.doRequest(obj, true);
      },
      () => {
        return this.$q.reject(null);
      });
  }

  public doRequest(request: FortyTwoRequest, loggedIn = false): IPromise<any> {
    return this.RequestService.requestAsync(request, loggedIn).then(
      (response) => response,
      (response) => this.handleError(response)
    );
  }

  private handleError(response) {

    let message = '';
    let isReadable = false;
    let exceptions = ft.app.exceptions.types;

    if (response.data != null && response.data.message != null) {
      isReadable = true;
      message = response.data.message;
    }

    if (response.data && (
      response.data.exceptionType == exceptions.invalidOperationException ||
      response.data.exceptionType == exceptions.providerException ||
      response.data.exceptionType == exceptions.unauthorizedAccessException)) {
      isReadable = true;
      message = response.data.exceptionMessage;
    }

    return this.$q.reject({
      result: false,
      isReadable: isReadable,
      message: message
    });
  }

  public getFamilyAsync(getAllChildren?: boolean): IPromise<any> {
    var path = 'parent/contacts';
    if (typeof (getAllChildren) !== 'undefined' )
    {
      path = `parent/contacts?allChildren=${getAllChildren}`;
    }
    return this.doLoggedInRequest({
          path: path,
          method: 'GET'
        }).then((results) => {
          results.forEach((item) => {
            if (item.type == ft.app.family.types.child) {
              if (item.gender == ft.app.gender.female) {
                item.header = {
                  'type': 'image',
                  'poster': 'https://www.partou.nl/media/ekjrphnhzp8bag7q4z646xj5q-w1920-h400-q100-c',
                  'url': 'https://www.partou.nl/media/ekjrphnhzp8bag7q4z646xj5q-w1920-h400-q100-c'
                };
              } else {
                item.header = {
                  'type': 'image',
                  'poster': 'https://www.partou.nl/media/x27vgjsq2spyfvacs3evg0dxl-w1920-h400-q80-c',
                  'url': 'https://www.partou.nl/media/x27vgjsq2spyfvacs3evg0dxl-w1920-h400-q80-c'
                };
              }
            }

          });

          return results;
        }, (response) => this.handleError(response));
      };

  public getChild(id: string) {
    return this.getFamilyAsync().then((results) => {
      var children = results.filter((item) => {

        return item.id == id;

      });

      return children[0];
    }, (response) => response);

  }

  public registerChildAsync(data: any) {
    return this.doRequest({
      path: 'register/new',
      method: 'POST',
      data: data
    });
  }

  public registerSiblingAsync(data: any): IPromise<any> {
    return this.doLoggedInRequest({
      path: 'register/sibling',
      method: 'POST',
      data: data
    });
  }

  public saveChildAsync(data: any) {
    return this.doLoggedInRequest({
      path: 'parent/contacts',
      method: 'POST',
      data: data
    });

  }

  public saveUserAsync(data: any) {
    return this.doLoggedInRequest({
      path: 'parent/contacts',
      method: 'POST',
      data: data
    });

  }

  public saveProfilePictureAsync(userId: string, userType: string, image64) {
    return this.doLoggedInRequest({
      path: 'parent/profileimage',
      method: 'POST',
      data: {
        id: userId,
        type: userType,
        content: image64
      }
    });

  }

  public setPasswordAsync(key: string, password: string) {
    return this.doRequest({
      path: 'account/setpassword' + (!password ? '?accessKey=' + key : ''),
      method: !password ? 'GET' : 'POST',
      data: !password ? null : { accessKey: key, password: password }
    });

  }

  public saveLanguageAsync(language: string) {
    return this.doLoggedInRequest({
      path: 'account/setlanguage',
      method: 'POST',
      data: { languageId: language }
    });

  }

  public changePasswordAsync(originalPassword: string, newPassword: string) {
    return this.doLoggedInRequest({
      path: 'account/changepassword',
      method: 'POST',
      data: {
        currentPassword: originalPassword,
        newPassword: newPassword
      }
    });

  }

  public forgotPasswordAsync(email: string) {
    return this.doRequest({
      path: `account/reset?userName=${encodeURIComponent(email)}`,
      method: 'POST',
      data: {
        userName: email
      }
    });
  }

  public registerEmailCheckAsync(email: string) {
    return this.doRequest({
      path: 'register/emailcheck',
      method: 'POST',
      data:  {
        userName: email
      }
    });
  }

  public getRegisterLocationsAsync(packageIds: any, schoolId: string, date?: any) {

    const stringDate = date != null ? moment(date).format(ft.app.datetime.formats.toAPIDate) : undefined;

    return this.doRequest({
      path: 'register/locations',
      method: 'POST',
      data:  {
        tariefgroepIds: packageIds,
        schoolId: schoolId,
        date: stringDate
      }
    });
  }

  public getRegisterPackagesAsync(type: string, school: string, contract: string) {
    return this.doRequest({
      path: 'register/options',
      method: 'POST',
      data:  {
        type: type,
        schoolId: school,
        contractId: contract
      }
    });
  }

  public getPlanningAsync(childId: string, year: number, month: number) {
    return this.doLoggedInRequest({
      path: `child/${childId}/planning`,
      method: 'POST',
      data:  {
        year: year,
        month: month + 1
      }
    });
  }

  public getPossibleTimesForDateAsync(childId: string, date: any, finalOnly: boolean, entityType: number) {

    return this.doLoggedInRequest({
      path: `child/${childId}/times`,
      method: 'POST',
      data:  {
        date: date,
        finalOnly: finalOnly,
        entityType: entityType
      }
    });
  }

  public validateRequestAsync(childId: string, requestables: any) {
    return this.doLoggedInRequest({
      path: `child/${childId}/request/validate`,
      method: 'POST',
      data:  {
        requestables: requestables
      }
    });
  }

  public saveRequestAsync(childId: string, requestables: any, asIncidental: boolean, useCreditHours: boolean) {
    return this.doLoggedInRequest({
      path: `child/${childId}/request`,
      method: 'POST',
      data:  {
        requestables: requestables,
        registerAsIncidental: asIncidental,
        useCreditHours: useCreditHours
      }
    });
  }

  public cancelRequestAsync(childId: string, blockId: string, blockType: string, date: any, start: any, end: any, useCreditHours: boolean) {
    return this.doLoggedInRequest({
      path: `child/${childId}/request`,
      method: 'DELETE',
      data:  {
        requestableId: blockId,
        requestableType: blockType,
        date: date,
        start: start,
        end: end,
        useCreditHours: useCreditHours
      }
    });
  }

  public saveAbsentAsync(childId: string, from: any, till: any, reason: string, comments: string) {
    return this.doLoggedInRequest({
      path: `child/${childId}/absences`,
      method: 'POST',
      data:  {
        start: from,
        end: till,
        description: comments,
        type: reason
      }
    });
  }

  public savePresentAsync(childId: string, from: any) {
    return this.doLoggedInRequest({
      path: `child/${childId}/absences`,
      method: 'POST',
      data:  {
        type: ft.app.planning.presence.sick,
        end: from
      }
    });
  }

  public saveSwapAsync(childId: string, from: any, till: any, fromTimes: any, tillTimes: any, comments: string) {
    return this.doLoggedInRequest({
      path: `child/${childId}/swapday`,
      method: 'POST',
      data:  {
        oldDate: from,
        newDate: till,
        absent: fromTimes,
        present: tillTimes,
        comments: comments
      }
    });
  }

  public saveIncidentalAsync(childId: string, from: any, times: any, useCreditHours: boolean, comments: string) {
    return this.doLoggedInRequest({
      path: `child/${childId}/incidentalday`,
      method: 'POST',
      data:  {
        date: from,
        present: times,
        comments: comments,
        useCreditHours: useCreditHours
      }
    });
  }

  public getIrregularHoursSummaryAsync(childId: string, date: any, planningId: string) {
    var url = `child/${childId}/incidentalday/${date}/summary`;
    if (planningId) {
      url = `child/${childId}/incidentalday/${date}/summary?planningid=${planningId}`;
    }

    return this.doLoggedInRequest({
      path: url,
      method: 'GET'
    });
  }

  public getActivitiesByRangeAsync(childId: string, start: any, end: any) {

    var headers = {
      'Authorization': 'Bearer ' + this.$sessionStorage.refreshToken
    };

    var url = this.secondaryApiUrl;
    var path = 'activiteitenbydate';
    var method = 'POST';
    var data = {
      child_id: childId,
      start: start,
      end: end
    };

    return this.LoginService.isLoggedInAsync().then(
      () => {

        return this.RequestService.requestAsync({
          url: url,
          path: path,
          method: method,
          data: data,
          headers: headers
        }).then((results) => {

          // TODO: don't want this
          if (results.forEach) {
            results.forEach((item) => {

              var mDate = moment(item.date).format(ft.app.datetime.formats.toAPIDate);
              item.date = mDate;

            });

            return results;
          } else {
            return this.$q.reject(false);
          }


        }, (response) => this.handleError(response));

      }, () => this.$q.reject(false));

  };

  // timeline
  public getActivitiesAsync(child: string, parent: string, start, end) {

    var headers = {
      'Authorization': 'Bearer ' + this.$sessionStorage.refreshToken
    };

    var url = this.secondaryApiUrl;
    var path = 'gettimeline';
    var method = 'POST';
    var data = {
      'child_id': child,
      'parent_id': parent,
      'start': start,
      'end': end
    };

    return this.LoginService.isLoggedInAsync().then(
      () => {

        return this.RequestService.requestAsync({
          url: url,
          path: path,
          method: method,
          data: data,
          headers: headers
        }).then((results) => {

          // TODO: Tik-Tik returns or JSON object or string as error message

          if (results && results.forEach) {
            results.forEach((item) => {

              if (item.icon) {
                item.icon = item.icon.replace(/\.png/gi, '_invert.png');
              }

              if (!item.icon) {

                if (item.key == ft.app.activities.keys.activity) {

                } else if (item.media && item.media.length) {
                  item.icon = item.media[0].poster;
                }

              }

            });

            return results;
          } else {
            return this.$q.reject(false);
          }

        }, (response) => this.handleError(response));

      }, () => this.$q.reject(null));

  }

  public getTimelineForPosts(childId: string, start: moment.Moment, end: moment.Moment) {
    return this.doLoggedInRequest({
      path: `posts/timeline?start=${start.format(ft.app.datetime.formats.toAPIDate)}&end=${end.format(ft.app.datetime.formats.toAPIDate)}&childId=${childId}`,
      method: 'GET'
    });
  }

  public getTimelineForDiaries(childId: string, start: moment.Moment, end: moment.Moment) {
    return this.doLoggedInRequest({
      path: `diary/timeline?start=${start.format(ft.app.datetime.formats.toAPIDate)}&end=${end.format(ft.app.datetime.formats.toAPIDate)}&childId=${childId}`,
      method: 'GET'
    });
  }

  public getTimelineForStories(childId: string, start: moment.Moment, end: moment.Moment) {
    return this.doLoggedInRequest({
      path: `story/timeline?start=${start.format(ft.app.datetime.formats.toAPIDate)}&end=${end.format(ft.app.datetime.formats.toAPIDate)}&childId=${childId}`,
      method: 'GET'
    });
  }

  public getStoryPdf(storyId: string, childId: string, isDownload: boolean) {
    return this.doLoggedInRequest({
      path: `storypreview/${storyId}?childId=${childId}&isDownload=${isDownload}`,
      method: 'GET'
    });
  }

  public getTimelineForMediaItems(childId: string, start: moment.Moment, end: moment.Moment) {
    return this.doLoggedInRequest({
      path: `media/timeline?start=${start.format(ft.app.datetime.formats.toAPIDate)}&end=${end.format(ft.app.datetime.formats.toAPIDate)}&childId=${childId}`,
      method: 'GET'
    });
  };

  public getTimelineForPresences(childId: string, start: moment.Moment, end: moment.Moment) {
    let path = 'presences';
    if (childId != null) {
        path = 'child/' + childId + '/presences';
    }

    return this.doLoggedInRequest({
      path: path,
      method: 'POST',
      data: {
        start: start.format(ft.app.datetime.formats.toAPIDate),
        end: end.format(ft.app.datetime.formats.toAPIDate)
      }
    });
  }

  public saveActivityCommentAsync(key, item, parent: string, comment: string) {
    return this.doLoggedInRequest({
      url: this.secondaryApiUrl,
      path: 'postitemmessage',
      method: 'POST',
      data: {
        key: key,
        item_id: item,
        parent_id: parent,
        comment: comment
      }
    });
  }

  public toggleFavoriteAsync(key, item, parent) {
    return this.doLoggedInRequest({
      url: this.secondaryApiUrl,
      path: 'faveunfave',
      method: 'POST',
      data: {
        key: key,
        item_id: item,
        parent_id: parent
      }
    });
  }

  public getEmployeesByRangeAsync(childId: string, start, end) {

    var path = 'groups/employees/' + start + '/' + end;
    var method = 'POST';
    var data = {
      child_id: childId,
      start: start,
      end: end
    };

    return this.LoginService.isLoggedInAsync().then(
      () => {

        return this.RequestService.requestAsync({
          path: path,
          method: method,
          data: data
        }, true).then((results) => {

          // TODO: Tik-Tik returns or JSON object or string as error message

          if (!ng.isString(results)) {
            var key, obj;
            var date;
            var employees = [];

            for (key in results) {
              obj = results[key];
              employees.push(obj);
            }

            // done
            return employees;
          } else {
            return this.$q.reject(null);
          }

        }, (response) => this.handleError(response));

      }, () => this.$q.reject(false));
  }

  public getMediaAlbumsAsync() {
    return this.doLoggedInRequest({
      path: 'media/albums',
      method: 'GET'
    });
  }

  public getMediaUntilAsync(dateTime, childId: string) {
    var path = 'media/until/' + dateTime + '/100';
    if (childId != null)
    {
        path = path + '?childId=' + childId;
    }
    return this.doLoggedInRequest({
      path: path,
      method: 'GET'
    });
  }

  public getMediaSinceAsync(dateTime) {
    return this.doLoggedInRequest({
      path: 'media/since/' + dateTime,
      method: 'GET'
    });
  }

  public saveConversationAsync(message: any, childrenIds: any, childId: string) {
    const obj: FortyTwoRequest = {
      path: 'posts/post',
      method: 'POST',
      data: {
        title: message.title,
        content: message.content,
        audiences: childrenIds,
        childId: childId
      }
    };

    return this.doLoggedInRequest(obj);
  }

  public answerToPostAsync(conversation, replyComment, answer) {
    return this.doLoggedInRequest({
      path: 'posts/post',
      method: 'POST',
      data: {
        postId: conversation.postId,
        content: answer,
        commentId: replyComment ? replyComment.commentId : null
      }
    });
  }

  public getMessagesAsync(dateTime) {
    return this.doLoggedInRequest({
      path: 'posts/until/' + dateTime + '/3',
      method: 'GET'
    });
  }

  public getMessagesSinceAsync(dateTime) {
    return this.doLoggedInRequest({
      path: 'posts/since/' + dateTime,
      method: 'GET'
    });
  }

  public getCommentsForMessage(message) {
    return this.doLoggedInRequest({
      path: 'posts/post/' + message.postId,
      method: 'GET'
    });
  }

  public getMessageAudiencesAsync() {
    return this.doLoggedInRequest({
      path: 'posts/audiences',
      method: 'GET'
    });
  }

 public getDocumentsAsync(): IPromise<ChildCare.Definitions.Family.DocumentsResponse[]> {
    return this.doLoggedInRequest({
      path: 'documents',
      method: 'GET'
    });
  }

  public getChildDocumentsAsync(child: string): IPromise<ChildCare.Definitions.Family.DocumentsResponse[]>{
    return this.doLoggedInRequest({
      path: `child/${child}/documents`,
      method: 'GET',
      data: {
        childId: child
      }
    });
  }

  public getChildActivities(childId: string): IPromise<ChildActivitiesResponse> {
    return this.doLoggedInRequest({
      path: `child/${childId}/activities`,
      method: 'GET',
      data: {
        childId: childId
      }
    });
  }

  public getContractsAsync(child: string): IPromise<ChildCare.Definitions.Contracts.ContractModel[]> {
    return this.doLoggedInRequest({
      path: `child/${child}/contracts`,
      method: 'GET',
      data: {
        childId: child
      }
    });
  }

  public newContractAsync(obj: any) {
    return this.doLoggedInRequest({
      path: 'register/new',
      method: 'POST',
      data: obj
    });
  }

  public editContractAsync(obj: any) {
    return this.doLoggedInRequest({
      path: 'register/change',
      method: 'POST',
      data: obj
    });
  }

  public approveContractAsync(child: string, id: string, contract: any) {
    return this.doLoggedInRequest({
      path: `child/${child}/contract/${id}/approve`,
      method: 'POST',
      data: contract
    });
  }

  public cancelContractAsync(child: string, id: string, comments: string) {
    return this.doLoggedInRequest({
      path: `child/${child}/contract/${id}/cancel`,
      method: 'POST',
      data: {
        remarks: comments
      }
    });
  }

  public rejectContractAsync(child: string, id: string, comments: string) {
    return this.doLoggedInRequest({
      path: `child/${child}/contract/${id}/reject`,
      method: 'POST',
      data: {
        remarks: comments
      }
    });
  }

  public endContractAsync(child: string, id: string, comments: string, end: any) {
    return this.doLoggedInRequest({
      path: `child/${child}/contract/${id}/end`,
      method: 'POST',
      data: {
        remarks: comments,
        end: end
      }
    });
  }

  public getRequestInformationAsync(childId: string, requestId: string): IPromise<ChildCare.Definitions.Contracts.RequestReceivedInformation> {
    return this.doLoggedInRequest({
      path: `child/${childId}/request/${requestId}/information`,
      method: 'GET',
    });
  }
  
  public getFinancialOverviewAsync() {
    return this.doLoggedInRequest({
      path: 'financial/overview',
      method: 'GET'
    });
  }

  public getWaitinglistAsync(child: string) {

    return this.doLoggedInRequest({
      path: `child/${child}/requests`,
      method: 'GET',
      data: {
        childId: child
      }
    });
  }

  public submitWaitinglistItemsAsync(child: string, approved: string[], rejected: string[]) {
    return this.doLoggedInRequest({
      path: `child/${child}/waitinglistitems`,
      method: 'POST',
      data: {
        approved: approved,
        rejected: rejected
      }
    });
  }

  public getRequestsAsync(child: string): IPromise<any> {
    return this.doLoggedInRequest({
      path: `child/${child}/requests`,
      method: 'GET'
    });
  }

  public getHolidayBalanceAsync(childId: string, date: any) {
    return this.doLoggedInRequest({
      path: `child/${childId}/currentbalance/${date}`,
      method: 'GET'
    });
  }

  public saveHolidayBalanceAsync(childId: string, start: any, requestedBalance: number) {

    return this.doLoggedInRequest({
      path: `child/${childId}/currentbalance`,
      method: 'POST',
      data: {
        start: start,
      requestedBalance: requestedBalance
      }
    });
  }

  public getStripCardBalanceAsync(childId: string): IPromise<StrippenkaartBalanceResponse> {

    return this.doLoggedInRequest({
      path: `strippenkaart/${childId}/balance`,
      method: 'GET'
    });
  }

  public saveChildDocumentAsync(file: any, childId: string) {
    var path = `child/${childId}/uploaddocument`;

    return this.LoginService.isLoggedInAsync().then(() => {
      var fileFormData = new FormData();
      fileFormData.append('file', file);

      return this.$http.post(ft.app.baseApiUrl + path, fileFormData, {
        transformRequest: angular.identity,
        headers: {
          'Authorization': `Bearer ${this.$sessionStorage.token.value}`,
          'Content-Type': undefined
        }

      }).catch(reason => this.handleError(reason));
    });
  }

  public saveStripCardBalanceAsync(childId: string, companyId: string, packageId: string) {

    return this.doLoggedInRequest({
      path: `strippenkaart/${childId}/new`,
      method: 'POST',
      data: {
        companyId: companyId,
        packageId: packageId
      }
    });
  };

  public getFeatures(): IPromise<ChildCare.Definitions.Configuration.FeaturesResponse> {
    return this.RequestService.requestAsync({ path: 'configuration/features' }).then(
        (results) => results,
        (response) => this.handleError(response)
    );
  }

  public getAppConfig() {
    return this.RequestService.requestAsync({
      path: 'configuration'
    }).then((results) => {
      return results;
    }, (response) => {
      return this.handleError(response);
    });
  }

  public getAppContent(language, codes) {
    var method = 'POST';
    var path = 'content';

    var data = {
      language: language,
      codes: codes
    };

    return this.RequestService.requestAsync({
      path: path,
      method: method,
      data: data,
    }).then((results) => results, (response) => this.handleError(response));
  }

  
}

ft.app.service('DataService', DataService);