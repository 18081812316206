ft.app.controller('incidentalModalController',
    ['$scope', '$element', 'close', 'item', '$timeout', '$translate', 'AppService', 'DataService', 'CheckStateChangeService',
        function ($scope, $element, close, item, $timeout, $translate, AppService, DataService, CheckStateChangeService) {
            ft.app.log(item);
            CheckStateChangeService.checkFormOnStateChange($scope, 'incidentalForm');

            var initialized = false;
            item = item || {};

            var loadingKeyPossibleTimes = 'possibleTimes';

            var addLoading = function (key) {
                if ($scope.data.loading.indexOf(key) < 0) {
                    $scope.data.loading.push(key);
                }
            };

            var removeLoading = function (key) {
                var idx = $scope.data.loading.indexOf(key);
                if (idx >= 0) {
                    $scope.data.loading.splice(idx, 1);
                }
            };

            $scope.appData = AppService.data;
            $scope.data = {
                attempts: 0,
                loading: [],
                saving: false,
                saveEnabled: true,
                comments: '',
                format: 'dd-MM-yyyy',
                now: new Date(),
                tomorrow: new Date(new Date().setDate(new Date().getDate() + 1)),
                from: null,
                fromOpened: false,
                fromValid: true,
                isToday: false,
                dateOptions: {
                    formatYear: 'yy',
                    minDate: new Date()
                },
                selectedTimes: [],
                possibleTimes: [],
                selectedTimesValid: true, // if a time is selected
                timesValid: true, // if start/end time are valid from the selected
                possibleTimesMessage: null,
                creditHoursApplicable: false,
                useCreditHours: true
            };

            $scope.$watch('data.selectedTimes', function () {
                $scope.data.selectedTimes.forEach(function (time) {
                    if (time.creditHours > 0) {
                        $scope.data.creditHoursApplicable = true;
                    }
                });
            }, true);

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                $scope.data.from = item.from ? new Date(item.from) : new Date();
                $scope.handleChangedDate();

                initialized = true;
            };

            $scope.handleChangedDate = function () {

                if ($scope.data.from) {
                    $scope.getPossibleTimes();
                }

            };

            $scope.getPossibleTimes = function () {

                addLoading(loadingKeyPossibleTimes);

                var data = $scope.data;

                data.possibleTimes.length = 0;
                data.selectedTimes.length = 0;
                data.possibleTimesMessage = null;
                data.timesValid = true;

                var date = moment($scope.data.from).format(ft.app.datetime.formats.toAPIDate);
                var prm = DataService.getPossibleTimesForDateAsync(item.child, date, true, 3458);
                prm.then($scope.handleGetPossibleTimes, $scope.handleGetPossibleTimesError);
                prm.finally(function () {
                    removeLoading(loadingKeyPossibleTimes);
                    data.creditHoursApplicable = data.possibleTimes.some(function (e) {
                        return e.creditHoursApplicable;
                    });
                });

            };

            $scope.handleGetPossibleTimes = function (results) {
                var data = $scope.data;

                if (results.availableTimeslots) {
                    results.availableTimeslots.sort(function (a, b) {

                        var aStart = parseInt(a.start);
                        var bStart = parseInt(b.start);

                        if (aStart > bStart) {
                            return 1;
                        } else if (aStart < bStart) {
                            return -1;
                        } else {
                            return 0;
                        }

                    });

                    if (results.availableTimeslots.length == 1) {
                        data.selectedTimes.push(results.availableTimeslots[0]);
                    }
                }

                $scope.data.possibleTimesMessage = results.availableTimeslotsMessage || null;
                $scope.data.possibleTimes = results.availableTimeslots || [];

                $scope.data.possibleTimes.forEach(function (time) {
                    $scope.resetTimes(time);
                });

            };

            $scope.handleGetPossibleTimesError = function (response) {
                $scope.data.possibleTimes.length = 0;
            };

            $scope.open = function (evt) {
                evt.preventDefault();
                evt.stopPropagation();

                $scope.data.fromOpened = true;
            };

            $scope.save = function () {
                var apiFrom, date;
                var data = $scope.data;
                data.errorMessage = '';
                data.successMessage = '';
                data.isToday = false;
                data.fromValid = true;
                data.selectedTimesValid = true;
                data.timesValid = true;

                date = moment().format(ft.app.datetime.formats.toAPIDate);

                // if from is not defined or not valid if defined
                if (!data.from) {
                    data.fromValid = false;
                } else {
                    apiFrom = moment(data.from).format(ft.app.datetime.formats.toAPIDate);
                    data.fromValid = moment(apiFrom).isValid();
                }

                if (data.selectedTimes && !data.selectedTimes.length) {
                    data.selectedTimesValid = false;
                } else if ($scope.appData.showIrregularTimeFields) {

                    var reqStart, reqEnd, start, end;

                    if (data.fromValid) {
                        data.isToday = date == apiFrom;
                    }

                    // don't validate, but just set to initial value
                    // should be done by binding, but for some reason that doesn't work
                    data.selectedTimes.forEach(function (item) {

                        item.startError = false;
                        item.endError = false;

                        if (!item.requestedStart) {
                            item.requestedStart = item.start;
                        }

                        if (!item.requestedEnd) {
                            item.requestedEnd = item.end;
                        }

                        reqStart = new Date(date + ' ' + item.requestedStart);
                        reqEnd = new Date(date + ' ' + item.requestedEnd);
                        start = new Date(date + ' ' + item.start);
                        end = new Date(date + ' ' + item.end);

                        if (reqEnd < reqStart) {
                            item.endError = true;
                        }

                        if (data.isToday && reqStart < start) {
                            item.startError = true;
                        }

                        if (item.startError || item.endError) {
                            data.timesValid = false;
                        }

                    });

                }

                if (!data.fromValid || !data.selectedTimesValid) {
                    return;
                }

                apiFrom = moment(data.from).format(ft.app.datetime.formats.toAPIDate);

                data.saving = true;
                var prm = DataService.saveIncidentalAsync(item.child, apiFrom, data.selectedTimes, data.useCreditHours, data.comments);
                prm.then($scope.handleSave, $scope.handleSaveError);
                prm.finally(function () {
                    data.saving = false;
                    $scope.close();
                });
            };

            $scope.handleSave = function (results) {
                $scope.data.saveEnabled = false;
                $scope.data.attempts = 0;
                $scope.data.successMessage = $translate.instant('PLANNING-INCIDENTAL.SAVED');
            };

            $scope.handleSaveError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('PLANNING-INCIDENTAL.ERROR-CONTINUES');
                } else {
                    $scope.data.errorMessage = $translate.instant('PLANNING-INCIDENTAL.ERROR-SAVE-INCIDENTAL');
                }

                if (response && !response.result && response.isReadable) {
                    $scope.data.errorMessage += ' ' + response.message;
                }
            };

            $scope.resetTimes = function (time) {
                time.requestedStart = time.start.substr(0, 5);
                time.requestedEnd = time.end.substr(0, 5);
            };

            $scope.close = function () {
                if (!$scope.data.saving) {
                    ($scope.data.saveEnabled) ? close() : close({});
                }
            };

            $scope.cancel = function () {
                if (!$scope.data.saving) {
                    close();
                }
            };

            $timeout($scope.initialize);

        }]);