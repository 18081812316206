ft.app.directive('ftClick', function () {
    return function (scope, element, attrs) {

        element.on('touch click', function (event) {

            event.preventDefault();
            event.stopPropagation();

            scope.$apply(attrs['ftClick']);

        });

        scope.$on('$destroy', function () {
            element.off('touch click');
        });

    };
});
