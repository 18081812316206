export class NetworkUtils {
    public static getTenantFromUrl(url: string): string | null {
        const tenantRegex = /(?:(?:groep|backoffice|ouder|vervoer)-)?([a-z-0-9]+)\.kindplanner.nl/i;
        const match = tenantRegex.exec(url);
        if (match != null) {
            return match[1];
        }

        return null;
    }

    public static createBackOfficeApiUrl(tenant: string): string {
        return `https://backoffice-${tenant}.kindplanner.nl/api/`;
    }

    public static getQueryParam(param: string): string {
        const regex = /([a-z]+)=([a-z0-9:/%.-]+)/ig;
        const search = window.location.href;
        param = param.toLowerCase();

        let match: RegExpExecArray;

        do {
            match = regex.exec(search);
            if (match != null) {
                const queryParam = match[1]?.toLowerCase();
                const queryValue = match[2];
                if (queryParam == param) {
                    return decodeURIComponent(queryValue);
                }
            }
        } while (match !=  null);

        return null;
    }
}
