import { ft } from '@js/definitions';
import { IAttributes, IAugmentedJQuery, IScope } from 'angular';

ft.app.directive('fileModel', [function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function ($scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, ngModel) {
            element.bind('change', function (event) {
                ngModel.$setViewValue((event.target as HTMLInputElement).files[0]);
                $scope.$apply();
            });

            $scope.$watch(function () {
                return ngModel.$viewValue;
            }, function (value) {
                if (!value) {
                    element.val('');
                }
            });
        }
    };
}]);
