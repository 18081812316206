import { NetworkUtils } from '@js/network-utils';

ft.app = ng.module('parentportal', [
        'ui.router', 'ui.bootstrap', 'ngAnimate', 'ngStorage', 'ngCookies', 'ngSanitize', 'pascalprecht.translate',
        'ng-mfb', 'perfect_scrollbar', 'checklist-model', 'luegg.directives', 'ngBootstrapLightbox'
    ]);

ft.app.cssFile = 'main.css';
ft.app.baseUrl = '';
ft.app.baseTemplateUrl = '/partials/';
ft.app.baseApiUrl = ft.app.baseUrl + 'api/';
ft.app.mainBackgroundImageUrl = '';

ft.app.secondaryApiUrl = '';

ft.app.datetime = {
    zone: 'Europe/Amsterdam',
    offset: moment().isDST() ? '+02:00' : '+01:00',
    locale: 'nl',
    formats: {
        default: 'dd. DD MMM',
        toAPIDate: 'YYYY-MM-DD',
        toAPITime: 'HH:mm'
    }
};

ft.app.gender = {
    male: 'male',
    female: 'female'
};

ft.app.family = {
    roles: {
        'responsible': 'responsible',
        'parent': 'parent',
        'fetcher': 'fetcher'
    },
    types: {
        child: 'child',
        member: 'parent'
    },
    images: {
        girl: '/assets/girl.png',
        boy: '/assets/boy.png',
        woman: '/assets/woman.png',
        men: '/assets/man.png'
    }

};

ft.app.planning = {
    days: {
        normal: 'normal',
        closed: 'closed',
        holiday: 'holiday'
    },
    states: {
        approved: 'approved',
        requested: 'requested',
        requestable: 'requestable',
        rejected: 'rejected'
    },
    types: {
        planning: 'chiPlanning',
        absent: 'chiAfwezig',
        vacation: 'chiVakantieperiode',
        study: 'chiStudiedag',
        incidental: 'chiIncidenteleOpvang',
        swap: 'chiRuildag',
        registration: 'chiRegistration'
    },
    presence: {
        absent: 'absent',
        present: 'present',
        sick: 'sick',
        holiday: 'holiday',
        other: 'other',
        traded: 'traded'
    },
    registrations:
    {
        unknown: 'unknown',
        incidental: 'incidental',
        swap: 'swapday',
        studyday: 'studyday',
        beforecontract: 'beforeContract'
    }
};

ft.app.messages = {
    directions: {
        in: 'in',
        out: 'out'
    }
};

ft.app.contactInformation = {
    types: {
        phone: 'telephone',
        mobile: 'mobile',
        email: 'email'
    },
    categories: {
        home: 'home',
        work: 'work',
        invoice: 'invoice'
    }
};

ft.app.activities = {
    keys: {
        activity: 'activity',
        video: 'film',
        report: 'report',
        photoalbum: 'photoalbum',
        own: 'own-cat',
        parents: 'parents'
    },
    types: {
        puzzle: 'puzzle',
        music: 'music',
        gaming: 'gaming',
        cooking: 'cooking',
        yoga: 'yoga'
    },
    images: {
        puzzle: 'https://tt3securecontent.tik-tik.nl/go/ci_cms/uploads/icons/puzzel_invert.png',
        music: 'https://tt3securecontent.tik-tik.nl/go/ci_cms/uploads/icons/muziek_invert.png',
        gaming: 'https://tt3securecontent.tik-tik.nl/go/ci_cms/uploads/icons/gamen_invert.png',
        cooking: 'https://tt3securecontent.tik-tik.nl/go/ci_cms/uploads/icons/koken_invert.png',
        yoga: 'https://tt3securecontent.tik-tik.nl/go/ci_cms/uploads/icons/yoga_invert.png'
    }
};

ft.app.media = {
    types: {
        image: 'image',
        video: 'video'
    }
};

ft.app.document = {
    types: {
        annualStatement: 'annualStatement',
        invoice: 'invoice',
        general: 'document',
        contract: 'contract'
    }
};

ft.app.contract = {
    states: {
        active: 'active',
        offered: 'offered',
        sign: 'sign',
        approved: 'approved',
        received: 'received',
        rejected: 'rejected',
        cancelled: 'cancelled',
        ended: 'ended'
    },
    packages: {
        stripCard: 'strippenkaart'
    }
};

ft.app.care = {
    types: {
        daycare: 'daycare',
        outOfSchool: 'outOfSchoolCare',
        playSchool: 'playSchool',
        holiday: 'holidayCare'
    }
};

ft.app.holidayBalance = {
    type: {
        requestNew: 'requestNew',
        incidental: 'incidental'
    }
};

ft.app.settings = {
    keys: {
        language: 'language'
    }
};

ft.app.events = {
    requests: {
        add: 'add',
        remove: 'remove',
        active: 'active',
        none: 'none'
    },
    login: {
        success: 'login.success'
    },
    family: {
        get: 'family.get'
    },
    app: {
        config: 'config'
    }
};

ft.app.exceptions = {
    types: {
        invalidOperationException: 'System.InvalidOperationException',
        providerException: 'System.Configuration.Provider.ProviderException',
        unauthorizedAccessException: 'System.UnauthorizedAccessException'
    }
};

/*
 * Purpose: encapsulates the console methods and outputs only if DEBUG is true
 * Usage:
 *
 * ft.app.log([string], object|boolean|string|number|array|etc);
 * ft.app.log('info', [string], object|boolean|string|number|array|etc);
 * ft.app.log('warn', [string], object|boolean|string|number|array|etc);
 * ft.app.log('error', [string], object|boolean|string|number|array|etc);
 *
 */
ft.app.log = function () {

    if (!FortyTwo.DEBUG || !arguments.length) {
        return;
    }

    var fnc = 'log';
    var type = arguments[0];

    if (typeof (type) == 'string' && type.search(/^info|warn|error$/i) >= 0) {
        [].shift.apply(arguments);
        fnc = type;
    }

    console[fnc].apply(console, arguments);

};

ft.app.getTemplateUrl = function (urlPart) {
    return ft.app.baseTemplateUrl + urlPart + '.html?v=' + ft.randomNumber;
};

ft.app.config(['$compileProvider', '$stateProvider', 'uibDatepickerConfig', '$sceDelegateProvider', '$translateProvider',
    function ($compileProvider, $stateProvider, uibDatepickerConfig, $sceDelegateProvider, $translateProvider) {

        uibDatepickerConfig.startingDay = 1;

        if (!ft.DEBUG) {
            $compileProvider.debugInfoEnabled(false);
        }

        const tenant = NetworkUtils.getTenantFromUrl(window.location.host);

        if (tenant != null) {
            ft.app.baseUrl = `https://backoffice-${tenant}.kindplanner.nl/`;
            ft.app.baseApiUrl = ft.app.baseUrl + 'api/';
        } else {
            let storedUrl = localStorage.getItem('ngStorage-apiUrl');
            if (storedUrl) {
                ft.app.baseApiUrl = JSON.parse(storedUrl);
            }
        }

        // api url
        const apiParam = NetworkUtils.getQueryParam('api');
        if (apiParam != null) {
            ft.app.baseApiUrl = apiParam;
            localStorage.setItem('ngStorage-apiUrl', JSON.stringify(ft.app.baseApiUrl));
        }

        ft.app.mainBackgroundImageUrl = ft.app.baseUrl + 'images/ChildCare_background.jpg';

        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from our assets domain.  Notice the difference between * and **.
            'http://*.local/**',
            'https://*.local/**',
            'http://*.42windmills.com/**',
            'https://*.scoolsuite.com/**'
        ]);

        $translateProvider.useSanitizeValueStrategy('sanitize');

        $translateProvider
            .useStaticFilesLoader({
                prefix: '/translations/local-',
                suffix: '.json'
            })
            .preferredLanguage('nl');

        var appContent = {
            name: 'app',
            url: '/app',
            abstract: true,
            views: {
                'main': {
                    templateUrl: ft.app.getTemplateUrl('app/content'),
                    controller: 'appContentController'
                }
            }
        };

        //#region non app content

        //#region login

        var login = {
            name: 'login',
            url: '/login?lang',
            pageTracker: 'login',
            views: {
                'main': {
                    templateUrl: ft.app.getTemplateUrl('app/login'),
                    controller: 'loginController'
                }
            }
        };

        //#endregion

        //#region registration

        var registration = {
            name: 'registration',
            url: '/registration',
            abstract: true,
            views: {
                'main': {
                    templateUrl: ft.app.getTemplateUrl('family/registration'),
                    controller: 'dummyController'
                }
            }
        };

        var introductionRegistration = {
            name: 'registration.introduction',
            url: '/introduction?lang',
            pageTracker: 'registration/introduction',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('family/registration-introduction'),
                    controller: 'registrationIntroductionController'
                }
            },
            data: {
                newRegistration: true
            }
        };

        var newRegistration = {
            name: 'registration.new',
            url: '/new?lang',
            pageTracker: 'registration/new',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('family/add-child'),
                    controller: 'familyAddChildController'
                }
            },
            data: {
                newRegistration: true
            }
        };

        //#endregion

        //#region impersonate

        var impersonate = {
            name: 'impersonate',
            url: '/impersonate?accesskey&id',
            pageTracker: 'impersonate',
            views: {
                'main': {
                    templateUrl: ft.app.getTemplateUrl('impersonate/index'),
                    controller: 'impersonateController'
                }
            }
        };

        //#endregion

        //#region set password

        var setPassword = {
            name: 'setPassword',
            url: '/setpassword?accesskey&lang',
            pageTracker: 'setpassword',
            views: {
                'main': {
                    templateUrl: ft.app.getTemplateUrl('user/setpassword'),
                    controller: 'setPasswordController'
                }
            }
        };

        //#endregion

        //#region logout

        var logout = {
            name: 'logout',
            url: '/logout',
            pageTracker: 'logout',
            views: {
                'main': {
                    templateUrl: ft.app.getTemplateUrl('abstract'),
                    controller: 'logoutController'
                }
            }
        };

        //#endregion

        //#endregion

        //#region app

        var start = {
            name: 'app.start',
            url: '/start',
            pageTracker: 'start',
            views: {
                'content': {
                    templateUrl: ft.app.getTemplateUrl('app/dashboard'),
                    controller: 'appDashboardController'
                }
            }
        };

        var help = {
            name: 'app.help',
            url: '/help',
            pageTracker: 'help',
            views: {
                'content': {
                    templateUrl: ft.app.getTemplateUrl('app/help'),
                    controller: 'helpDashboardController'
                }
            }
        };

        var documents = {
            name: 'app.documents',
            url: '/documents',
            pageTracker: 'documents',
            views: {
                'content': {
                    templateUrl: ft.app.getTemplateUrl('documents/list'),
                    controller: 'documentsController'
                }
            }
        };

        var financial = {
            name: 'app.financial',
            url: '/financial',
            pageTracker: 'financial',
            views: {
                'content': {
                    templateUrl: ft.app.getTemplateUrl('financial/overview'),
                    controller: 'financialOverviewController'
                }
            }
        };

        var parentApp = {
            name: 'app.parentApp',
            url: '/parent-app',
            pageTracker: 'parent-app',
            views: {
                'content': {
                    templateUrl: ft.app.getTemplateUrl('app/parent-app'),
                    controller: 'loginController'
                }
            }
        };

        //#endregion

        //#region child

        // pageheader
        var childProfile = {
            name: 'app.child',
            url: '/child/{child}',
            abstract: true,
            views: {
                'content': {
                    templateUrl: ft.app.getTemplateUrl('child/profile'),
                    controller: 'childProfileController'
                }
            }
        };

        var childEdit = {
            name: 'app.child.edit',
            url: '/edit',
            pageTracker: 'child/edit',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/edit'),
                    controller: 'childEditController'
                }
            }
        };

        var childPlanningList = {
            name: 'app.child.planninglist',
            url: '/planning/list?year&month',
            pageTracker: 'child/planning/list',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('planning/list'),
                    controller: 'planningListController'
                }
            }
        };

        var childPlanningItem = {
            name: 'app.child.planningitem',
            url: '/planning/item/{item}',
            pageTracker: 'child/planning/item',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('planning/item'),
                    controller: 'planningItemController'
                }
            }
        };

        var childMessages = {
            name: 'app.child.messages',
            url: '/messages',
            pageTracker: 'child/messages',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/messages'),
                    controller: 'messagesController'
                }
            }
        };

        var childSpecificMessage = {
            name: 'app.child.messagesSpecific',
            url: '/messages/{message}',
            pageTracker: 'child/messages/message',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/messages'),
                    controller: 'messagesController'
                }
            }
        };

        var childMedialibrary = {
            name: 'app.child.medialibrary',
            url: '/medialibrary',
            pageTracker: 'child/media',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/medialibrary'),
                    controller: 'medialibraryController'
                }
            }
        };

        var childInfo = {
            name: 'app.child.info',
            url: '/info?success',
            pageTracker: 'child/info',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/info'),
                    controller: 'childInfoController'
                }
            }
        };

        var childDocuments = {
            name: 'app.child.documents',
            url: '/documents',
            pageTracker: 'child/documents',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/documents'),
                    controller: 'childDocumentsController'
                }
            }
        };

        var childContracts = {
            name: 'app.child.contracts',
            url: '/contracts',
            pageTracker: 'child/contracts',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/contracts'),
                    controller: 'childContractsController'
                }
            }
        };

        var childActivities = {
            name: 'app.child.activities',
            url: '/activities',
            pageTracker: 'child/activities',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/activities'),
                    controller: 'childActivitiesController'
                }
            }
        };

        var childUploadProfilePicture = {
            name: 'app.child.upload-profile-picture',
            url: '/uploadprofilepicture',
            pageTracker: 'child/uploadpicture',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('family/uploadprofileimage'),
                    controller: 'familyUploadProfilePictureController'
                }
            }
        };

        var childRequests = {
            name: 'app.child.requests',
            url: '/requests',
            pageTracker: 'child/requests',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('child/requests'),
                    controller: 'childRequestsController'
                }
            }
        };

        //#endregion

        //#region users

        // page header
        var userProfile = {
            name: 'app.user',
            url: '/user/{user}',
            abstract: true,
            views: {
                'content': {
                    templateUrl: ft.app.getTemplateUrl('user/profile'),
                    controller: 'userProfileController'
                }
            }
        };

        var userInfo = {
            name: 'app.user.info',
            url: '/info?success',
            pageTracker: 'user/info',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('user/info'),
                    controller: 'userInfoController'
                }
            }
        };

        var userEdit = {
            name: 'app.user.edit',
            url: '/edit',
            pageTracker: 'user/edit',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('family/add-user'),
                    controller: 'familyAddUserController'
                }
            }
        };

        var userPasswordChange = {
            name: 'app.user.passwordchange',
            url: '/passwordchange',
            pageTracker: 'user/passwordchange',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('user/passwordchange'),
                    controller: 'userPasswordchangeController'
                }
            }
        };

        var userUploadProfilePicture = {
            name: 'app.user.upload-profile-picture',
            url: '/uploadprofilepicture',
            pageTracker: 'user/uploadpicture',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('family/uploadprofileimage'),
                    controller: 'familyUploadProfilePictureController'
                }
            }
        };

        //#endregion

        //#region family

        // family is in first release an entry to or child profile or user profile and to add children or users

        var family = {
            name: 'app.family',
            url: '/family',
            abstract: true,
            views: {
                'content': {
                    templateUrl: ft.app.getTemplateUrl('family/overall'),
                    controller: 'familyController'
                }
            }
        };

        var familyList = {
            name: 'app.family.list',
            url: '/list?success',
            pageTracker: 'family/list',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('family/list'),
                    controller: 'familyListController'
                }
            }
        };

        var familyAddChild = {
            name: 'app.family.add-child',
            url: '/add/child?step',
            pageTracker: 'child/add',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('family/add-child'),
                    controller: 'familyAddChildController'
                }
            }
        };

        var familyAddUser = {
            name: 'app.family.add-user',
            url: '/add/user',
            pageTracker: 'user/add',
            views: {
                '': {
                    templateUrl: ft.app.getTemplateUrl('family/add-user'),
                    controller: 'familyAddUserController'
                }
            }
        };

        //#endregion

        //#region locations

        // NOT FOR FIRST RELEASE

        /*

        var locations = {
            name: 'locations',
            url: '/locations',
            views: {
                'main@': {
                    templateUrl: ft.app.getTemplateUrl('locations/dashboard'),
                    controller: 'locationsController'
                }
            }
        };

        */

        //#endregion

        // non app content
        $stateProvider.state(login);
        $stateProvider.state(registration);
        $stateProvider.state(introductionRegistration);
        $stateProvider.state(newRegistration);
        $stateProvider.state(impersonate);
        $stateProvider.state(setPassword);
        $stateProvider.state(parentApp);
        $stateProvider.state(logout);

        // app
        $stateProvider.state(appContent);
        $stateProvider.state(start);
        $stateProvider.state(help);
        $stateProvider.state(documents);
        $stateProvider.state(financial);

        // child
        $stateProvider.state(childProfile);
        $stateProvider.state(childPlanningList);
        $stateProvider.state(childPlanningItem);
        $stateProvider.state(childMessages);
        $stateProvider.state(childSpecificMessage);
        $stateProvider.state(childMedialibrary);
        $stateProvider.state(childInfo);
        $stateProvider.state(childDocuments);
        $stateProvider.state(childContracts);
        $stateProvider.state(childActivities);
        $stateProvider.state(childEdit);
        $stateProvider.state(childUploadProfilePicture);
        $stateProvider.state(childRequests);

        // user
        $stateProvider.state(userProfile);
        $stateProvider.state(userInfo);
        $stateProvider.state(userPasswordChange);
        $stateProvider.state(userEdit);
        $stateProvider.state(userUploadProfilePicture);

        // family
        $stateProvider.state(family);
        $stateProvider.state(familyList);
        $stateProvider.state(familyAddChild);
        $stateProvider.state(familyAddUser);

    }]); // app config
