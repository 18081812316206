ft.app.controller('planningItemEmployeesController',
    ['$scope', '$element', 'close', 'item', '$timeout',
        function ($scope, $element, close, item, $timeout) {

            var initialized = false;

            $scope.data = {
                item: item,
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $scope.close = function () {
                close();
            };

            $timeout($scope.initialize);

        }
    ]);