ft.app.controller('contractCancelModalController',
    ['$scope', '$element', 'close', 'item', '$timeout', '$translate', 'DataService', 'AppService',
        function ($scope, $element, close, item, $timeout, $translate, DataService, AppService) {

            var initialized = false;

            $scope.data = {
                item: item,
                cancellation: false,
                comments: '',
                saving: false,
                saveEnabled: true,
                attempts: 0,
                successMessage: '',
                errorMessage: ''
            };

            $scope.initialize = function () {

                var data = $scope.data;

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $scope.save = function () {
                var data = $scope.data;
                data.successMessage = '';
                data.errorMessage = '';

                if (!data.cancellation) {
                    return;
                }

                data.saving = true;
                var prm = DataService.cancelContractAsync(item.child, item.contract.id, data.comments);
                prm.then($scope.handleSave, $scope.handleSaveError);
                prm.finally(function () {
                    data.saving = false;
                });

            };

            $scope.handleSave = function (results) {
                $scope.data.saveEnabled = false;
                $scope.data.attempts = 0;
                $scope.data.successMessage = $translate.instant('CONTRACTS-CANCEL.CANCELLATION-SAVED');
            };

            $scope.handleSaveError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('CONTRACTS-CANCEL.ERROR-CONTINUES');
                } else {
                    $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('CONTRACTS-CANCEL.ERROR-SAVE-CANCELLATION');
                }
            };

            $scope.cancel = function () {
                close();
            };

            $scope.close = function () {
                close({
                    success: !$scope.data.saveEnabled
                });
            };

            $timeout($scope.initialize);

        }
    ]);
