ft.app.controller('planningChoiceController',
    ['$scope', '$element', 'close', 'child', 'item', '$timeout', 'AppService', 'PlanningModalService',
        function ($scope, $element, close, child, item, $timeout, AppService, PlanningModalService) {

            var initialized = false;

            $scope.data = {
                item: item,
                child: null,
                hasActions: false
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                $scope.data.child = AppService.getUser(child);
                $scope.determineHasActions();

                initialized = true;

            };

            $scope.hasBlock = function () {
                return $scope.data.item.day.blocks.length > 0;
            };

            $scope.maySwapInThePast = function () {
                return AppService.data.allowSwapdaysFromPast;
            };

            $scope.isClosedDay = function () {
                return $scope.data.item.day.type == ft.app.planning.days.closed;
            };

            $scope.isSick = function () {

                // check if child is currently sick
                if (new Date($scope.data.item.day.date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0) && $scope.data.child.isSick) {
                    return true;
                }

                var blocks = $scope.data.item.day.blocks;
                var result = false;

                if (blocks) {
                    blocks.forEach(function (item, idx) {

                        if (item.presence == ft.app.planning.presence.sick) {
                            result = true;
                        }

                    });
                }

                return result;
            };

            $scope.isInThePast = function () {
                return new Date(item.day.date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
            };

            $scope.isToday = function () {
                return new Date(item.day.date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0);
            };

            $scope.determineHasActions = function () {

                var actions = ['absentFrom', 'absentTill', 'present', 'swapFrom', 'swapTill', 'incidental'];
                $scope.data.hasActions = false;

                var possible = actions.filter(function (action) {
                    return $scope.mayShowActionButton(action, true);
                });

                $scope.data.hasActions = possible && possible.length > 0;

            };

            $scope.hasAbsenceBlock = function (blocks) {

                if (!$scope.hasBlock()) {
                    return false;
                }

                var config = ft.app.planning;

                var results = blocks.filter(function (item) {

                    if ((item.status == config.states.approved) &&
                        (item.type == config.types.planning || item.type == config.types.incidental || item.type == config.types.swap) &&
                        (item.presence == config.presence.sick || item.presence == config.presence.holiday || item.presence == config.presence.absent || item.presence == config.presence.other)) {
                        return true;
                    }

                    return false;

                });

                return results && results.length;

            };

            $scope.mayShowActionButton = function (key, bypass) {

                var result = false;
                var day = $scope.data.item.day;

                if (!$scope.data.hasActions && !bypass) {
                    return false;
                }

                if (day.type == ft.app.planning.days.closed) {
                    return false;
                }

                if ($scope.isInThePast()) {
                    switch (key) {
                        case 'swapFrom':
                            result = $scope.maySwapInThePast() && ($scope.isClosedDay() || $scope.hasAbsenceBlock(day.blocks));
                            break;
                    }
                } else {
                    switch (key) {
                        case 'absentFrom':
                            result = true;
                            break;
                        case 'absentTill':
                            result = !$scope.isToday();
                            break;
                        case 'sick': // ziek melden
                            result = !$scope.isSick();
                            break;
                        case 'present': // beter melden
                            result = $scope.isSick();
                            break;
                        case 'swapFrom':
                            result = $scope.hasBlock();
                            break;
                        case 'swapTill':
                            result = !$scope.hasBlock();
                            break;
                        case 'incidental':
                            result = true;
                            break;
                    }
                }

                return result;
            };

            $scope.handleActionButton = function (evt, key) {

                evt.stopPropagation();
                evt.preventDefault();

                switch (key) {
                    case 'absentFrom':
                    case 'absentTill':
                        $scope.openAbsentModal(evt, (key == 'absentFrom'));
                        break;
                    case 'sick':
                        $scope.openSickModal(evt);
                        break;
                    case 'present':
                        $scope.openPresentModal(evt);
                        break;
                    case 'swapFrom':
                    case 'swapTill':
                        $scope.openSwapModal(evt, (key == 'swapFrom'));
                        break;
                    case 'incidental':
                        $scope.openIncidental(evt);
                        break;
                }
            };

            $scope.openAbsentModal = function (evt, from) {
                var item = $scope.data.item;
                var fromDate = from ? item.day.date : null;
                var tillDate = !from ? item.day.date : null;

                PlanningModalService.openAbsentModal(evt, child, fromDate, tillDate)
                    .then(function (modal) {
                    });
            };

            $scope.openSickModal = function (evt) {
                PlanningModalService.openAbsentModal(evt, child, $scope.data.item.day.date, null, null, null, ft.app.planning.presence.sick)
                    .then(function (modal) {
                    });
            };

            $scope.openPresentModal = function (evt) {
                PlanningModalService.openPresentModal(evt, child, $scope.data.item.day.date)
                    .then(function (modal) {
                    });
            };

            $scope.openSwapModal = function (evt, from) {
                var item = $scope.data.item;
                var fromDate = from ? item.day.date : null;
                var tillDate = !from ? item.day.date : null;

                PlanningModalService.openSwapModal(evt, child, fromDate, tillDate)
                    .then(function (modal) {
                    });
            };

            $scope.openIncidental = function (evt) {
                var from = $scope.data.item.day.date;

                PlanningModalService.openIncidentalModal(evt, child, from)
                    .then(function (modal) {
                    });
            };

            $scope.close = function () {
                close({});
            };

            $timeout($scope.initialize);

        }
    ]);