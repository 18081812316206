ft.app.controller('childProfileController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', 'DataService', 'AppService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, DataService, AppService) {

            var initialized = false;

            $scope.appData = AppService.data;
            $scope.data = {
                child: null
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

                AppService.setCurrentChild($stateParams.child);
                $rootScope.$on(ft.app.events.family.get, $scope.initializeData);

                $scope.initializeData();

            };

            $scope.initializeData = function () {
                $scope.data.child = AppService.getUser($stateParams.child);
            };

            $scope.navigateToInfo = function () {
                $state.go('app.child.info', {child: $scope.data.child.id});
            };

            $timeout($scope.initialize);

        }
    ]);

ft.app.controller('childInfoController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', 'DataService', 'AppService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, DataService, AppService) {

            var initialized = false;
            $rootScope.checkForFormChange = true;

            if (!$scope.data) {
                $scope.data = {};
            }

            $scope.data.appData = AppService.data;
            $scope.data.success = $stateParams.success;

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                if ($stateParams.success == true) {
                    AppService.getFamilyAsync();
                }

                initialized = true;

            };

            $scope.isBirthday = function () {

                if (!$scope.data.child) {
                    return false;
                }

                var birthday = new Date($scope.data.child.dateOfBirth);
                var today = new Date();

                return birthday.getDate() == today.getDate() && birthday.getMonth() == today.getMonth();

            };

            $timeout($scope.initialize);

        }
    ]);

ft.app.controller('childContractsController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$filter', '$translate', 'DataService', 'AppService', 'ModalService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $filter, $translate, DataService, AppService, ModalService) {

            var groupByFilter = $filter('GroupByFilter');
            var initialized = false;

            if (!$scope.data) {
                $scope.data = {};
            }

            $scope.isLoading = false;
            $scope.data.errorMessage = '';

            $scope.data.items = [];
            $scope.data.contractConfig = ft.app.contract;

            $scope.data.requestInformation = [];
            $scope.data.requestInformation.preferredLocations = [];
            $scope.data.requestInformation.requestedTimeslots = {};

            $scope.data.hasItems = true;

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

                getContracts();

            };

            $scope.openNewContract = function (evt) {
                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    style: 'contract-modal-settings',
                    templateUrl: 'contracts/edit-new-modal',
                    controller: 'contractEditModalController',
                    inputs: {
                        item: {
                            child: $scope.data.child.id
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function (result) {

                        getContracts();

                    }, function () {
                    });

                });
            };
     
            $scope.openContract = function (evt, contract) {
                if (contract.state == 'received' || contract.contractants == 0) {
                    getRequestInformation(contract, () => {
                        openContractShowModal(evt, contract);
                    });
                }
                else
                {
                    openContractShowModal(evt, contract);
                }
            };

            function openContractShowModal(evt, contract)
            {
                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    style: 'contract-modal-settings',
                    templateUrl: 'contracts/item',
                    controller: 'contractModalController',
                    inputs: {
                        item: {
                            child: $scope.data.child.id,
                            contract: contract,
                            preferredLocations: $scope.data.requestInformation.preferredLocations,
                            requestedTimeslots: $scope.data.requestInformation.requestedTimeslots,
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function (result) {

                        getContracts();

                    }, function () {
                    });

                });
            }

            var getContracts = function () {

                $scope.data.errorMessage = '';
                $scope.isLoading = true;

                var prm = DataService.getContractsAsync($stateParams.child);
                prm.then(handleGetContracts, handleGetContractsError);
                prm.finally(function () {
                    $scope.isLoading = false;
                });

            };

            var handleGetContracts = function (results) {

                if (results && results.length) {
                    $scope.data.hasItems = true;
                    // old > new
                    results.sort(function (a, b) {

                        var aStartDate = new Date(a.start);
                        var bStartDate = new Date(b.start);
                        var aEndDate = new Date(a.end);
                        var bEndDate = new Date(b.end);

                        if (aStartDate < bStartDate) {
                            return -1;
                        }
                        if (aStartDate > bStartDate) {
                            return 1;
                        }
                        if (aEndDate < bEndDate) {
                            return -1;
                        }
                        if (aEndDate > bEndDate) {
                            return 1;
                        }

                        return 0;

                    });
                    results.reverse(); // new > old

                    $scope.data.items = results;
                } else {
                    $scope.data.hasItems = false;
                }
            };

            var handleGetContractsError = function (response) {

                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('CONTRACTS.ERROR-GET-CONTRACTS');

            };

            var getRequestInformation = function (contract, callback) {
                $scope.data.errorMessage = '';
                $scope.isLoading = true;
                var prm = DataService.getRequestInformationAsync($stateParams.child, contract.aanvraagId);
                prm.then(handleGetRequestInformation, handleGetRequestInformationError);
                prm.finally(function () {
                    if(typeof callback === 'function') {
                        callback();
                    }
                    $scope.isLoading = false;
                });
            };
            
            var handleGetRequestInformation = function (result) {
                if (result && Object.keys(result).length != 0) { 
                    $scope.data.requestInformation.preferredLocations = result.preferredLocations;
                    $scope.data.requestInformation.requestedTimeslots = result.requestedTimeslots;
                    $scope.data.hasItems = true;
                } else {
                    $scope.data.hasItems = false;
                }
            };

            var handleGetRequestInformationError = function (response) {
                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('CONTRACTS.ERROR-GET-CONTRACT-REQUEST');
            };
            
            $timeout($scope.initialize);
        }
    ]);

ft.app.controller('childEditController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$filter', '$translate', 'DataService', 'AppService', 'CheckStateChangeService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $filter, $translate, DataService, AppService, CheckStateChangeService) {

            var initialized = false;
            var getPhoneNumber = $filter('GetPhoneNumber');

            CheckStateChangeService.checkFormOnStateChange($scope, 'childForm');

            if (!$scope.data) {
                $scope.data = {};
            }

            ng.extend($scope.data, {
                appData: AppService.data,
                name: {},
                ssn: '',
                dateOfBirth: '',
                placeOfBirth: '',
                gender: '',
                emergencyNumber: '',
                doctorName: '',
                doctorPhone: '',
                insuranceOrganizationName: '',
                insuranceOrganizationNumber: '',
                school: '',
                schools: AppService.data.schools,
                dateOfBirthOpened: false,
                dateOptions: {
                    formatYear: 'yy'
                },
                formats: ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
                format: 'dd-MM-yyyy',
                saving: false,
                errorMessage: '',
                successMessage: '',
                lastNameValid: true,
                firstNameValid: true,
                dateOfBirthValid: true,
                genderValid: true,
                ssnValid: true,
                attempts: 0
            });

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                $rootScope.$on(ft.app.events.app.config, function () {
                    $scope.data.schools = AppService.data.schools;
                });

                $rootScope.$on(ft.app.events.family.get, function () {
                    $scope.initializeData();
                });

                $scope.initializeData();

                initialized = true;

            };

            $scope.initializeData = function () {

                if ($scope.data.child) {
                    var data = $scope.data;
                    var child = data.child;

                    data.name = child.name;
                    data.ssn = child.ssn;
                    data.dateOfBirth = new Date(child.dateOfBirth);
                    data.placeOfBirth = child.placeOfBirth;
                    data.gender = child.gender;
                    data.remarks = child.remarks;
                    
                    var emergencyNumber = getPhoneNumber(child.contactInformation, null, true);
                    data.emergencyNumber = emergencyNumber ? emergencyNumber.content : '';

                    if (child.doctor) {
                        var doctorPhone = getPhoneNumber(child.doctor.contactInformation);
                        data.doctorName = child.doctor.name;
                        data.doctorPhone = doctorPhone ? doctorPhone.content : '';
                    }

                    if (child.insurance) {
                        data.insuranceOrganizationName = child.insurance.organization;
                        data.insuranceOrganizationNumber = child.insurance.policyNumber;
                    }

                    if (child.school) {
                        data.school = child.school.id;
                    }
                }
            };

            $scope.isDateOfBirthInFuture = function () {
                var data = $scope.data;

                if (data.dateOfBirth) {
                    return ft.dates.isDateInFuture(data.dateOfBirth);
                } else {
                    return false;
                }
            };

            $scope.save = function () {

                var data = $scope.data;

                data.errorMessage = '';
                data.successMessage = '';

                data.lastNameValid = true;
                data.firstNameValid = true;
                data.dateOfBirthValid = true;
                data.genderValid = true;
                data.ssnValid = true;

                if (!data.name.lastName.trim()) {
                    data.lastNameValid = false;
                }

                if (!data.name.firstName.trim()) {
                    data.firstNameValid = false;
                }

                if (!data.dateOfBirth) {
                    data.dateOfBirthValid = false;
                } else {
                    if (!moment(data.dateOfBirth).isValid()) {
                        data.dateOfBirthValid = false;
                    }
                }

                if (!data.gender) {
                    data.genderValid = false;
                }

                // Disabled for now as it breaks the form when SSN number is not filled in, backend needs a change
                // to get this properly working again

                // if (data.appData.ssnIsRequiredAndShown && data.dateOfBirth && !$scope.isDateOfBirthInFuture() && !data.ssn.trim())
                // 	data.ssnValid = false;

                if (!data.lastNameValid || !data.firstNameValid || !data.dateOfBirthValid || !data.genderValid || !data.ssnValid) {
                    return;
                }

                var obj = {
                    id: data.child.id,
                    name: data.name,
                    type: ft.app.family.types.child,
                    isContact: false,
                    dateOfBirth: moment(data.dateOfBirth).format(ft.app.datetime.formats.toAPIDate),
                    placeOfBirth: data.placeOfBirth,
                    gender: data.gender,
                    ssn: data.appData.ssnIsRequiredAndShown ? data.ssn : null,
                    doctor: {
                        name: data.doctorName,
                        //addresses: [],
                        contactInformation: [
                            {
                                type: ft.app.contactInformation.types.phone,
                                content: data.doctorPhone
                            }
                        ]
                    },
                    remarks: data.remarks,
                    insurance: {
                        organization: data.insuranceOrganizationName,
                        policyNumber: data.insuranceOrganizationNumber
                    },
                    //school: {
                    //    id: data.school
                    //},
                    contactInformation: [
                        {
                            type: ft.app.contactInformation.types.phone,
                            content: data.emergencyNumber,
                            isEmergency: true
                        }
                    ]
                };

                $scope.data.saving = true;
                var prm = DataService.saveChildAsync(obj);
                prm.then($scope.handleSave, $scope.handleSaveError);
                prm.finally(function () {
                    $scope.data.saving = false;
                });
            };

            $scope.handleSave = function (result) {
                $scope.data.attempts = 0;

                $rootScope.checkForFormChange = false;

                AppService.getFamilyAsync().then(function () {
                    $state.go('app.child.info', {id: $scope.data.child.id, success: true});
                }, function () {
                });
            };

            $scope.handleSaveError = function (response) {

                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('CHILD-EDIT.ERROR-CONTINUES');
                } else {
                    $scope.data.errorMessage = $translate.instant('CHILD-EDIT.ERROR-SAVE-CHILD');
                }

                if (response && !response.result && response.isReadable) {
                    $scope.data.errorMessage += ' ' + response.message;
                }

            };

            $scope.cancel = function () {
                $state.go('app.child.info', {child: $stateParams.child});
            };

            $scope.open = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.data.dateOfBirthOpened = true;
            };

            $timeout($scope.initialize);

        }
    ]);

ft.app.controller('childWidgetController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', 'ModalService', 'PlanningModalService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, ModalService, PlanningModalService) {

            var initialized = false;

            //#region init

            var initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;
            };

            //#endregion

            //#region screen handlers

            $scope.navigate = function (state) {

                $state.go('app.child.' + state, {child: $scope.child.id});

            };

            $scope.handleActionButton = function (evt, id, key) {

                evt.stopPropagation();
                evt.preventDefault();

                switch (key) {
                    case 'absent':
                        $scope.openAbsentModal(evt, id);
                        break;
                    case 'sick':
                        $scope.openSickModal(evt, id);
                        break;
                    case 'present':
                        $scope.openPresentModal(evt, id);
                        break;
                }
            };

            $scope.openAbsentModal = function (evt, id) {
                PlanningModalService.openAbsentModal(evt, id)
                    .then(function (modal) {
                    });
            };

            $scope.openSickModal = function (evt, id) {
                PlanningModalService.openAbsentModal(evt, id, null, null, null, null, ft.app.planning.presence.sick)
                    .then(function (modal) {
                    });
            };

            $scope.openPresentModal = function (evt, id) {
                PlanningModalService.openPresentModal(evt, id)
                    .then(function (modal) {
                    });
            };

            //#endregion

            //#region data

            //#endregion

            //#region handlers

            //#endregion

            $timeout(initialize);

        }
    ]);
