import { ChildCare } from '@js/data-models';
import { ft } from '@js/definitions';
import { FortyTwoLoginService } from '@js/FortyTwoFramework';
import { AppService } from '@js/services/FT.app.services.appservice';
import { DataService } from '@js/services/FT.app.services.dataservice';
import { IScope } from 'angular';
import { IStateParamsService, IStateService } from 'angular-ui-router';


interface Scope extends IScope {
    switchLanguage(lang: string): void;
    gotoRegistration(): void;
    sendRegistration(): void;
    send(): void;

    isLoading: boolean;
    currentYear: number;
    appData: any;
    whitelabelApp: boolean;
    data: {
        mode: 'login' | 'remember';
        username: string;
        usernameRegistration: string;
        validUsername: boolean;
        validUsernameRegistration: boolean;
        password: string;
        validPassword: boolean;
        stayLoggedIn: boolean;
        errorMessage: string;
        registrationMessage: string;
        form: 'forgot-password' | undefined;
    };
}

class LoginController {
    public static $inject = ['$scope', '$state', '$stateParams', '$translate', 'AppService', 'DataService', 'LoginService'];

    private $scope: Scope;
    private $state: IStateService;
    private $translate: any;
    private appService: AppService;
    private dataService: DataService;
    private loginService: FortyTwoLoginService;

    constructor($scope: Scope, $state: IStateService, $stateParams: IStateParamsService, $translate: any, appService: AppService, dataService: DataService, loginService: FortyTwoLoginService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.appService = appService;
        this.dataService = dataService;
        this.loginService = loginService;

        $scope.isLoading = false;
        $scope.currentYear = new Date().getFullYear();
        $scope.appData = appService.data;

        $scope.data = {
            mode: 'login',
            username: loginService.getStoredUsername() || '',
            usernameRegistration: '',
            validUsername: true,
            validUsernameRegistration: true,
            password: '',
            validPassword: true,
            stayLoggedIn: false,
            errorMessage: '',
            registrationMessage: '',
            form: undefined
        };

        dataService.getFeatures().then(x => {
            $scope.whitelabelApp = x.features.some(y => y.code === 'whitelabel-parentapp');
        });


        if ($stateParams.lang) {
            appService.switchLanguage($stateParams.lang);
        }

        this.$scope.send = this.send.bind(this);
        this.$scope.switchLanguage = this.switchLanguage.bind(this);
        this.$scope.gotoRegistration = this.gotoRegistration.bind(this);
        this.$scope.sendRegistration = this.sendRegistration.bind(this);
    }

    private switchLanguage(lang: string): void {
        this.appService.switchLanguage(lang);
    }

    private gotoRegistration(): void {
        this.$state.go('registration.introduction');
    }

    private send(): void {
        this.$scope.data.errorMessage = '';
        this.$scope.data.validUsername = true;
        this.$scope.data.validPassword = true;

        var un = this.$scope.data.username.toString().trim();
        var pw = this.$scope.data.password.toString().trim();

        if (!un) {
            this.$scope.data.validUsername = (un != '');
        }

        if (!pw && this.$scope.data.mode == 'login') {
            this.$scope.data.validPassword = (pw != '');
        }

        if (!this.$scope.data.validUsername || (!this.$scope.data.validPassword && this.$scope.data.mode == 'login')) {
            return;
        }

        if (this.$scope.data.mode == 'login') {
            this.login();
        } else if (this.$scope.data.mode == 'remember') {
            this.remember();
        }
    }

    private async login(): Promise<void> {

        this.$scope.isLoading = true;

        try {
            const result = await this.loginService.doLoginAsync(this.$scope.data.username, this.$scope.data.password, this.$scope.data.stayLoggedIn);
            if (result) {
                this.$state.go('app.start');
            } else {
                this.$scope.data.errorMessage = this.$translate.instant('LOGIN.ERROR-COMBINATION');
            }
        } catch (error) {
            this.$scope.data.errorMessage = error && !error.result && error.isReadable ? error.message : this.$translate.instant('LOGIN.NOT-POSSIBLE');
        } finally {
            this.$scope.isLoading = false;
            this.$scope.$apply();
        }
    }

    private async remember(): Promise<void> {
        this.$scope.isLoading = true;

        try {
            await this.dataService.forgotPasswordAsync(this.$scope.data.username);
            this.$scope.data.errorMessage = this.$translate.instant('LOGIN.MAIL-SEND-WITH-INSTRUCTIONS');
        } catch (error) {
            this.$scope.data.form = 'forgot-password';
            this.$scope.data.registrationMessage = error && !error.result && error.isReadable ? error.message : this.$translate.instant('LOGIN.ERROR-HAVE-CORRECT-MAIL');

        } finally {
            this.$scope.isLoading = false;
            this.$scope.$apply();
        }
    }

    private async sendRegistration(): Promise<void> {
        this.$scope.data.registrationMessage = '';
        this.$scope.data.validUsernameRegistration = (this.$scope.data.usernameRegistration.toString().trim() != '');

        if (!this.$scope.data.validUsernameRegistration) {
            return;
        }

        this.$scope.isLoading = true;

        try {
            await this.dataService.registerEmailCheckAsync(this.$scope.data.username);
            this.$scope.data.registrationMessage = this.$translate.instant('LOGIN.MAIL-SEND-WITH-INSTRUCTIONS');
        } catch (error) {
            this.$scope.data.errorMessage = error && !error.result && error.isReadable ? error.message : this.$translate.instant('LOGIN.ERROR-HAVE-CORRECT-MAIL');
        } finally {
            this.$scope.isLoading = false;
            this.$scope.$apply();
        }
    }
}

ft.app.controller('loginController', LoginController);