ft.app.directive('ftScrollTo', function () {
    return {
        link: function (scope, elm, attrs) {

            var offDestroy;

            var fncScrollTo = function () {

                var target = attrs.ftScrollTo;
                var top = $('#' + target).offset().top - 50;

                $('body').animate({
                    scrollTop: top
                });
            };

            var handleDestroy = function () {
                elm.off('click');
                if (offDestroy) {
                    offDestroy();
                }
            };

            elm.on('click', fncScrollTo);
            offDestroy = scope.$on('$destroy', handleDestroy);

        }
    };
});

/*
 * ftStickyScroll
 * Purpose: push elm to sticky scroll plugin
 * 
 */
ft.app.directive('ftStickyScroll', function () {
    return {
        link: function (scope, elm, attrs) {

            var cssName = 'sticked';
            var evtName = 'scroll';
            var $content = $('#content');
            var $elm = $(elm);
            var offset = $elm.offset().top - $content.offset().top;
            var $pageButton;
            var pgRight = 0;

            $content.on(evtName, function () {

                var scrollTop = $content.scrollTop();

                if (!$pageButton) {
                    var btn = $('.page-button'); // should be rendered now by other directive

                    if (btn.length == 1) {
                        $pageButton = btn;
                        pgRight = $(window).width() - ($pageButton.offset().left + $pageButton.width());
                        //pgRight = $pageButton.offset().right - $content.offset().right - parseInt($pageButton.css('right'));
                    }
                }

                if (scrollTop >= offset) {
                    $content.addClass(cssName);
                    $elm.css('top', scrollTop);
                    if ($pageButton) {
                        $pageButton.css('top', scrollTop - 5);
                    }
                    if ($pageButton) {
                        $pageButton.css('right', pgRight - 42);
                    }
                } else {
                    if ($pageButton) {
                        $pageButton.css('top', '');
                    }
                    if ($pageButton) {
                        $pageButton.css('right', '');
                    }
                    $elm.css('top', '');
                    $content.removeClass(cssName);
                }

            });

            scope.$on('$destroy', function () {

                $content.scrollTop(0);
                $content.removeClass(cssName);
                $content.off(evtName);

            });


            //$(elm).scrollToFixed();

            return;
            $(elm).sticky({
                topSpacing: '0',
                className: 'sticked'
            });
        }
    };
});

