ft.app.controller('contractModalController',
    ['$scope', '$element', 'close', 'item', '$timeout', 'LoginService', 'ModalService', 'AppService',
        function ($scope, $element, close, item, $timeout, LoginService, ModalService, AppService) {

            var initialized = false;
            var hasSuccess = false;

            $scope.data = {
                errorMessage: '',
                item: item,
                hasActions: false,
                states: ft.app.contract.states,
                isApprovedContractEditable: AppService.data.settings.isApprovedContractEditable
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                $scope.determineHasActions();

                initialized = true;

            };

            $scope.isInFuture = function (date) {

                var objDate = new Date(date).setHours(0, 0, 0, 0);
                var objNow = new Date().setHours(0, 0, 0, 0);

                return objDate > objNow;

            };

            $scope.determineHasActions = function () {

                var actions = ['activate', 'edit', 'cancel', 'reject', 'end'];
                $scope.data.hasActions = false;

                var possible = actions.filter(function (action) {
                    return $scope.mayShowActionButton(action, true);
                });

                $scope.data.hasActions = possible && possible.length > 0;
            };

            $scope.mayShowActionButton = function (key, bypass) {

                var result = false;
                var config = ft.app.contract;
                var contract = item.contract;

                if (!$scope.data.hasActions && !bypass) {
                    return false;
                }

                // if contract is ended or is a received one, freeze
                if (contract.status.type == config.states.ended || contract.status.type == config.states.received) {
                    return false;
                }

                switch (key) {
                    case 'activate':
                        result = contract.status.type == config.states.offered || contract.status.type == config.states.sign;
                        break;
                    case 'edit':
                        result = (contract.status.type == config.states.approved && $scope.isInFuture(contract.start)) || contract.status.type == config.states.active;
                        break;
                    case 'end':
                        result = (contract.status.type == config.states.approved && $scope.isInFuture(contract.start)) || contract.status.type == config.states.active;
                        break;
                    case 'cancel':
                        result = contract.status.type == config.states.offered;
                        break;
                    case 'reject':
                        result = contract.status.type == config.states.offered;
                        break;
                }

                return result;

            };

            $scope.handleActionButton = function (evt, key) {

                evt.stopPropagation();
                evt.preventDefault();

                switch (key) {
                    case 'activate':
                        $scope.openActivate(evt);
                        break;
                    case 'edit':
                        $scope.openEdit(evt);
                        break;
                    case 'end':
                        $scope.openEnd(evt);
                        break;
                    case 'cancel':
                        $scope.openCancel(evt);
                        break;
                    case 'reject':
                        $scope.openReject(evt);
                        break;
                }
            };

            $scope.openActivate = function (evt) {

                var data = $scope.data;
                hasSuccess = false;

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'contracts/activate-modal',
                    controller: 'contractActivateModalController',
                    inputs: {
                        item: {
                            child: item.child,
                            contract: item.contract
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function (result) {
                        close({success: true});
                    }, function () {
                    });

                });

            };

            $scope.openEdit = function (evt) {
                var data = $scope.data;
                hasSuccess = false;

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'contracts/edit-new-modal',
                    controller: 'contractEditModalController',
                    inputs: {
                        item: {
                            child: item.child,
                            contract: item.contract
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function (result) {
                        close({success: true});
                    }, function () {
                    });

                });
            };

            $scope.openEnd = function (evt) {

                var data = $scope.data;
                hasSuccess = false;

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'contracts/end-modal',
                    controller: 'contractEndModalController',
                    inputs: {
                        item: {
                            child: item.child,
                            contract: item.contract
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function (result) {
                        close({success: true});
                    }, function () {
                    });

                });

            };

            $scope.openCancel = function (evt) {

                var data = $scope.data;
                hasSuccess = false;

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'contracts/cancel-modal',
                    controller: 'contractCancelModalController',
                    inputs: {
                        item: {
                            child: item.child,
                            contract: item.contract
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function (result) {
                        close({success: true});
                    }, function () {
                    });

                });

            };

            $scope.openReject = function (evt) {

                var data = $scope.data;
                hasSuccess = false;

                ModalService.showModal({
                    elm: evt.currentTarget || evt.target,
                    templateUrl: 'contracts/reject-modal',
                    controller: 'contractRejectModalController',
                    inputs: {
                        item: {
                            child: item.child,
                            contract: item.contract
                        }
                    }
                }).then(function (modal) {

                    modal.close.then(function (result) {
                        close({success: true});
                    }, function () {
                    });

                });

            };

            $scope.getDownloadLink = function () {
                getDownloadLink();
            };

            $scope.getDownloadCurrentLink = function () {
                getDownloadCurrentLink();
            };

            $scope.resetDownloadLink = function (item) {
                var item = $scope.data.item;
                item.contract.loading = false;
                item.contract.downloadUrl = null;
            };

            $scope.resetDownloadCurrentLink = function (item) {
                var item = $scope.data.item;
                item.contract.loading = false;
                item.contract.downloadCurrentUrl = null;
            };

            $scope.getDownloadCostLink = function () {
                getDownloadCostLink();
            };

            $scope.resetDownloadCostLink = function (item) {
                var item = $scope.data.item;
                item.contract.loading = false;
                item.contract.downloadCostUrl = null;
            };

            var getDownloadLink = function () {
                var item = $scope.data.item.contract;
                item.loading = true;

                var prm = LoginService.refreshLoginAsync();
                prm.then(handleGetDownloadLink, handleGetDownloadLinkError);
                prm.finally(function () {
                    item.loading = false;
                });
            };

            var getDownloadCurrentLink = function () {
                var item = $scope.data.item.contract;
                item.loading = true;

                var prm = LoginService.refreshLoginAsync();
                prm.then(handleGetDownloadCurrentLink, handleGetDownloadLinkError);
                prm.finally(function () {
                    item.loading = false;
                });
            };

            var getDownloadCostLink = function () {
                var item = $scope.data.item.contract;
                item.loading = true;

                var prm = LoginService.refreshLoginAsync();
                prm.then(handleGetDownloadCostLink, handleGetDownloadLinkError);
                prm.finally(function () {
                    item.loading = false;
                });
            };

            var handleGetDownloadLink = function (results) {
                var item = $scope.data.item.contract;
                var tokenData = LoginService.getTokenData();
                item.downloadUrl = prepareItemUrl(item.url) + 'accessToken=' + encodeURIComponent(tokenData.token.value);
            };

            var handleGetDownloadCurrentLink = function (results) {
                var item = $scope.data.item.contract;
                var tokenData = LoginService.getTokenData();
                item.downloadCurrentUrl = prepareItemUrl(item.url) + 'forceGeneration=true&accessToken=' + encodeURIComponent(tokenData.token.value);
            };

            var handleGetDownloadCostLink = function (results) {
                var item = $scope.data.item.contract;
                var tokenData = LoginService.getTokenData();
                item.downloadCostUrl = prepareItemUrl(item.costOverviewUrl) + 'accessToken=' + encodeURIComponent(tokenData.token.value);
            };

            var handleGetDownloadLinkError = function (response) {
                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('DOCUMENTS.ERROR-GET-DOCUMENT-LINK');
            };

            var prepareItemUrl = function (url) {
                return url + (url.indexOf('?') >= 0 ? '&' : '?');
            };

            $scope.close = function () {
                close({
                    success: hasSuccess
                });
            };

            $timeout($scope.initialize);

        }
    ]);
