ft.app.service('ModalService',
    ['$rootScope', '$document', '$compile', '$controller', '$q', 'TemplateHandler',
        function ($rootScope, $document, $compile, $controller, $q, TemplateHandler) {

            var $body = $('body');
            var layerManager = ft.ui.managers.zIndexManager;
            var standardSizeClass = 'overlay-standard-size';
            var openClass = 'overlay-open';
            var modalPlaceholderClass = 'modal-placeholder';
            var modalClass = 'overlay';
            var shadowClass = 'shadow';
            var noTransitionClass = 'no-transition';
            var modalSizeAttr = 'modalSize';
            var query = 'screen and (max-width: 992px)';
            var queryHandler;

            function ModalService() {

                var self = this;

                self.showModal = function (options) {

                    var closed = false;
                    var $content = $('#content');

                    var modalController, modalScope, modalElement;
                    var modalElementTemplate;
                    var closeDeferred = $q.defer();

                    //  Create a deferred we'll resolve when the modal is ready.
                    var deferred = $q.defer();

                    //  Validate the input parameters.
                    var controllerName = options.controller;
                    if (!controllerName) {
                        deferred.reject('No controller has been specified.');
                        return deferred.promise;
                    }

                    var max = 1000000;
                    var id = 'modal-' + ft.getRandom(1, max) + '-' + ft.getRandom(max * 2, max * 3);

                    var $elm = $(options.elm || $body);
                    var $wrapper = $('<div class="' + modalPlaceholderClass + '"></div>');
                    var $modal = $('<div class="' + modalClass + '"></div>');
                    var $shadow = $('<div class="' + shadowClass + '"></div>');

                    $wrapper.attr('id', id);
                    $elm.attr('modal', id);

                    $wrapper.append($shadow);
                    $wrapper.append($modal);
                    //$content.append($shadow);
                    $body.append($wrapper);

                    var inputClose = function (result, delay) {
                        delay = !delay ? 0 : delay;

                        //  Resolve the 'close' promise.
                        closeDeferred.promise.then(close);
                        closeDeferred.resolve(result);

                        window.setTimeout(function () {

                            //  We can now clean up the scope and remove the element from the DOM.
                            modalScope.$destroy();
                            $wrapper.remove();

                            //  Unless we null out all of these objects we seem to suffer
                            //  from memory leaks, if anyone can explain why then I'd 
                            //  be very interested to know.            					
                            deferred = null;
                            closeDeferred = null;
                            modalElement = null;
                            modalScope = null;
                        }, 800);
                    };

                    var compile = function (results) {

                        var template = results.data ? results.data : results;

                        //  Create a new scope for the modal.
                        modalScope = $rootScope.$new();

                        //  Create the inputs object to the controller - this will include
                        //  the scope, as well as all inputs provided.
                        //  We will also create a deferred that is resolved with a provided
                        //  close function. The controller can then call 'close(result)'.
                        //  The controller can also provide a delay for closing - this is
                        //  helpful if there are closing animations which must finish first.

                        var inputs = {
                            $scope: modalScope,
                            close: function (result, delay) {
                                inputClose(result, delay);

                                inputs.close = null;
                                inputs = null;
                            }
                        };

                        //  If we have provided any inputs, pass them to the controller.
                        if (options.inputs) {
                            for (var inputName in options.inputs) {
                                inputs[inputName] = options.inputs[inputName];
                            }
                        }

                        //  Parse the modal HTML into a DOM element (in template form).
                        modalElementTemplate = ng.element(template);

                        //  Compile then link the template element, building the actual element.
                        //  Set the $element on the inputs so that it can be injected if required.
                        modalElement = $compile(modalElementTemplate)(modalScope);
                        inputs.$element = modalElement;

                        //  Create the controller, explicitly specifying the scope to use.
                        modalController = $controller(controllerName, inputs);

                        return modalElement;
                    };

                    // get properties from css rule for height, width and top
                    // makes custom sizes possible, but standard is given
                    var getSizeAndPositionFromCss = function (style) {

                        var sheets = document.styleSheets;

                        for (var prop in sheets) {

                            var sheet = sheets[prop];

                            if (sheet && sheet.href && sheet.href.indexOf(ft.app.cssFile) >= 0) {
                                return getCssRules(sheet, style);
                            }

                        }

                    };

                    var getCssRules = function (media, style) {
                        var obj;
                        var rules = media.cssRules ? media.cssRules : media.rules;

                        for (var i = 0; i < rules.length; i++) {
                            var rule = rules[i];

                            if (rule.type == 1 && rule.selectorText && rule.selectorText.indexOf(style) >= 0) {

                                if (!obj) {
                                    obj = {};
                                }

                                ng.extend(obj, {
                                    height: rule.style.height,
                                    width: rule.style.width,
                                    top: rule.style.top
                                });
                            } else if (rule.type == 4 && rule.media.length && window.matchMedia(rule.media[0]).matches) {
                                var res = getCssRules(rule, style);
                                if (res) {
                                    if (!obj) {
                                        obj = {};
                                    }

                                    ng.extend(obj, res);
                                }
                            }
                        }

                        return obj;
                    };

                    var setModalSize = function () {

                        var modalSize;
                        if (!$modal.data(modalSizeAttr)) {
                            modalSize = options.style ? getSizeAndPositionFromCss(options.style) : null;
                            modalSize = modalSize || getSizeAndPositionFromCss(standardSizeClass);
                            $modal.data(modalSizeAttr, modalSize);
                        } else {
                            modalSize = $modal.data(modalSizeAttr);
                        }

                        if (!modalSize.top) {
                            var top = Math.floor((parseInt($body.outerHeight()) - parseInt(modalSize.height)) / 2);
                            $modal.css('top', top + 'px');
                        }

                        $modal.css(modalSize);
                        return modalSize;
                    };

                    // mainly closes the modal
                    var close = function () {

                        closed = true;

                        layerManager.remove($shadow.css('zIndex'));
                        layerManager.remove($modal.css('zIndex'));

                        var scrollTop = $body.scrollTop();
                        var position = $elm.offset();

                        //$elm.removeClass(openClass);
                        $wrapper.removeClass(openClass);
                        //$shadow.removeClass(openClass);

                        $modal.css('top', (position.top - scrollTop) + 'px');
                        $modal.css('left', position.left + 'px');
                        $modal.css('right', ($body.outerWidth() - (position.left + $elm.outerWidth())) + 'px');
                        $modal.css('width', $elm.outerWidth() + 'px');
                        $modal.css('height', $elm.outerHeight() + 'px');

                        //enquire.unregister(query, queryHandler);

                        return;
                        setTimeout(function () {
                            $wrapper.css('display', 'none');
                        }, 800);
                    };

                    //  Get the actual html of the template.
                    var url = ft.app.getTemplateUrl(options.templateUrl);
                    TemplateHandler.get(url)
                        .then(compile)
                        .then(function (result) {

                            var scrollTop = $body.scrollTop();
                            var position = $elm.offset();
                            position.left = parseInt(position.left);
                            position.top = parseInt(position.top);

                            $modal.append(result);

                            //  We now have a modal object...
                            var modal = {
                                controller: modalController,
                                scope: modalScope,
                                element: modalElement,
                                close: closeDeferred.promise
                            };

                            $modal.addClass(noTransitionClass);
                            $modal.css('left', position.left + 'px');
                            $modal.css('right', ($body.outerWidth() - (position.left + $elm.outerWidth())) + 'px');
                            $modal.css('top', (position.top - scrollTop) + 'px');
                            $modal.css('width', $elm.outerWidth() + 'px');
                            $modal.css('height', $elm.outerHeight() + 'px');
                            $modal.removeClass(noTransitionClass);

                            $shadow.css('zIndex', layerManager.index);
                            $modal.css('zIndex', layerManager.index);

                            //$wrapper.css('display', 'block');
                            $shadow.on('click', inputClose);

                            setTimeout(function () {
                                //$elm.addClass(openClass);
                                $wrapper.addClass(openClass);
                                //$shadow.addClass(openClass);

                                queryHandler = {
                                    match: function () {
                                        // it looks like if this call can be in the method 'setModalSize', but it doesn't
                                        // we want this to have it in cache when reopen window
                                        $modal.data(modalSizeAttr, '');
                                        setModalSize();
                                    },
                                    unmatch: function () {
                                        if (closed) {
                                            return;
                                        }

                                        $modal.data(modalSizeAttr, '');
                                        setModalSize();
                                    }
                                };

                                //enquire.register(query, queryHandler);
                                setModalSize();

                            }, 400);

                            //  ...which is passed to the caller via the promise.
                            deferred.resolve(modal);

                        })
                        .catch(function (error) {
                            deferred.reject(error);
                        });

                    return deferred.promise;
                };

            }

            return new ModalService();

        }]);