import { ft } from '@js/definitions';
import { IAugmentedJQuery, IDirective, IScope, IDirectiveFactory } from 'angular';
import { MediaReference, MediaType } from '@js/models';
import { AppService } from '@js/services/FT.app.services.appservice';
import { UrlHelper } from '@js/urlHelper';
import { ngStorage } from 'ngstorage';
import { VideoMediaReference } from '@js/controllers/FT.app.controllers.dashboard';

declare global {
    interface JQueryStatic {
        fancybox: any;
    }
}

interface IDirectiveScope extends IScope {
    media: MediaReference[];
}

class LightboxDirective implements IDirective<IDirectiveScope> {

    static $inject = ['AppService', '$sessionStorage'];
    public scope = {
        media: '='
    };

    constructor(private appService: AppService, private $sessionStorage: ngStorage.StorageService) {
    }

    private linkAction(scope: IDirectiveScope, element: IAugmentedJQuery) {

        window.$.fancybox.defaults.buttons = [
            'slideShow',
            'download',
            'thumbs',
            'close',
            'zoom'
        ];

        scope.$watch('media', () => {
            this.render(scope, element);
        }, true);
    }

    private render(scope: IDirectiveScope, element: IAugmentedJQuery) {

        if (!scope.media) {
            return;
        }

        element.children().remove();

        for (const item of scope.media) {

            const $href = window.$('<a></a>');
            $href.addClass('fancybox');

            $href.attr('data-fancybox', 'images');

            if (item.type == MediaType.image) {
                $href.attr('href', item.url);
            } else if (item.type == MediaType.video) {
                $href.attr('data-src', this.createVideoUrl((item as VideoMediaReference).videoUrl));
                $href.attr('data-type', 'iframe');
                $href.attr('href', 'javascript:;');
            }

            var $img = window.$('<img />');
            $img.attr('src', item.url || item.poster);

            $href.append($img);
            element.append($href);
        }
    };

    private createVideoUrl(url: string): string {
        if (!UrlHelper.isUrlAbsolute(url)) {
            url = UrlHelper.createAbsoluteMediaUrl(this.appService.data.services['media'], url);
        }

        return `/component/video-player.html?src=${encodeURIComponent(url)}&access_token=${encodeURIComponent(this.$sessionStorage.refreshToken)}`;
    }

    link = this.linkAction.bind(this);

    static factory(): IDirectiveFactory {

        const directive = (appService: AppService, $sessionStorage: ngStorage.StorageService) => {
            return new LightboxDirective(appService, $sessionStorage);
        };

        directive.$inject = ['AppService', '$sessionStorage'];
        return directive;
    }
}

ft.app.directive('ftFancybox', LightboxDirective.factory());
