ft.app.service('RequestService', ['$http', '$q', '$timeout', '$sessionStorage', '$rootScope',
    function ($http, $q, $timeout, $sessionStorage, $rootScope) {

        this.requests = 0;

        $rootScope.$on(ft.app.events.requests.add, function () {

            this.requests++;
            $rootScope.$broadcast(ft.app.events.requests.active);

        }.bind(this));

        $rootScope.$on(ft.app.events.requests.remove, function () {

            this.requests--;

            // this cannot be true, but better to be sure
            if (this.requests < 0) {
                this.requests = 0;
            }

            if (this.requests == 0) {
                $rootScope.$broadcast(ft.app.events.requests.none);
            }

        }.bind(this));

        // base function to do a request
        this.requestAsync = function (request, useAuthorization) {

            var dfd = $q.defer();
            var url = request.url || ft.app.baseApiUrl;
            url += request.path;
            url = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'rnd=' + ft.getRandom();
            var headers = request.headers || {};

            if (useAuthorization) {
                headers = ng.extend(headers, {
                    'Authorization': 'Bearer ' + $sessionStorage.token.value
                });
            }

            if (request.method && (request.method == 'POST' || request.method == 'DELETE')) {
                headers = ng.extend(headers, {
                    'Content-Type': 'application/json'
                });
            }

            $rootScope.$broadcast(ft.app.events.requests.add);

            $http({
                headers: headers,
                url: url,
                method: request.method || 'GET',
                data: request.data || {}
            }).then(
                function (response) {
                    // done
                    dfd.resolve(response.data);
                    $rootScope.$broadcast(ft.app.events.requests.remove);
                },
                function (response) {
                    // fail
                    dfd.reject(response);
                    $rootScope.$broadcast(ft.app.events.requests.remove);
                });

            return dfd.promise;

        };

    }]);