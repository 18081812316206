ft.app.controller('navigationController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'AppService', 'DataService',
        function ($rootScope, $scope, $state, $stateParams, $timeout, AppService, DataService) {

            var initialized = false;

            $scope.appData = AppService.data;

            //#region init

            var initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            //#endregion

            //#region screen handlers

            $scope.setCurrentChild = function (id) {
                AppService.setCurrentChild(id);
            };

            //#endregion

            $timeout(initialize);

        }
    ]);
