ft.app.controller('monthPickerController',
  ['$scope', '$element', 'close', 'selectedDate', '$timeout',
    function ($scope, $element, close, selectedDate, $timeout) {

      let initialized = false;
      const today = new Date();

      $scope.data = {
        today: today,
        year: selectedDate.year || today.getFullYear(),
        selectedMonth: selectedDate.month || today.getMonth(),
        months: [
          [0, 1, 2],
          [3, 4, 5],
          [6, 7, 8],
          [9, 10, 11]
        ]
      };

      $scope.initialize = function () {

        if (initialized) {
          return;
        }

        initialized = true;
      };

      $scope.close = function () {
        close({
          year: $scope.data.year,
          month: $scope.data.selectedMonth
        });
      };

      $scope.closeWithToday = function () {
        close({
          year: new Date().getFullYear(),
          month: new Date().getMonth()
        });
      };

      $scope.cancel = function () {
        close();
      };

      $scope.up = function () {
        $scope.data.year++;
      };

      $scope.down = function () {
        $scope.data.year--;
      };

      $scope.isCurrentMonth = function (month) {

        const thisYear = moment().year();
        const thisMonth = moment().month();

        return thisYear == $scope.data.year && thisMonth == month;

      };

      $scope.isSelectedMonth = function (month) {

        return $scope.data.selectedMonth == month;

      };

      $scope.selectMonth = function (month) {
        $scope.data.selectedMonth = month;

        close({
          year: $scope.data.year,
          month: $scope.data.selectedMonth
        });
      };

      $timeout($scope.initialize);

    }]);