import { ft } from '../definitions';
import { IPromise, IRootScopeService } from 'angular';
import { ngStorage } from 'ngstorage';
import moment from 'moment';
import { Country, PersonModel, PersonRole, PersonType } from '@js/models';
import { ChildCare } from '@js/data-models';

type AppConfigurationModel = ChildCare.Definitions.Configuration.AppConfigurationModel;

export interface MonthAndYear {
    month: number;
    year: number;
}

export interface IAppData extends AppConfigurationModel {
    currentChild: string;
    family: PersonModel[];
    members: PersonModel[];
    children: PersonModel[];
    currentUser: PersonModel;
    currentLanguage: string;
}

export class AppService {

    static $inject = ['$rootScope', '$sessionStorage', '$localStorage', '$translate', 'DataService'];
    private $rootScope: IRootScopeService;
    private $sessionStorage: ngStorage.StorageService;
    private $localStorage: WindowLocalStorage;
    private $translate;
    private DataService;

    public data: IAppData;
    static data: any;

    constructor($rootScope: IRootScopeService, $sessionStorage: ngStorage.StorageService, $localStorage: WindowLocalStorage, $translate, DataService) {
        this.$rootScope = $rootScope;
        this.$sessionStorage = $sessionStorage;
        this.$localStorage = $localStorage;
        this.$translate = $translate;
        this.DataService = DataService;

        this.data = {
            currentLanguage: $localStorage[ft.app.settings.keys.language] || ft.app.datetime.locale
        } as IAppData;
    }

    public getConfigAsync(): IPromise<AppConfigurationModel> {

        const promise = this.DataService.getAppConfig() as IPromise<AppConfigurationModel>;
        promise.then(config => {
            this.data = {
                ...this.data,
                ...config
            };
            this.setCurrentCountry();
        });

        return promise;
    };

    private setCurrentCountry() {
        if (!this.data.countries || this.data.countries.length === 0) {
            return;
        }
        let country: Country = null;
        if (this.data.countries.length > 1) {
            for (let i = 0; i < this.data.countries.length; i++) {
                let c = this.data.countries[i];
                if (c.default) {
                    country = c;
                    break;
                }
            }
            if (country === null) {
                country = this.data.countries[0];
            }
        } else {
            country = this.data.countries[0];
        }
        if (country === null) {
            return;
        }

        var locale = this.data.currentLanguage.split('-');

        this.data.currentLanguage = locale[0] + '-' + country.code;

    }

    public getFamilyAsync(allChildren?: boolean): IPromise<PersonModel[]> {
        const promise = this.DataService.getFamilyAsync(allChildren) as IPromise<PersonModel[]>;
        promise.then(persons => {
            this.data.family = persons;
            this.data.members = persons.filter(item => item.type === PersonType.parent);
            this.data.children = persons.filter(item => item.type === PersonType.child);
            this.data.currentUser = persons.find(item => item.isCurrentUser);

            this.$rootScope.$broadcast(ft.app.events.family.get);
        });

        return promise;
    }

    public switchLanguage(lang: string) {
        this.data.currentLanguage = lang;
        this.$localStorage[ft.app.settings.keys.language] = lang;

        const currentLanguage = lang.split('-')[0];
        this.$translate.use(currentLanguage);
        moment.locale(currentLanguage);

        if (this.data.currentUser) {
            this.DataService.saveLanguageAsync(lang);
        }
    };

    public getUser(id: string): PersonModel {
        return this.data.family.find(item => item.id == id);
    };

    public getResponsibleUser(): PersonModel {
        return this.data.family.find(item => {
            if (item.roles && item.roles.length) {
                return item.roles.indexOf(PersonRole.responsible) >= 0;
            }
        });
    };

    public getSelectedMonthAndYear(): MonthAndYear {

        if (this.$sessionStorage.selectedMonth >= 0 || this.$sessionStorage.selectedYear) {
            return {
                month: this.$sessionStorage.selectedMonth,
                year: this.$sessionStorage.selectedYear
            };
        }

        return;
    };

    public setSelectedMonthAndYear(month: number, year: number) {
        if (month || month == 0) {
            this.$sessionStorage.selectedMonth = month;
        }

        if (year) {
            this.$sessionStorage.selectedYear = year;
        }
    };

    public setCurrentChild(id: string) {
        this.data.currentChild = this.data.currentChild == id ? null : id;
    };
}

ft.app.service('AppService', AppService);
