import { ft } from '@js/definitions';
import { DataService } from '@js/services/FT.app.services.dataservice';
import { AppService, IAppData } from '@js/services/FT.app.services.appservice';
import { IStateParamsService, IStateService } from 'angular-ui-router';
import { IScope } from 'angular';

interface Scope extends IScope {
    navigateToLogin: () => void;
    navigateToForm: () => void;
    switchLanguage: (lang: any) => void;
    appData: IAppData;
    newApplicationForm: boolean;
}

ft.app.controller('registrationIntroductionController',
    ['$scope', '$state', '$stateParams', 'AppService', 'DataService',
        function ($scope: Scope, $state: IStateService, $stateParams: IStateParamsService, AppService: AppService, dataService: DataService) {
            $scope.appData = AppService.data;
            $scope.newApplicationForm = false;

            dataService.getFeatures().then(x => {
                $scope.newApplicationForm = x.features.some(y => y.code === 'application-form');
            });

            $scope.navigateToLogin = function () {
                $state.go('login');
            };

            $scope.navigateToForm = function () {
                if ($scope.newApplicationForm) {
                    const returnUrl = encodeURIComponent(window.location.origin);
                    let localeData = AppService.data.currentLanguage.split('-');
                    const languageCode = localeData[0];
                    let countryCode = languageCode;
                    if (localeData.length > 1) {
                        countryCode = localeData[1];
                    }
                    window.location.href = `https://inschrijven.kindplanner.nl/?tenant=${$scope.appData.tenant}&lang=${languageCode}&country=${countryCode}`;
                } else {
                    $state.go('registration.new', $stateParams);
                }
            };

            $scope.switchLanguage = function (lang) {
                AppService.switchLanguage(lang);
            };
        }]);
