import { ChildCare } from '@js/data-models';
import { ft } from '@js/definitions';
import { FortyTwoLoginService } from '@js/FortyTwoFramework';
import { DataService } from '@js/services/FT.app.services.dataservice';
import { ITimeoutService, IScope } from 'angular';
import { IStateParamsService } from 'angular-ui-router';

type DocumentsResponse = ChildCare.Definitions.Family.DocumentsResponse & { loading?: boolean; downloadUrl?: string };

interface Scope extends IScope {
    uploaded: boolean;
    newDocument: File;
    uploadFile: () => void;
    resetDownloadLink: (item: DocumentsResponse) => void;
    getDownloadLink: (item: DocumentsResponse) => void;
    errorMessage: string;
    items: DocumentsResponse[];
    isLoading: boolean;
}

class ChildDocumentsController {
    public static $inject = ['$scope', '$timeout', '$translate', 'LoginService', 'DataService', '$stateParams'];

    private $scope: Scope;
    private loginService: FortyTwoLoginService;
    private dataService: DataService;
    private errorMessage: string;

    private childId: string;

    constructor($scope: Scope, $timeout: ITimeoutService, $translate: any, loginService: FortyTwoLoginService, dataService: DataService, $stateParams: IStateParamsService) {
        this.$scope = $scope;
        this.loginService = loginService;
        this.dataService = dataService;
        this.childId = $stateParams.child;

        this.errorMessage = $translate.instant('DOCUMENTS.ERROR-LOADING-DOCUMENTS');

        $timeout(this.initialize.bind(this));

        $scope.getDownloadLink = this.getDownloadLink.bind(this);
        $scope.uploadFile = this.uploadFile.bind(this);
        $scope.resetDownloadLink = function (item) {
            item.loading = false;
            item.downloadUrl = null;
        };
    }

    private async initialize(): Promise<void> {
        this.$scope.isLoading = true;

        try {
            this.$scope.items = await this.dataService.getChildDocumentsAsync(this.childId);
        } catch (error) {
            this.$scope.errorMessage = error?.message ?? this.errorMessage;
        } finally {
            this.$scope.isLoading = false;
            this.$scope.$apply();
        }
    }

    private async uploadFile(): Promise<void> {
        this.$scope.uploaded = false;

        if (this.$scope.newDocument == null) {
            return;
        }

        this.$scope.isLoading = true;

        try {
            await this.dataService.saveChildDocumentAsync(this.$scope.newDocument, this.childId);
            this.$scope.uploaded = true;
            this.$scope.newDocument = null;
        } catch (error) {
            this.$scope.errorMessage = error?.message ?? this.errorMessage;
        } finally {
            this.$scope.isLoading = false;
            this.$scope.$apply();
        }
    }

    private async getDownloadLink(item: DocumentsResponse): Promise<void>  {
        if (item.loading) {
            return;
        }

        item.loading = true;

        const prepareItemUrl = url => url + (url.indexOf('?') >= 0 ? '&' : '?');

        try {
            await this.loginService.refreshLoginAsync();
            const tokenData = this.loginService.getTokenData();

            item.downloadUrl = prepareItemUrl(item.url) + 'accessToken=' + encodeURIComponent(tokenData.token.value);

        } catch (error) {
            this.$scope.errorMessage = error?.message ?? this.errorMessage;
        } finally {
            item.loading = false;
            this.$scope.$apply();
        }
    };
}

ft.app.controller('childDocumentsController', ChildDocumentsController);