/*
 * isDebug
 * Purpose: Show if debug
 * 
 */
ft.app.directive('ftScreenContent',
    ['LanguageService', '$sce',
        function (LanguageService, $sce) {
            return {
                scope: {
                    code: '@'
                },
                link: function (scope, elm, attrs) {
                    scope.isExpanded = false;
                    scope.content = null;

                    var offCodeChange = scope.$watch('code', function (newValue, oldValue) {

                        scope.content = null;

                        if (newValue) {
                            LanguageService.getContentAsync(scope.code).then(function (results) {
                                scope.content = results[0]; 
                                scope.content.htmlContent = $sce.trustAsHtml(results[0].htmlContent);
                            }, function () {
                            });
                        }
                    });

                    scope.$on('$destroy', function () {
                        if (offCodeChange) {
                            offCodeChange();
                        }
                    });

                },
                template: '<p><span ng-if="!isExpanded" ng-bind-html="content.htmlContent"></span><span ng-if="isExpanded && content.canExpand" ng-bind-html="content.readMore"></span><span ng-if="content.canExpand"><br /><a ng-click="isExpanded = !isExpanded" ng-bind-html="content.expandLabel"></a></span></p>'
            };
        }]);
