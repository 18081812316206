moment.locale(ft.app.datetime.locale);
moment().isoWeekday(1);

/*
 * GetDayNameOfWeek
 * Purpose: Get the name of the day of the week
 * Example: 2015-01-28T15:30:50.320Z > "Th".
 * Usage:
 * {{ item.datetime | GetDayNameOfWeek }}
 *
 */
ft.app.filter('GetDayNameOfWeek', function () {
    return function (dateString) {

        return moment(dateString).format('dd');

    };
});

/*
 * GetFullDayNameOfWeek
 * Purpose: Get the full name of the day of the week
 * Example: 2015-01-28T15:30:50.320Z > "Thursday".
 * Usage:
 * {{ item.datetime | GetFullDayNameOfWeek }}
 *
 */
ft.app.filter('GetFullDayNameOfWeek', function () {
    return function (dateString) {

        return moment(dateString).format('dddd');

    };
});

/*
 * GetDayNameByIndex
 * Purpose: Get the name of the dayname
 * Example: 2 > "Th".
 * Usage:
 * {{ an index | GetDayNameOfWeek }}
 *
 */
ft.app.filter('GetDayNameByIndex', function () {
    return function (index) {
        return moment.weekdaysMin(index);
    };
});

/*
 * GetDayOfMonth
 * Purpose: Get the day in date
 * Example: 2015-01-28T15:30:50.320Z > "Th".
 * Usage:
 * {{ item.datetime | GetDayOfMonth }}
 *
 */
ft.app.filter('GetDayOfMonth', function () {
    return function (dateString) {

        return moment(dateString).format('DD');

    };
});

/*
 * GetMonthName
 * Purpose: Get the name of the month
 * Example: 2015-01-28T15:30:50.320Z > "Jan".
 * Usage:
 * {{ item.datetime | GetMonthName }}
 *
 */
ft.app.filter('GetMonthName', function () {
    return function (dateString) {

        return moment(dateString).format('MMM').replace(/\./, '');

    };
});

/*
 * GetMonthNameByIndex
 * Purpose: Get the name of the month by index
 * Example: 1 > "Jan".
 * Usage:
 * {{ i | GetMonthName }}
 *
 */
ft.app.filter('GetMonthNameByIndex', function () {
    return function (index) {

        // index = 0 (january) will generate a false so ignore 0
        if (!index && !(index == 0)) {
            return '';
        }

        return moment().month(index).format('MMMM');

    };
});

/*
 * GetYear
 * Purpose: Get the last 2 digits of the year
 * Example: 2015-01-28T15:30:50.320Z > "15".
 * Usage:
 * {{ item.datetime | GetYear }}
 *
 */
ft.app.filter('GetYear', function () {
    return function (dateString) {

        return moment(dateString).format('YY');

    };
});

/*
 * GetFullYear
 * Purpose: Get the year
 * Example: 2015-01-28T15:30:50.320Z > "2015".
 * Usage:
 * {{ item.datetime | GetYear }}
 *
 */
ft.app.filter('GetFullYear', function () {
    return function (dateString) {

        return moment(dateString).format('YYYY');

    };
});

/*
 * GetTime
 * Purpose: Get the hour and minutes
 * Example: 2015-01-28T15:30:50.320Z > "15:30".
 * Usage:
 * {{ item.datetime | GetTime }}
 *
 */
ft.app.filter('GetTime', function () {
    return function (dateString, isOnlyTime) {
        if (!dateString) {
            return '';
        }

        var m;

        if (isOnlyTime == true) {
            m = moment(dateString, 'HH:mm:ss');
        } else {
            m = moment(dateString);
        }

        //return m.utcOffset(ft.app.datetime.offset).format('HH:mm');
        return m.format('HH:mm');
    };
});

/*
 * FromNow
 * Purpose: Converts datetime values to a readable context aware string.
 * Example: 1409652117038 > "9 minutes ago".
 * Usage:
 * {{ item.datetime | FromNow }}
 *
 */
ft.app.filter('FromNow', function () {
    return function (dateString) {

        return moment(dateString).fromNow();

    };
});

/*
 * RelativeFormatDate
 * Purpose: Formats the date (without time), but today and yesterday is humanized.
 * Usage:
 * {{ item.date | RelativeFormatDate }}
 *
 */
ft.app.filter('RelativeFormatDate', function () {
    return function (dateString) {

        var today = moment(new Date()).format('YYYY-MM-DD'); // make sure to have only year, month and date WITHOUT time
        var now = moment(today);
        var time = moment(dateString);
        var diff = now.diff(time, 'days');

        if (diff == 0) {
            return 'vandaag';
        } else if (diff == -1) {
            return 'morgen';
        } else {
            return time.format(ft.app.datetime.formats.default);
        }

    };
});

/*
 * FormatDate
 * Purpose: Formats the date (without time)
 * Usage:
 * {{ item.datetime | FormatDate }}
 *
 */
ft.app.filter('FormatDate', function () {
    return function (dateString) {
        return moment(dateString).format(ft.app.datetime.formats.default);
    };
});
