ft.app.directive('ftPlanningBlocks',
    function () {

        return {
            replace: true,
            controller: 'planningBlocklistController',
            templateUrl: ft.app.getTemplateUrl('planning/blocklist'),
            link: function (scope, elm, attrs) {

            }
        };
    });

ft.app.directive('ftPlanningStyle',
    function () {
        return {
            link: function (scope, elm, attrs) {

                var planning = ft.app.planning;
                var day = scope.$eval(attrs.day);
                var block = scope.$eval(attrs.block);
                var $elm = $(elm);

                if (block) {
                    if (block.status == planning.states.requestable) {
                        elm.addClass('requestable');
                    } else if (block.status == planning.states.requested) {
                        elm.addClass('requested');

                        if (block.type == planning.types.swap && block.presence == planning.presence.traded) {
                            elm.addClass('traded');
                        }
                    } else if (block.status == planning.states.rejected) {
                        elm.addClass('rejected');
                    } else if (block.presence == planning.presence.holiday || block.presence == planning.presence.sick || block.presence == planning.presence.absent || block.type == planning.types.absent || (block.type == planning.types.swap && block.presence == planning.presence.traded)) {
                        elm.addClass('absent');
                    } else if (block.status == planning.states.approved && block.type != planning.types.absent) {
                        elm.addClass('approved');
                    }
                } else if (day) {
                    if (new Date(day.date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)) {
                        elm.addClass('today');
                    }

                    if (day.type == planning.days.closed) {
                        elm.addClass('closed');
                    }

                    if (day.type == planning.days.holiday) {
                        elm.addClass('holiday');
                    }
                }

            }
        };
    });
