import { ft } from '../definitions';
import moment from 'moment';
import { IPromise, IRootScopeService, IScope, ITimeoutService } from 'angular';
import { IStateParamsService } from 'angular-ui-router';
import { ngStorage } from 'ngstorage';
import { MediaListResponse, MediaReference, MediaType } from '@js/models';
import { UrlHelper } from '@js/urlHelper';
import { AppService } from '@js/services/FT.app.services.appservice';
import { DataService } from '../services/FT.app.services.dataservice';
import { VideoMediaReference } from './FT.app.controllers.dashboard';

interface IData {
    errorText: string;
    isLoading: boolean;
}

interface ILocalScope extends IScope {
    sendMediaNotification: any;
    handleGetMediaError: (response: any) => void;
    media: MediaItem[];
    images: MediaItem[];
    videos: VideoMediaReference[];

    data: IData;

    openLightbox: (mediaItem: MediaItem) => void;
    initializeData: () => void;
    initialize: () => void;
    refreshMedia: () => void;
}

export interface MediaItem {
    src: string; // Required for lightbox
    albumId: string;
    mediaId: string;
    thumbUrl: string;
    caption: string;
    type: MediaType;
    media: MediaReference;
}

ft.app.controller('medialibraryController',
    ['$rootScope', '$scope', '$stateParams', '$timeout', '$translate', 'DataService', 'AppService', '$sessionStorage', 'lightbox',
        function (
            $rootScope: IRootScopeService, $scope: ILocalScope,
            $stateParams: IStateParamsService, $timeout: ITimeoutService,
            $translate: angular.translate.ITranslateService, dataService: DataService, AppService: AppService,
            $sessionStorage: ngStorage.StorageService, lightbox) {

            let initialized = false;
            let lastFetchDateMedia = null;
            const token = $sessionStorage.refreshToken;

            const lightboxOptions = {
                fitImageInViewPort: true,
                showImageNumberLabel: true,
                alwaysShowNavOnTouchDevices: false,
                wrapAround: false
            };

            $scope.media = [];

            $scope.data = {
                isLoading: false,
                errorText: ''
            };

            $scope.initialize = function () {
                if (initialized) {
                    return;
                }

                if ($stateParams.child && AppService.data.currentUser) {
                    $scope.initializeData();
                } else {
                    $rootScope.$on(ft.app.events.family.get, $scope.initializeData);
                }

                initialized = true;
            };

            $scope.initializeData = function () {
                $scope.data.isLoading = true;

                getMediaAsync()
                    .then(handleGetMediaInitial)
                    .finally(() => $scope.data.isLoading = false);
            };

            $scope.openLightbox = (mediaItem: MediaItem) => {
                lightbox.open($scope.images, $scope.images.indexOf(mediaItem), lightboxOptions);
            };

            var getMediaAsync = function (): IPromise<MediaListResponse> {
                const dateTime = moment().utc().format('YYYY-MM-DDTHH-mm-ss');
                if (!lastFetchDateMedia) {
                    lastFetchDateMedia = dateTime;
                    return dataService.getMediaUntilAsync(lastFetchDateMedia, $stateParams.child);
                }

                const returnValue = dataService.getMediaSinceAsync(lastFetchDateMedia);
                lastFetchDateMedia = dateTime;
                return returnValue;
            };

            $scope.refreshMedia = function () {
                console.log('Im refreshing the media...');
                getMediaAsync().then(handleGetMediaLater);
            };

            var handleGetMediaInitial = function (results: MediaListResponse) {
                return handleGetMedia(results, true);
            };

            var handleGetMediaLater = function (results: MediaListResponse) {
                return handleGetMedia(results, false);
            };

            var handleGetMedia =  function (results: MediaListResponse, isInitial: boolean) {
                let currentItems = $scope.media || [];

                const newMediaItems = results.media.map(m => ({
                    albumId: m.albumId,
                    mediaId: m.mediaId,
                    src: createUrl(m.media.url),
                    thumbUrl: createUrl(m.media.poster),
                    caption: m.media.title,
                    type: m.media.type,
                    media: m.media
                }));

                for (const mediaItem of newMediaItems) {
                    const existingItem = currentItems.find(x => x.mediaId == mediaItem.mediaId);
                    if (existingItem) {
                        currentItems.unshift(existingItem);
                    }
                    if (isInitial) {
                        currentItems.push(mediaItem);
                    }
                    else {
                        currentItems.unshift(mediaItem);
                    }    
                }

                $scope.media = currentItems;

                $scope.images = $scope.media.filter(m => m.type == MediaType.image);
                $scope.videos = $scope.media.filter(m => m.type == MediaType.video).map(v => {
                    const media = v.media as VideoMediaReference;

                    var newMedia = {...media };

                    newMedia.videoUrl = media.url;
                    newMedia.url = v.thumbUrl;

                    return newMedia;
                });
            };           

            $scope.handleGetMediaError = function () {
                $scope.data.errorText = $translate.instant('CHILD-MEDIA.ERROR-GET-ITEMS');
            };

            function createUrl(url: string): string {

                const encodedToken = encodeURIComponent(token);

                let absoluteUrl = url;

                if (!UrlHelper.isUrlAbsolute(absoluteUrl)) {
                    absoluteUrl = UrlHelper.createAbsoluteMediaUrl(AppService.data.services['media'], url);
                }

                return `${absoluteUrl}?access_token=${encodedToken}`;
            }

            $timeout($scope.initialize);

        }
    ]);