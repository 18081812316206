ft.app.controller('helpDashboardController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', 'DataService', 'AppService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, DataService, AppService) {

            var initialized = false;

            $scope.appData = AppService.data;
            $scope.data = {};

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $timeout($scope.initialize);

        }
    ]);
