/*
 * CheckDefaultProfilePicture
 * Purpose: If no picture is present, then default profile picture according to the gender and type (child, not child) is returned
 * Usage:
* {{ item.picture.url | CheckDefaultProfilePicture }}
* 
*/
ft.app.filter('CheckDefaultProfilePicture', function () {
    return function (url, gender, type) {

        if (url) {
            return url;
        }

        if (gender == ft.app.gender.female) {
            return type == ft.app.family.types.child ? ft.app.family.images.girl : ft.app.family.images.woman;
        } else {
            return type == ft.app.family.types.child ? ft.app.family.images.boy : ft.app.family.images.men;
        }

    };
});

/*
 * TranslateGender
 * Purpose: Translate gender type to a nice string
 * Usage:
* {{ item.gender | TranslateGender }}
* 
*/
ft.app.filter('TranslateGender', function () {
    return function (gender, type) {

        if (ft.app.gender.female == gender) {
            return type == ft.app.family.types.child ? 'meisje' : 'vrouw';
        } else if (ft.app.gender.male == gender) {
            return type == ft.app.family.types.child ? 'jongen' : 'man';
        } else {
            return '';
        }

    };
});

ft.app.filter('UserFirstName', function () {
    return function (id, users) {

        users = users || [];
        var user = users.find(x => x.id == id);

        if (!user) {
            return '';
        }
        return user.name.firstName;

    };
});
