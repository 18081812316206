//#region IE version

var ie = (function () {

    var undef,
        v = 3,
        div = document.createElement('div'),
        all = div.getElementsByTagName('i');

    while (
        div.innerHTML = '<!--[if gt IE ' + (++v) + ']><i></i><![endif]-->',
            all[0]
        ){
    }

    return v > 4 ? v : undef;

}());

//#endregion

//#region Window location origin

if (!window.location.origin) {
    window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

//#endregion

window.FortyTwo = window.FortyTwo || function () {
};

FortyTwo.prototype.DEBUG = window.location.origin.indexOf('.ngrok.io') >= 0 ||
    window.location.origin.indexOf('localhost') >= 0 ||
    window.location.origin.indexOf('.local') >= 0 ||
    window.location.origin.indexOf('.test') >= 0;

FortyTwo.prototype.createDelegate = function (ctx, fnc) {

    // because arguments is an object that can act like an array, use slice to return arguments as array.
    var slice = Array.prototype.slice;

    // get every argument after index 1 if applied
    var args = arguments.length > 2 ? slice.call(arguments, 2) : null;

    // return a new function with pre-given parameters and parameters when called
    return function () {
        return fnc.apply(ctx, args ? args.concat(slice.call(arguments)) : arguments);
    };

};

FortyTwo.prototype.defineNamespace = function (namespace) {

    var nsparts = namespace.split('.');
    var parent;

    // we want to be able to include or exclude the root namespace
    if (nsparts[0] === 'FortyTwo') {

        // if not exists, create it
        if (!window[nsparts[0]]) {
            window[nsparts[0]] = {};
        }

        parent = window[nsparts[0]];
        nsparts = nsparts.slice(1);
    }

    // loop through the parts and create a nested namespace if necessary
    for (var i = 0; i < nsparts.length; i++) {

        var partname = nsparts[i];

        // check if the current parent already has the namespace declared
        // if it isn't, then create it
        if (typeof parent[partname] === 'undefined') {
            parent[partname] = {};
        }

        parent = parent[partname];

    }

    // the parent is now constructed with empty namespaces and can be used
    return parent;

};

/*
 * defineBindableGetter
 * purpose: defines getter property that will be seen by the hasOwnProperty method in angular.extend
 */
FortyTwo.prototype.defineBindableGetter = function (obj, prop, fnc) {

    Object.defineProperty(obj, prop, {
        enumerable: true,
        configurable: false,
        writable: false,
        value: fnc.bind(obj)()
    });

};

FortyTwo.prototype.getRandom = function (min, max) {

    min = min || 1;
    max = max || 9999999;

    return Math.floor((Math.random() * (max - min)) + min);

};

FortyTwo.prototype.getInterval = function (min, max) {

    min = min || 10;
    max = max || 20;

    return FortyTwo.getRandom(min, max) * 1000;

};

// usage ft.getQueryVariable('id|name|prodId')
FortyTwo.prototype.getQueryVariable = function (name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'), results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

// usage ft.getQueryParam('id|name|prodId')
FortyTwo.prototype.getQueryParam = function (name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

    var regex = new RegExp('[\\?&]' + name + '=([^/&#]*)');
    var results = regex.exec(window.location.href);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

FortyTwo = new FortyTwo();
FortyTwo.browser = ie;
FortyTwo.randomNumber = FortyTwo.getRandom();