import { validateRegistrationForm2 } from '@js/registration-form-validator';

ft.app.controller('familyController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', 'DataService', 'AppService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, DataService, AppService) {

            var initialized = false;

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $timeout($scope.initialize);

        }]);

ft.app.controller('familyListController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$translate', 'DataService', 'AppService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $translate, DataService, AppService) {

            var initialized = false;

            $scope.appData = AppService.data;
            $scope.data = {
                successMessage: ''
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

                if ($stateParams.success && $stateParams.child) {
                    $scope.data.successMessage = $translate.instant('FAMILY.SAVED-PARENT');
                } else if ($stateParams.success && $stateParams.child) {
                    $scope.data.successMessage = $translate.instant('FAMILY.SAVED-CHILD');
                }

            };

            $scope.navigateToUser = function (id) {
                $state.go('app.user.info', {user: id});
            };

            $timeout($scope.initialize);

        }
    ]);

ft.app.controller('familyAddChildController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$filter', '$translate', 'DataService', 'AppService', 'CheckStateChangeService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $filter, $translate, DataService, AppService, CheckStateChangeService) {

            var groupByFilter = $filter('GroupByFilter');
            var initialized = false;

            $scope.$watch('data.contact.address.countryId', (newValue) => {
                getPostcodeFormat(newValue, $scope.data.contact.addressValid);
            });

            $scope.$watch('data.parentOne.address.countryId', (newValue) => {
                getPostcodeFormat(newValue, $scope.data.parentOne.addressValid);
            });

            $scope.$watch('data.parentTwo.address.countryId', (newValue) => {
                getPostcodeFormat(newValue, $scope.data.parentTwo.addressValid);
            });

            $rootScope.checkForFormChange = true;
            CheckStateChangeService.checkFormOnStateChange($scope, 'childForm');

            var loadingKeyPackages = 'packages';
            var loadingKeyLocations = 'locations';

            var addLoading = function (key) {
                if ($scope.data.loading.indexOf(key) < 0) {
                    $scope.data.loading.push(key);
                }
            };

            var removeLoading = function (key) {
                var idx = $scope.data.loading.indexOf(key);
                if (idx >= 0) {
                    $scope.data.loading.splice(idx, 1);
                }
            };

            var getPostcodeFormat = function(countryId, addressValid){
                if (addressValid == null) {
                    return;
                }

                if (countryId == null || countryId === ''){
                    addressValid.postcodeFormat = null;
                    addressValid.postcodePattern = null;
                } else {
                    const country = $scope.data.countries.find(c => c.countryId === countryId);
                    if (country != null) {
                        addressValid.postcodeFormat = country.postcodeFormat;
                        addressValid.postcodePattern = country.postcodePattern;
                    }
                }
            };

            $scope.data = {
                appData: AppService.data,
                careConfig: ft.app.care,
                loading: [],
                newRegistration: $state.current.data && $state.current.data.newRegistration,
                maxLocations: 3,
                currentStep: parseInt($stateParams.step) || 0,
                steps: [
                    {
                        key: 'basics',
                        title: $translate.instant('NEW-CONTRACT.STEP-CHILD-INFORMATION'),
                        description: 'pp-screen-contract-basics'
                    },
                    {
                        key: 'registerOptions',
                        title: $translate.instant('NEW-CONTRACT.STEP-REGISTER-OPTIONS'),
                        description: 'pp-screen-contract-registeroptions'
                    },
                    {
                        key: 'packages',
                        title: $translate.instant('NEW-CONTRACT.STEP-PACKAGES'),
                        description: 'pp-screen-contract-packages'
                    },
                    {
                        key: 'locations',
                        title: $translate.instant('NEW-CONTRACT.STEP-LOCATIONS'),
                        description: 'pp-screen-contract-locations'
                    },
                    {
                        key: 'comments',
                        title: $translate.instant('NEW-CONTRACT.STEP-COMMENTS'),
                        description: 'pp-screen-contract-comments'
                    }
                ],
                now: new Date(),
                registrationId: null,
                startdate: null,
                name: {
                    lastName: '',
                    middleName: '',
                    firstName: ''
                },
                dateOfBirth: '',
                gender: null,
                countries: null,
                contactSameAsParent: true,
                fillInSecondParent: false,
                parentHasSameAddress: true,
                contact: {
                    name: {
                        lastName: '',
                        middleName: '',
                        firstName: '',
                        initials: ''
                    },
                    gender: null,
                    dateOfBirth: '',
                    phone: '',
                    email: '',
                    address: {
                        street: '',
                        number: '',
                        zipCode: '',
                        city: '',
                        countryId: '',
                        municipalityId: ''
                    },
                    initialsValid: true,
                    firstNameValid: true,
                    lastNameValid: true,
                    dateOfBirthValid: true,
                    genderValid: true,
                    addressValid: {
                        street: true,
                        number: true,
                        zipCode: true,
                        zipCodeFormat: true,
                        city: true,
                        country: true
                    },
                    emailValid: true,
                    phoneValid: true
                },
                parentOne: {
                    name: {
                        lastName: '',
                        middleName: '',
                        firstName: '',
                        initials: ''
                    },
                    gender: null,
                    dateOfBirth: '',
                    address: {
                        street: '',
                        number: '',
                        zipCode: '',
                        city: '',
                        countryId: '',
                        municipalityId: ''
                    },
                    initialsValid: true,
                    firstNameValid: true,
                    lastNameValid: true,
                    dateOfBirthValid: true,
                    genderValid: true,
                    addressValid: {
                        street: true,
                        number: true,
                        zipCode: true,
                        zipCodeFormat: true,
                        city: true,
                        country: true
                    }
                },
                parentTwo: {
                    name: {
                        lastName: '',
                        middleName: '',
                        firstName: '',
                        initials: ''
                    },
                    gender: null,
                    dateOfBirth: '',
                    email: '',
                    address: {
                        street: '',
                        number: '',
                        zipCode: '',
                        city: '',
                        countryId: '',
                        municipalityId: ''
                    },
                    initialsValid: true,
                    firstNameValid: true,
                    lastNameValid: true,
                    dateOfBirthValid: true,
                    genderValid: true,
                    addressValid: {
                        street: true,
                        number: true,
                        zipCode: true,
                        zipCodeFormat: true,
                        city: true,
                        country: true
                    }
                },
                registerConfig: null,
                locations: [],
                chosenRegisterOption: null,
                chosenLocations: [],
                chosenPackages: [],
                chosenSchool: null,
                chosenClass: null,
                configQuestions: null,
                questions: null,
                comments: '',
                dateOfBirthOpened: false,
                startdateOpened: false,
                contactDateOfBirthOpened: false,
                parentOneDateOfBirthOpened: false,
                parentTwoDateOfBirthOpened: false,
                dateOptions: {
                    formatYear: 'yy'
                },
                formats: ['dd-MM-yyyy', 'd!-MMMM-yyyy', 'yyyy/M!/d!', 'd!.M!.yyyy', 'shortDate'],
                format: 'dd-MM-yyyy',
                dateOfBirthValid: true,
                lastNameValid: true,
                firstNameValid: true,
                genderValid: true,
                emergencyNumber: '',
                insuranceOrganizationName: '',
                insuranceOrganizationNumber: '',
                chosenRegisterOptionValid: true,
                chosenSchoolValid: true,
                startdateValid: true,
                chosenPackagesValid: true,
                chosenLocationsValid: true,
                questionsValid: true,
                successMessage: '',
                errorMessage: '',
                saveEnabled: true,
                saving: false,
                attempts: 0,
                processingUserData: false,
                processingUserDataValid: true
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                if ($state.current.data && $state.current.data.newRegistration) {
                    $scope.data.steps.splice(1, 0, {
                        key: 'members',
                        title: $translate.instant('NEW-CONTRACT.STEP-MEMBERS'),
                        description: 'pp-screen-contract-members'
                    });

                    if ($stateParams.lang) {
                        $scope.switchLanguage($stateParams.lang);
                    }

                } else {
                    $rootScope.$on(ft.app.events.family.get, $scope.initializeFamily);
                }

                $scope.setupStep();

                initialized = true;

            };

            $scope.initializeFamily = function () {
                var data = $scope.data;
                var appData = AppService.data;
                var responsible = AppService.getResponsibleUser();

                if (!data.name.lastName) {
                    data.name.lastName = responsible.name.lastName;
                }

                if (!data.name.middleName) {
                    data.name.middleName = responsible.name.middleName;
                }
            };

            $scope.setLastName = function (target) {
                var data = $scope.data;
                target.lastName = data.name.lastName;
            };

            $scope.changeTimeslot = function (packageId, timeslots, time) {

                var data = $scope.data;
                var overlapping = time.overlappingTimeslotIds;
                var overlapTime;

                if (overlapping && overlapping.length) {
                    overlapping.forEach(function (overlap) {

                        overlapTime = timeslots.find(x => x.timeslotId == overlap);

                        if (overlapTime) {
                            data.chosenPackages[packageId][overlapTime.timeslotId]['chosen'] = false;
                        }

                    });
                }

            };

            $scope.switchLanguage = function (lang) {
                AppService.switchLanguage(lang);
            };

            $scope.disableDay = function (date, mode) {
                var data = $scope.data;

                var minimal = $scope.getMinimalStartdate();
                date.setHours(0, 0, 0, 0);

                if (minimal > date) {
                    return true;
                } else if (mode === 'day' && data.registerConfig.validStartDays.length) {
                    var possibles = data.registerConfig.validStartDays;
                    return possibles.indexOf(date.getDate()) < 0;
                }
            };

            $scope.getMinimalStartdate = function () {
                var data = $scope.data;
                var start = new Date();
                start.setDate(start.getDate() + 1);
                start.setHours(0, 0, 0, 0);

                if (data.dateOfBirth && data.dateOfBirth > start) {
                    start = new Date(data.dateOfBirth);
                    start.setDate(start.getDate() + 1);
                    start.setHours(0, 0, 0, 0);
                }

                return start;
            };

            $scope.open = function (key, $event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.data[key + 'Opened'] = true;
            };

            $scope.previousStep = function (evt) {
                evt.target.blur();

                $scope.data.currentStep -= 1;
                $scope.setupStep();
            };

            $scope.nextStep = function (evt) {
                evt.target.blur();

                var data = $scope.data;

                if ($scope.finishStep()) {
                    if (data.currentStep == data.steps.length - 1) {
                        if (!data.processingUserData) {
                            data.processingUserDataValid = false;
                            return;
                        }

                        $scope.save();
                    } else {
                        data.currentStep += 1;

                        $scope.setupStep();
                        ng.element(evt.currentTarget).closest('.info-overlay-wrapper').scrollTop(0);
                    }
                }
            };

            $scope.setupStep = function () {
                var data = $scope.data;
                var step = data.steps[data.currentStep];

                switch (step.key) {
                    case 'basics':
                        $scope.setupBasics();
                        break;
                    case 'members':
                        $scope.setupMembers();
                        break;
                    case 'registerOptions':
                        $scope.setupRegisterOptions();
                        break;
                    case 'packages':
                        $scope.setupPackages();
                        break;
                    case 'locations':
                        $scope.setupLocations();
                        break;
                    case 'comments':
                        $scope.setupComments();
                        break;
                }
            };

            $scope.finishStep = function () {

                var data = $scope.data;
                var step = data.steps[data.currentStep];
                var result = false;

                switch (step.key) {
                    case 'basics':
                        result = $scope.finishBasics();
                        break;
                    case 'members':
                        result = $scope.finishMembers();
                        break;
                    case 'registerOptions':
                        result = $scope.finishRegisterOptions();
                        break;
                    case 'packages':
                        result = $scope.finishPackages();
                        break;
                    case 'locations':
                        result = $scope.finishLocations();
                        break;
                    case 'comments':
                        result = $scope.finishComments();
                        break;
                }

                return result;
            };

            $scope.setupBasics = function () {
            };

            $scope.setupMembers = function () {
                $scope.data.countries = $scope.data.appData.countries;
                $scope.data.municipalities = $scope.data.appData.municipalities;
            };

            $scope.setupRegisterOptions = function () {
                var data = $scope.data;
                var appData = AppService.data;

                data.registerOptions = appData.registerOptions;
                data.schools = appData.schools;
            };

            $scope.setupPackages = function () {
                var data = $scope.data;

                data.packageGroups = null;
                data.registerConfig = null;

                addLoading(loadingKeyPackages);

                var prm = DataService.getRegisterPackagesAsync(data.chosenRegisterOption.id, data.chosenSchool ? data.chosenSchool.id : null);
                prm.then($scope.handleGetRegisterPackages, $scope.handleGetRegisterPackagesError);
            };

            $scope.setupLocations = function () {

                addLoading(loadingKeyLocations);

                var data = $scope.data;
                var chosenPackages = [];
                var key, chosenPackage;

                for (key in data.chosenPackages) {

                    chosenPackage = data.chosenPackages[key];

                    if (chosenPackage.chosen) {
                        chosenPackages.push(key);
                    }

                }

                var schoolId = null;
                if (data.chosenSchool) {
                    schoolId = data.chosenSchool.id;
                }

                data.errorMessage = '';
                data.locations = [];

                var prm = DataService.getRegisterLocationsAsync(chosenPackages, schoolId, data.startdate);
                prm.then($scope.handleGetRegisterLocations, $scope.handleGetRegisterLocationsError);

            };

            $scope.setupComments = function () {

                var data = $scope.data;
                var configQuestions = data.registerConfig.questions;
                var chosenPackages = data.chosenPackages;
                var i, chosenPackage, question;
                var questions = [];

                for (i = 0; i < configQuestions.length; i++) {

                    question = configQuestions[i];

                    if (!question.packageId) {
                        questions.push(question);
                    } else {

                        chosenPackage = chosenPackages[question.packageId];

                        if (chosenPackage && chosenPackage['chosen']) {
                            questions.push(question);
                        }

                        if (!chosenPackage) {
                            for (var configPackage of data.registerConfig.packages) {
                                if (configPackage.label && configPackage.label.id === question.packageId) {
                                    chosenPackage = chosenPackages[configPackage.id];
                                    if (chosenPackage && chosenPackage['chosen']) {
                                        questions.push(question);
                                    }
                                }
                            }
                        }

                    }

                }

                data.configQuestions = questions;
            };

            $scope.finishBasics = function () {

                var data = $scope.data;

                data.dateOfBirthValid = true;
                data.lastNameValid = true;
                data.firstNameValid = true;
                data.genderValid = true;

                if (data.dateOfBirth) {
                    var date = moment(data.dateOfBirth).format(ft.app.datetime.formats.toAPIDate);

                    if (!moment(date).isValid()) {
                        data.dateOfBirthValid = false;
                    }
                } else {
                    data.dateOfBirthValid = false;
                }

                if (!data.name.lastName.trim()) {
                    data.lastNameValid = false;
                }

                if (data.dateOfBirth && !$scope.isDateOfBirthInFuture()) {

                    if (!data.name.firstName.trim()) {
                        data.firstNameValid = false;
                    }

                    if (!data.gender) {
                        data.genderValid = false;
                    }

                }

                if (!data.dateOfBirthValid || !data.lastNameValid || !data.firstNameValid || !data.genderValid) {
                    return false;
                } else {
                    return true;
                }
            };

            $scope.finishMembers = function () {
                return validateRegistrationForm2($scope.data);
            };

            $scope.finishRegisterOptions = function () {

                var data = $scope.data;
                data.chosenRegisterOptionValid = true;
                data.chosenSchoolValid = true;

                if (!data.chosenRegisterOption) {
                    data.chosenRegisterOptionValid = false;
                } else if (data.chosenRegisterOption.id == ft.app.care.types.outOfSchool && data.schools.length && !data.chosenSchool) {
                    data.chosenSchoolValid = false;
                }

                if (!data.chosenRegisterOptionValid || !data.chosenSchoolValid) {
                    return false;
                } else {
                    return true;
                }
            };

            $scope.finishPackages = function () {

                var data = $scope.data;
                data.startdateValid = true;
                data.chosenPackagesValid = true;

                // startdate must be after today, after date of birth (if given) and on a valid start day (if given)
                var startdate = data.startdate;
                var minimal = $scope.getMinimalStartdate();
                var today = new Date();
                today.setHours(0, 0, 0, 0);

                // validate startdate
                if (startdate < minimal || startdate < today) {
                    data.startdateValid = false;
                } else if (data.registerConfig.validStartDays.length && data.registerConfig.validStartDays.indexOf(startdate.getDate()) < 0) {
                    data.startdateValid = false;
                }

                if (data.dateOfBirth && data.dateOfBirth > data.startdate) {
                    data.startdateValid = false;
                }

                // validate packages
                var i = 0, t = 0;
                var chosen = false;
                var chosenPackages = data.chosenPackages;
                var registerPackages = data.registerConfig.packages;
                var registerPackage, chosenPackage;
                var time;

                while (i < registerPackages.length && !chosen) {
                    registerPackage = registerPackages[i];

                    chosenPackage = chosenPackages[registerPackage.id];

                    if (chosenPackage && chosenPackage['chosen']) {

                        for (t = 0; t < registerPackage.timeslots.length; t++) {
                            time = chosenPackage[registerPackage.timeslots[t].timeslotId];

                            if (time && time['chosen']) {
                                chosen = true;
                            }
                        }

                    }

                    i += 1;
                }

                if (!chosen) {
                    data.chosenPackagesValid = false;
                }

                if (!data.startdateValid || !data.chosenPackagesValid) {
                    return false;
                }

                return true;
            };

            $scope.finishLocations = function () {
                var data = $scope.data;
                data.chosenLocationsValid = true;

                var i = 0;
                var chosen = false;
                var chosenLocationId;

                while (i < data.chosenLocations.length && !chosen) {

                    chosenLocationId = data.chosenLocations[i]['id'];

                    if (chosenLocationId && data.locations.find(x => x.id == chosenLocationId)) {
                        chosen = true;
                    }

                    i += 1;
                }

                if (!chosen) {
                    data.chosenLocationsValid = false;
                }

                if (!data.chosenLocationsValid) {
                    return false;
                }

                return true;
            };

            $scope.finishComments = function () {

                var valid = true;
                var data = $scope.data;
                var configQuestions = data.configQuestions;
                var questions = data.questions || {};
                var i, chosenPackage, configQuestion, question;

                for (i = 0; i < configQuestions.length; i++) {

                    configQuestion = configQuestions[i];
                    question = questions[configQuestion.questionId];

                    if (configQuestion.isRequired && !question) {
                        valid = false;
                    }

                }

                data.questionsValid = valid;
                return valid;

            };

            $scope.handleGetRegisterPackages = function (results) {
                var startdate = new Date();
                var data = $scope.data;

                data.registerConfig = results;
                data.registerConfig.packages.sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase();
                });

                var ids = data.registerConfig.packages.map(function (item) {
                    return item.id;
                });

                // set chosen to false on all other packages (if the user has chosen to go back to step 1)
                var key;
                for (key in data.chosenPackages) {

                    // package is from other register option
                    if (ids.indexOf(key) < 0) {
                        data.chosenPackages[key].chosen = false;
                    }
                }

                removeLoading(loadingKeyPackages);
            };

            $scope.handleGetRegisterPackagesError = function (response) {
            };

            $scope.handleGetRegisterLocations = function (results) {

                var data = $scope.data;
                data.locations = results || [];

                if (!data.locations.length) {
                    data.errorMessage = $translate.instant('NEW-CONTRACT.NO-LOCATIONS-AVAILABLE');
                    return;
                }

                if (data.maxLocations > data.locations.length) {
                    data.maxLocations = data.locations.length;
                }

                removeLoading(loadingKeyLocations);

            };

            $scope.handleGetRegisterLocationsError = function (response) {
            };

            $scope.getFilteredLocations = function (idx) {
                var data = $scope.data;

                var selected = data.chosenLocations.filter(function (itm) {
                    return (itm.id);
                });

                if (selected.length == data.locations.length) {
                    return data.locations;
                }

                if (data.chosenLocations[idx].id) {
                    return data.locations;
                }

                var mapped = data.chosenLocations.map(function (itm) {
                    return itm.id;
                });

                var selectedLocations = mapped.toString();
                var locs = data.locations.filter(function (loc) {
                    return selectedLocations.indexOf(loc.id) < 0;
                });

                return locs;

            };

            $scope.distinctChosenLocations = function (idx) {
                var data = $scope.data;
                var chosen = data.chosenLocations[idx].id;
                var chosenLocationId;
                var i;

                for (i = data.chosenLocations.length - 1; i >= 0; i--) {

                    chosenLocationId = data.chosenLocations[i].id;

                    if (i != idx && chosenLocationId && chosenLocationId == chosen) {
                        chosenLocationId = null;
                    }

                }
            };

            $scope.initChosenSchool = function () {

                var data = $scope.data;

                if (!data.chosenSchool && data.appData.schools.length) {
                    data.chosenSchool = data.appData.schools[0];
                }

            };

            $scope.isDateOfBirthInFuture = function () {
                var data = $scope.data;

                if (data.dateOfBirth) {
                    return ft.dates.isDateInFuture(data.dateOfBirth);
                } else {
                    return false;
                }
            };

            $scope.showFrequencyColumn = function (pack) {
                var data = $scope.data;
                var result = false;
                var i, time, id;
                var chosen = data.chosenPackages[pack.id];

                for (i = 0; i < pack.timeslots.length; i++) {
                    time = pack.timeslots[i];
                    id = time.timeslotId;

                    if (chosen && chosen[id] && chosen[id]['chosen']) {
                        result = true;
                    }
                }

                return result;
            };

            $scope.save = function () {

                var data = $scope.data;
                var obj = {
                    'registrationId': data.registerConfig.registrationId,
                    'preferredLocations': $scope.getLocationsForSave(),
                    'selectedPackages': $scope.getPackagesForSave(),
                    'child': {
                        'name': data.name,
                        'gender': data.gender,
                        'dateOfBirth': moment(data.dateOfBirth).format(ft.app.datetime.formats.toAPIDate),
                        'placeOfBirth': data.placeOfBirth,
                        'school': data.chosenRegisterOption.id == data.careConfig.types.outOfSchool && data.chosenSchool ? {
                            'id': data.chosenSchool.id,
                            'class': {
                                'id': data.chosenClass ? data.chosenClass : null
                            }
                        } : null,
                        'doctor': {
                            'name': data.doctorName,
                            'contactInformation': [
                                {
                                    'type': ft.app.contactInformation.types.phone,
                                    'content': data.doctorPhone
                                }
                            ]
                        },
                        'insurance': {
                            'organization': data.insuranceOrganizationName,
                            'policyNumber': data.insuranceOrganizationNumber
                        },
                        'contactInformation': data.emergencyNumber ? [
                            {
                                'content': data.emergencyNumber,
                                'isEmergency': true, // altijd true voor noodnummer
                                'type': 'telephone'
                            }
                        ] : null
                    },
                    'contacts': $scope.getContactsForSave(),
                    'start': moment(data.startdate).format(ft.app.datetime.formats.toAPIDate),
                    'answers': $scope.getAnswersForSave(),
                    'remarks': data.comments,
                    'careType': data.chosenRegisterOption.id
                };

                data.saving = true;
                data.saveEnabled = false;
                data.successMessage = '';
                data.errorMessage = '';

                var prm;

                if ($state.current.data && $state.current.data.newRegistration) {
                    prm = DataService.registerChildAsync(obj);
                } else {
                    prm = DataService.registerSiblingAsync(obj);
                }

                prm.then($scope.handleSave, $scope.handleSaveError);
                prm.finally(function () {
                    data.saving = false;
                });
            };

            $scope.handleSave = function (results) {
                var data = $scope.data;

                data.saveEnabled = false;
                data.attempts = 0;

                $rootScope.checkForFormChange = false; // disable form check before navigation

                if ($state.current.data && $state.current.data.newRegistration) {
                    data.successMessage = $translate.instant('NEW-CONTRACT.SAVED-REGISTRATION');
                } else {
                    $state.go('app.family.list', {success: true, child: true});
                }
            };

            $scope.handleSaveError = function (response) {
                var data = $scope.data;

                data.saveEnabled = true;
                data.attempts += 1;

                if (data.attempts > 3) {
                    data.errorMessage = $translate.instant('NEW-CONTRACT.ERROR-CONTINUES');
                } else {
                    data.errorMessage = $translate.instant('NEW-CONTRACT.ERROR-SAVE-CONTRACT');
                }

                if (response && !response.result && response.isReadable) {
                    data.errorMessage += ' ' + response.message;
                }
            };

            $scope.getLocationsForSave = function () {
                var data = $scope.data;
                var result = [];
                var i = 0;

                data.chosenLocations.sort(function (a, b) {
                    return a['preference'] > b['preference'];
                });

                for (i = 0; i < data.chosenLocations.length; i++) {

                    if (data.chosenLocations[i]['id']) {
                        result.push({id: data.chosenLocations[i]['id']});
                    }

                }

                return result;
            };

            $scope.getPackagesForSave = function () {

                var data = $scope.data;
                var result = [];

                var i = 0, t = 0;
                var chosenPackages = data.chosenPackages;
                var registerPackages = data.registerConfig.packages;
                var registerPackage, chosenPackage;
                var time, obj;

                for (i = 0; i < registerPackages.length; i++) {
                    registerPackage = registerPackages[i];

                    var chosenPackage = chosenPackages[registerPackage.id];

                    if (chosenPackage && chosenPackage['chosen']) {

                        obj = null;

                        for (t = 0; t < registerPackage.timeslots.length; t++) {
                            time = chosenPackage[registerPackage.timeslots[t].timeslotId];

                            if (time && time['chosen']) {

                                if (!obj) {
                                    obj = {
                                        id: registerPackage.id,
                                        holidayBalance: chosenPackage['holidayBalance'],
                                        timeslots: []
                                    };

                                    result.push(obj);
                                }

                                obj.timeslots.push({
                                    timeslotId: registerPackage.timeslots[t].timeslotId,
                                    frequency: time['frequency'],
                                    extendedHours: time['expanded'] ? true : false
                                });

                            }
                        }

                    }

                }

                return result;

            };

            $scope.getContactsForSave = function () {
                var data = $scope.data;
                var result = [];

                if ($state.current.data && $state.current.data.newRegistration) {

                    var contactData = data.contact;
                    var parentOneData = data.parentOne;
                    var parentTwoData = data.parentTwo;

                    contactData.address.type = ft.app.contactInformation.categories.home;

                    var contact = {
                        name: contactData.name,
                        type: ft.app.family.types.member,
                        dateOfBirth: moment(contactData.dateOfBirth).format(ft.app.datetime.formats.toAPIDate),
                        gender: contactData.gender,
                        contactInformation: [
                            {
                                type: ft.app.contactInformation.types.phone,
                                category: ft.app.contactInformation.categories.home,
                                content: contactData.phone,
                            },
                            {
                                type: ft.app.contactInformation.types.email,
                                category: ft.app.contactInformation.categories.home,
                                content: contactData.email,
                            }
                        ],
                        roles: [ft.app.family.roles.responsible],
                        addresses: [contactData.address]
                    };
                    result.push(contact);

                    if (data.contactSameAsParent) {
                        contact.roles.push(ft.app.family.roles.parent);
                    } else {

                        parentOneData.address.type = ft.app.contactInformation.categories.home;

                        var parentOne = {
                            name: parentOneData.name,
                            type: ft.app.family.types.member,
                            dateOfBirth: moment(parentOneData.dateOfBirth).format(ft.app.datetime.formats.toAPIDate),
                            gender: parentOneData.gender,
                            roles: [ft.app.family.roles.parent],
                            addresses: [parentOneData.address]
                        };
                        result.push(parentOne);

                    }

                    if (data.fillInSecondParent) {

                        var parentTwo = {
                            name: parentTwoData.name,
                            type: ft.app.family.types.member,
                            dateOfBirth: moment(parentTwoData.dateOfBirth).format(ft.app.datetime.formats.toAPIDate),
                            gender: parentTwoData.gender,
                            roles: [ft.app.family.roles.parent]
                        };

                        if (parentTwoData.email)
                        {
                            parentTwo.contactInformation = [
                                {
                                    type: ft.app.contactInformation.types.email,
                                    category: ft.app.contactInformation.categories.home,
                                    content: parentTwoData.email,
                                }
                            ];
                        }

                        if (data.parentHasSameAddress && data.contactSameAsParent) {
                            parentTwo.addresses = [contactData.address];
                        } else if (data.parentHasSameAddress && !data.contactSameAsParent) {
                            parentTwo.addresses = [parentOneData.address];
                        } else {
                            parentTwoData.address.type = ft.app.contactInformation.categories.home;
                            parentTwo.addresses = [parentTwoData.address];
                        }

                        result.push(parentTwo);
                    }
                } else {
                    var members = data.appData.members;
                    var i = 0;

                    for (i = 0; i < members.length; i++) {
                        result.push({
                            id: members[i].id,
                            roles: members[i].roles
                        });
                    }
                }

                return result;
            };

            $scope.getAnswersForSave = function () {
                var data = $scope.data;
                var i = 0;
                var result = [];

                var registerQuestions = data.registerConfig.questions;
                var questions = data.questions || [];
                var registerQuestion, question;

                if (questions.length == 0) {
                    return result;
                }

                for (i = 0; i < registerQuestions.length; i++) {
                    registerQuestion = registerQuestions[i];
                    question = questions[registerQuestion.questionId];

                    if (question) {
                        result.push({
                            questionId: registerQuestion.questionId,
                            answer: question
                        });
                    }

                }

                return result;
            };

            $timeout($scope.initialize);

        }]);

ft.app.controller('familyAddUserController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$filter', '$translate', 'DataService', 'AppService', 'CheckStateChangeService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $filter, $translate, DataService, AppService, CheckStateChangeService) {

            var initialized = false;

            var getAddressValid = function(addressValid) {
                if (addressValid == null) {
                    addressValid = { postcodeFormat: null, postcodePattern: null };
                }
                return addressValid;
            };

            var getPostcodeFormat = function(countryId, addressValid) {
                if (addressValid == null) {
                    return;
                }
                if (countryId == null || countryId === ''){
                    addressValid.postcodeFormat = null;
                    addressValid.postcodePattern = null;
                }

                const country = $scope.data.countries.find(c => c.countryId === countryId);
                if (country != null) {
                    addressValid.postcodeFormat = country.postcodeFormat;
                    addressValid.postcodePattern = country.postcodePattern;
                }
            };

            $scope.$watch('data.postalAddress.countryId', (newValue) => {
                getPostcodeFormat(newValue, $scope.data.postalAddressValid);
            });

            $scope.$watch('data.invoiceAddress.countryId', (newValue) => {
                getPostcodeFormat(newValue, $scope.data.invoiceAddressValid);
            });

            var getPhoneNumber = $filter('GetPhoneNumber');
            var getMobilePhoneNumber = $filter('GetMobilePhoneNumber');
            var getEmailAddress = $filter('GetEmailAddress');
            var getAddress = $filter('GetAddress');

            CheckStateChangeService.checkFormOnStateChange($scope, 'userForm');

            if (!$scope.data) {
                $scope.data = {};
            }

            var postalAddressValid = getAddressValid($scope.data.postalAddressValid);
            var invoiceAddressValid = getAddressValid($scope.data.invoiceAddressValid);

            ng.extend($scope.data, {
                appData: AppService.data,
                isEditMode: ($stateParams.user),
                sameAsPostalAddress: false,
                name: {
                    lastName: '',
                    middleName: '',
                    firstName: '',
                    initials: ''
                },
                ssn: '',
                dateOfBirth: '',
                gender: '',
                email: '',
                phone: '',
                workphone: '',
                mobile: '',
                postalAddress: {
                    street: '',
                    number: '',
                    zipCode: '',
                    city: '',
                    countryId: '',
                    country: '',
                    type: ft.app.contactInformation.categories.home
                },
                invoiceAddress: {
                    street: '',
                    number: '',
                    zipCode: '',
                    city: '',
                    countryId: '',
                    country: '',
                    type: ft.app.contactInformation.categories.invoice
                },
                countries: [],
                municipalities: [],
                dateOfBirthOpened: false,
                dateOptions: {
                    formatYear: 'yy'
                },
                formats: ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
                format: 'dd-MM-yyyy',
                saving: false,
                errorMessage: '',
                successMessage: '',
                ssnValid: true,
                lastNameValid: true,
                firstNameValid: true,
                initialsValid: true,
                dateOfBirthValid: true,
                genderValid: true,
                postalAddressValid: {
                    postcodeFormat: postalAddressValid.postcodeFormat,
                    postcodePattern: postalAddressValid.postcodePattern,
                    street: true,
                    number: true,
                    zipCode: true,
                    zipCodeFormat: true,
                    city: true,
                    country: true
                },
                invoiceAddressValid: {
                    postcodeFormat: invoiceAddressValid.postcodeFormat,
                    postcodePattern: invoiceAddressValid.postcodePattern,
                    street: true,
                    number: true,
                    zipCode: true,
                    zipCodeFormat: true,
                    city: true,
                    country: true

                },
                attempts: 0
            });

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                $rootScope.$on(ft.app.events.app.config, function () {
                    $scope.data.countries = AppService.data.countries;
                });

                $rootScope.$on(ft.app.events.family.get, function () {
                    $scope.initializeData();
                });

                $scope.data.countries = AppService.data.countries;
                $scope.initializeData();

                initialized = true;

            };

            $scope.initializeData = function () {

                if ($scope.data.user) {
                    var data = $scope.data;
                    var user = data.user;

                    data.name = user.name;
                    data.dateOfBirth = new Date(user.dateOfBirth);
                    data.placeOfBirth = user.placeOfBirth;
                    data.gender = user.gender;
                    data.ssn = user.ssn;
                    data.remarks = user.remarks;

                    var phone = getPhoneNumber(user.contactInformation, ft.app.contactInformation.categories.home);
                    data.phone = phone ? phone.content : '';

                    var workphone = getPhoneNumber(user.contactInformation, ft.app.contactInformation.categories.work);
                    data.workphone = workphone ? workphone.content : '';

                    var mobile = getMobilePhoneNumber(user.contactInformation, ft.app.contactInformation.categories.home);
                    data.mobile = mobile ? mobile.content : '';

                    var email = getEmailAddress(user.contactInformation);
                    data.email = email ? email.content : '';

                    var address = getAddress(user.addresses, ft.app.contactInformation.categories.home);
                    data.postalAddress = address || null;

                    var address = getAddress(user.addresses, ft.app.contactInformation.categories.invoice);
                    data.invoiceAddress = address || null;
                }

            };

            $scope.open = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.data.dateOfBirthOpened = true;
            };

            var isMatch = function(value, pattern){
                if (pattern == null || pattern === '') {
                    return true;
                }
                if (value == null || value === '') {
                    return false;
                }
                const rx = new RegExp(pattern.trim(), 'g');
                return rx.test(value.trim());
            };

            var validateAddress = function(address, addressValid) {
                if (address == null || addressValid == null) {
                    return;
                }

                if (!address.street.trim()) {
                    addressValid.street = false;
                }

                if (!address.number.trim()) {
                    addressValid.number = false;
                }

                if (!address.zipCode.trim() && !isMatch(address.zipCode, addressValid.postcodePattern)) {
                    addressValid.zipCode = false;
                }
                if (addressValid.zipCode && !isMatch(address.zipCode, addressValid.postcodePattern)) {
                    addressValid.zipCodeFormat = false;
                }

                if (!address.city.trim()) {
                    addressValid.city = false;
                }

                if (!address.countryId || (address.countryId && !address.countryId.trim())) {
                    addressValid.country = false;
                }
            };

            $scope.save = function () {

                var data = $scope.data;

                var postalAddress = data.postalAddress;
                var invoiceAddress = data.invoiceAddress;
                var postalAddressValid = getAddressValid(data.postalAddressValid);
                var invoiceAddressValid = getAddressValid(data.invoiceAddressValid);

                data.errorMessage = '';
                data.successMessage = '';

                data.lastNameValid = true;
                data.firstNameValid = true;
                data.initialsValid = true;
                data.dateOfBirthValid = true;
                data.genderValid = true;
                data.ssnValid = true;

                data.postalAddressValid = {
                    postcodeFormat: postalAddressValid.postcodeFormat,
                    postcodePattern: postalAddressValid.postcodePattern,
                    street: true,
                    number: true,
                    zipCode: true,
                    zipCodeFormat: true,
                    city: true,
                    country: true

                };
                postalAddressValid = data.postalAddressValid;

                data.invoiceAddressValid = {
                    postcodeFormat: invoiceAddressValid.postcodeFormat,
                    postcodePattern: invoiceAddressValid.postcodePattern,
                    street: true,
                    number: true,
                    zipCode: true,
                    zipCodeFormat: true,
                    city: true,
                    country: true
                };
                invoiceAddressValid = data.invoiceAddressValid;

                if (!data.name.lastName.trim()) {
                    data.lastNameValid = false;
                }

                if (!data.name.firstName.trim() && !data.name.initials.trim()) {
                    data.firstNameValid = false;
                    data.initialsValid = false;
                }

                if (!data.dateOfBirth) {
                    data.dateOfBirthValid = false;
                } else {
                    if (!moment(data.dateOfBirth).isValid()) {
                        data.dateOfBirthValid = false;
                    }
                }

                if (!data.gender) {
                    data.genderValid = false;
                }

                if (data.appData.ssnIsRequiredAndShown && !data.ssn) {
                    data.ssnValid = false;
                }

                //#region postal

                validateAddress(postalAddress, postalAddressValid);

                //#endregion

                //#region invoice
                if (!data.sameAsPostalAddress) {
                    validateAddress(invoiceAddress, invoiceAddressValid);
                }
                //#endregion

                if (!data.lastNameValid || !data.firstNameValid || !data.initialsValid || !data.dateOfBirthValid || !data.genderValid) {
                    return;
                }

                if (!postalAddressValid.street || !postalAddressValid.number || !postalAddressValid.zipCode || !postalAddressValid.zipCodeFormat || !postalAddressValid.city || !postalAddressValid.country) {
                    return;
                }

                if (data.sameAsPostalAddress) {
                    ng.copy(data.postalAddress, data.invoiceAddress);
                    data.invoiceAddress.type = ft.app.contactInformation.categories.invoice;
                }
                else if (!invoiceAddressValid.street || !invoiceAddressValid.number || !invoiceAddressValid.zipCode || !invoiceAddressValid.zipCodeFormat || !invoiceAddressValid.city || !invoiceAddressValid.country) {
                    return;
                }

                var obj = {
                    name: data.name,
                    type: ft.app.family.types.member,
                    dateOfBirth: moment(data.dateOfBirth).format(ft.app.datetime.formats.toAPIDate),
                    gender: data.gender,
                    contactInformation: [
                        {
                            type: ft.app.contactInformation.types.phone,
                            category: ft.app.contactInformation.categories.home,
                            content: data.phone,
                        },
                        {
                            type: ft.app.contactInformation.types.phone,
                            category: ft.app.contactInformation.categories.work,
                            content: data.workphone,
                        },
                        {
                            type: ft.app.contactInformation.types.mobile,
                            category: ft.app.contactInformation.categories.home,
                            content: data.mobile,
                        }
                    ],
                    addresses: [
                        data.postalAddress,
                        data.invoiceAddress
                    ],
                    remarks: data.remarks
                };

                if (data.user) {
                    obj.id = data.user.id;
                }

                if (data.appData.ssnIsRequiredAndShown) {
                    obj.ssn = data.ssn;
                }

                if (!data.isEditMode) {
                    obj.contactInformation.push({
                        type: ft.app.contactInformation.types.email,
                        category: ft.app.contactInformation.categories.home,
                        content: data.email,
                    });
                }

                $scope.data.saving = true;
                DataService.saveUserAsync(obj).then($scope.handleSave, $scope.handleSaveError);
            };

            $scope.handleSave = function (result) {

                var prm = AppService.getFamilyAsync();
                prm.then(function () {

                    $scope.data.attempts = 0;
                    $scope.data.saving = false;

                    $rootScope.checkForFormChange = false;

                    if ($scope.data.user) {
                        $state.go('app.user.info', {id: $scope.data.user.id, success: true});
                    } else {
                        $state.go('app.family.list', {success: true, parent: true});
                    }

                }, function () {
                });

            };

            $scope.handleSaveError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('PROFILE.ERROR-CONTINUES');
                } else {
                    $scope.data.errorMessage = $translate.instant('PROFILE.ERROR-SAVE-INFO');
                }

                if (response && !response.result && response.isReadable) {
                    $scope.data.errorMessage += ' ' + response.message;
                }

                $scope.data.saving = false;
            };

            $scope.cancel = function () {

                $state.go('app.user.info', {user: $stateParams.user});

            };

            $timeout($scope.initialize);

        }]);

ft.app.controller('familyUploadProfilePictureController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$translate', 'DataService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $translate, DataService) {

            var initialized = false;

            if (!$scope.data) {
                $scope.data = {};
            }

            $scope.data.errorMessage = '';
            $scope.data.successMessage = '';

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                initialized = true;

            };

            $scope.saveImage = function (image) {

                $scope.data.errorMessage = '';
                $scope.data.successMessage = '';

                var data = image.data.replace(/^data:image\/(png|jpg|jpeg);base64,/gi, '');
                var user = $stateParams.user || $stateParams.child;
                var type = $stateParams.user ? ft.app.family.types.member : ft.app.family.types.child;

                DataService.saveProfilePictureAsync(user, type, data).then(
                    function (results) {
                        // done
                        $scope.data.successMessage = $translate.instant('PROFILE-PICTURE.SAVED');
                    },
                    function (response) {
                        // fail
                        $scope.data.errorMessage = !response.result && response.isReadable ? response.message : $translate.instant('PROFILE-PICTURE.ERROR-SAVE-PICTURE');
                    });

            };

            $timeout($scope.initialize);

        }]);
