ft.app.directive('ftFormFieldNumber', function () {
    return {
        restrict: 'EA',
        template: '<input id="{{inputId}}" type="text" ng-model="inputValue" class="form-control" />',
        scope: {
            inputValue: '=',
            inputId: '=',
            decimalChar: '=?',
            allowNegatives: '=?'
        },
        link: function (scope) {

            scope.decimalChar = scope.decimalChar || ',';
            scope.allowNegatives = scope.allowNegatives || false;

            scope.$watch('inputValue', function (newValue, oldValue) {
                var newValue = newValue.replace(/\./gi, scope.decimalChar);

                var arr = String(newValue).split('');
                if (arr.length === 0) {
                    return;
                }
                if (arr.length === 1 && (arr[0] == '-' || arr[0] === scope.decimalChar)) {
                    return;
                }
                if (arr.length === 2 && newValue === '-' + scope.decimalChar) {
                    return;
                }

                var temp = newValue.replace(/,/gi, '.');

                if (isNaN(temp)) {
                    scope.inputValue = oldValue;
                } else {
                    scope.inputValue = newValue;
                }
            });
        }
    };
});

// model value 10:00:00 becomes 10:00 for user
// user value 1000 becomes 10:00:00 for model
ft.app.directive('ftTime', ['$filter', function ($filter) {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) {
                return;
            }

            var toView = function (a) {
                //return $filter('GetTime')(ctrl.$modelValue, true);
                return ctrl.$modelValue;
            };

            var toModel = function (viewValue) {

                var plainNumber = viewValue.replace(/:/g, '');
                var patt = new RegExp(/^(([0-1]?[0-9])|([2][0-3]))([0-5][0-9])$/g);
                var res = patt.test(plainNumber);

                if (!res) {
                    return;
                }

                viewValue = plainNumber.replace(patt, '$1:$4');
                elem.val(viewValue);

                return viewValue + ':00'; // as we want seconds in the model as well

            };

            ctrl.$formatters.unshift(toView);
            ctrl.$parsers.unshift(toModel);
        }
    };
}]);
