export enum AlbumType {
    none = 'none',
    new = 'new'
}

export enum TypeOfAudience {
    parentOfChild = 'parentOfChild',
    parentsOfAllChildren = 'parentsOfAllChildren',
    group = 'group'
}

export interface WriteAudienceGroup {
    name: string;
    audienceType: TypeOfAudience;
    audiences: WriteAudience[];
}

export interface WriteAudience {
    id: string;
    name: string;
    picture: MediaReference;
    isAllAudience: boolean;
    checked: boolean | undefined;
}

export enum MediaType {
    image = 'image',
    video = 'video'
}

export interface MediaReference {
    mediaId: string;
    childId: string;
    type: MediaType;
    poster: string;
    url: string;
    title: string;
}

export interface AlbumsListResponseItem {
    id: string;
    createdOn: string;
    title: string;
    description: string;
    posterUrl: string;
    numberOfMedia: number;
}

export interface AlbumsListResponse {
    items: AlbumsListResponseItem[];
}

export interface MediaListResponse {
    media: MediaListItem[];
}

export interface MediaListItem {
    lastUpdatedUTC: any;
    mediaId: string;
    albumId: string;
    createdOn: string;
    modifiedOn: string;
    media: MediaReference;
    publishedOn: string;
    publishedOnUTC: string;
    publisher: MediaListItemPublisher;
}

export interface MediaListItemPublisher {
    publisherId: string;
    publisherName: string;
}

export interface MediaAlbumResponseItem {
    id: string;
    createdOn: Date;
    title: string;
    description: string;
    posterUrl: string;
}

export interface MediaAlbumsResponse {
    items: MediaAlbumResponseItem[];
}

export interface SelectionModel<T> {
    id: T;
    name: string;
}

export enum ContactInfoType {
    telephone = 'telephone',
    email = 'email',
    mobile = 'mobile',
}

export enum ContactInfoCategory {
    home = 'home',
    work = 'work',
}

export enum AddressType {
    home = 'home',
    invoice = 'invoice',
    visiting = 'visiting',
    postal = 'postal',
}

export interface ContactInfo {
    type: ContactInfoType;
    content: string;
    category: ContactInfoCategory;
    isEmergency: boolean;
}

export interface Address {
    street: string;
    number: string;
    extension: string;
    zipCode: string;
    city: string;
    countryId: string;
    country: string;
    municipalityId: string;
    type: AddressType;
}

export interface ContactBase {
    id: string;
    addresses: Address[];
    contactInformation: ContactInfo[];
}

export interface ContactModel extends ContactBase {
    name: string;
}

export interface Country {
    countryId: string;
    name: string;
    postcodeFormat: string;
    postcodePattern: string;
    code: string;
    default: boolean;
}

export interface SchoolContactModel extends ContactModel {
    class: SelectionModel<string>;
}

export interface Name {
    firstName: string;
    middleName: string;
    lastName: string;
    initials: string;
    fullName: string;
}

export enum PersonType {
    child = 'child',
    parent = 'parent',
    fetcher = 'fetcher',
}

export enum PersonRole {
    responsible = 'responsible',
    parent = 'parent',
    fetcher = 'fetcher',
}

export enum Gender {
    male = 'male',
    female = 'female',
}

export interface Mentor {
    name: string;
}

export interface Insurance {
    organization: string;
    policyNumber: string;
}

export interface PersonModel extends ContactBase {
    name: Name;
    type: PersonType;
    isContact: boolean;
    picture: MediaReference;
    gender: Gender;
    dateOfBirth: Date;
    placeOfBirth: string;
    doctor: ContactModel;
    insurance: Insurance;
    school: SchoolContactModel;
    isCurrentUser: boolean;
    isSick: boolean;
    roles: PersonRole[];
    hasApprovedContract: boolean;
    mentor: Mentor;
    ssn: string;
    hasSSN: boolean;
    hasDoenKids: boolean;
}

export interface SchoolModel extends ContactModel {
    classes: SelectionModel<string>[];
}

export enum RegisterOptionsType {
    daycare = 'daycare',
    playschool = 'playschool',
    outOfSchoolCare = 'outOfSchoolCare'
}

export interface KeyValuePair<TKey, TValue> {
    key: TKey;
    value: TValue;
}

export interface AppSettingsModel {
    invoicingByDebtor: boolean;
    allowSwapDayCustomTime: boolean;
    allowIrregularCustomTime: boolean;
    contractChangeUseCancelDate: boolean;
    telemetryInstrumentationKey: string;
}

/**
 * Timeline implementation
 */
interface BaseTimelineResponse {
    start: Date;
    end: Date;
}

export interface BaseTimelineItem {
    title: string;
    description: string;
    timelineTitle: string;
    on: Date;
}

// Media Timeline
export interface MediaTimelineAlbumPublisher {
    publisherId: string;
    publisherName: string;
}

export interface MediaTimelineItem extends BaseTimelineItem {
    albumId: string;
    numberOfMedia: number;
    posterUrl: string;
    media: MediaReference[];
    publisher: MediaTimelineAlbumPublisher;
}

export interface MediaTimelineResponse extends BaseTimelineResponse {
    albums: MediaTimelineItem[];
}

/**
 * Presence timeline
 *
 * TODO: This call is old and works different than the others, that's why it's not extending from the base response!!
 * RECOMMENDATION: Update the call so it works the same as the others!
 */
export interface PresenceTimelineItem {
    date: Date;
    state: string;
    startTime: string;
    endTime: string;
    type: string;
    childId: string;
}

// Diary timeline
export interface DiaryTimelineItem extends BaseTimelineItem {
    diaryEntryId: string;
    childId: string;
    color: string;
    icon: string;
}

export interface DiaryTimelineResponse extends BaseTimelineResponse {
    diaryEntryItems: DiaryTimelineItem[];
}

export interface StoryTimelineItem extends BaseTimelineItem {
    storyId: string;
    childId: string;
}
export interface StoryTimelineResponse extends BaseTimelineResponse {
    storyEntryItems: StoryTimelineItem[];
}

// Post timeline
export interface PostSender {
    senderId: string;
    senderName: string;
    senderType: string;
    picture: MediaReference;
}

export interface PostTimeLineItem extends BaseTimelineItem {
    postId: string;
    childId: string;
    numberOfReplies: number;
    regarding: string;
    sender: PostSender;
}

export interface PostTimeLineResponse extends BaseTimelineResponse {
    posts: PostTimeLineItem[];
}

export interface StrippenkaartBalanceResponse {
    items: StrippenkaartBalanceModel[];
}

export interface StrippenkaartBalanceModel {
    childId: string;
    packageId: string;
    packageName: string;
    startDate: string;
    originalBalance: number;
    currentBalance: number;
    companyId: string;
}

export enum PlanningDayType {
    normal,
    holiday,
    closed
}

export enum Pakkettype
{
    intern = 'intern',
    tijdblokkeuze = 'tijdblokkeuze',
    onregelmatigMetTijdblokkeuze = 'onregelmatigMetTijdblokkeuze',
    onregelmatigHeleWeek = 'onregelmatigHeleWeek',
    strippenkaart = 'strippenkaart',
}

export interface PlanningBlock
{
    id: string;
    packageType: Pakkettype;
    type: EntityType;
    status: PlanningBlockStatus;
    start: string;
    end: string;
    presence: PlanningBlockPresence;
    timeslotId: string;
    requestedStart: string;
    requestedEnd: string;
    description: string;
    planningId: string;
}

export enum AbsenceType {
    absent = 'absent',
    sick = 'sick'
}

export enum PlanningBlockStatus {
    requestable = 'requestable',
    requested = 'requested',
    approved = 'approved',
    rejected = 'rejected',
    cancelled = 'cancelled'
}

export enum PlanningBlockPresence {
    present = 'present',
    absent = 'absent',
    sick = 'sick',
    holiday = 'holiday',
    traded = 'traded',
    other = 'other'
}

export enum EntityType {
    chiAanvraag = 'chiAanvraag',
    chiAanvraagregel = 'chiAanvraagregel',
    chiAcceskey = 'chiAcceskey',
    chiActiviteitruimte = 'chiActiviteitruimte',
    chiActiviteitsdeelname = 'chiActiviteitsdeelname',
    chiActiviteitsplaatsing = 'chiActiviteitsplaatsing',
    chiActivity = 'chiActivity',
    chiActivityCareType = 'chiActivityCareType',
    chiActivityGroup = 'chiActivityGroup',
    chiActivityinfo = 'chiActivityinfo',
    chiActivityPlanning = 'chiActivityPlanning',
    chiActivitytype = 'chiActivitytype',
    chiAfhaler = 'chiAfhaler',
    chiAfwezig = 'chiAfwezig',
    chiAlbum = 'chiAlbum',
    chiAntwoord = 'chiAntwoord',
    chiAudience = 'chiAudience',
    chiAudiencecorrectie = 'chiAudiencecorrectie',
    chiBelastingdienstexport = 'chiBelastingdienstexport',
    chiBsoopvanglocatie = 'chiBsoopvanglocatie',
    chiBV = 'chiBV',
    chiCaregiver = 'chiCaregiver',
    chiChildlabel = 'chiChildlabel',
    chiChildlabeltype = 'chiChildlabeltype',
    chiClieopBatch = 'chiClieopBatch',
    chiColor = 'chiColor',
    chiComment = 'chiComment',
    chiContract = 'chiContract',
    chiDagactie = 'chiDagactie',
    chiDebtornumber = 'chiDebtornumber',
    chiDiaryEntry = 'chiDiaryEntry',
    chiDiaryEntryType = 'chiDiaryEntryType',
    chiDiarySchedule = 'chiDiarySchedule',
    chiEventtraceinformation = 'chiEventtraceinformation',
    chiFactuurbatch = 'chiFactuurbatch',
    chiFactuurexport = 'chiFactuurexport',
    chiFactuurregel = 'chiFactuurregel',
    chiFactuurrun = 'chiFactuurrun',
    chiFamilyfilter = 'chiFamilyfilter',
    chiFlexibleholiday = 'chiFlexibleholiday',
    chiFlexibleholidaychildren = 'chiFlexibleholidaychildren',
    chiGroep = 'chiGroep',
    chiGroepDetail = 'chiGroepDetail',
    chiGroepnotificatie = 'chiGroepnotificatie',
    chiGroepsluitingsdag = 'chiGroepsluitingsdag',
    chiGrootboek = 'chiGrootboek',
    chiHolidayperpackage = 'chiHolidayperpackage',
    chiHolidayspecial = 'chiHolidayspecial',
    chiHolidaytype = 'chiHolidaytype',
    chiIncassobatch = 'chiIncassobatch',
    chiIncidenteleOpvang = 'chiIncidenteleOpvang',
    chiIncome = 'chiIncome',
    chiIncometable = 'chiIncometable',
    chiIncometablerow = 'chiIncometablerow',
    chiInstantie = 'chiInstantie',
    chiInstantieBetaling = 'chiInstantieBetaling',
    chiIprange = 'chiIprange',
    chiIteminformation = 'chiIteminformation',
    chiKind = 'chiKind',
    chiKindafhaler = 'chiKindafhaler',
    chiKinddocument = 'chiKinddocument',
    chiKostendrager = 'chiKostendrager',
    chiKostenplaats = 'chiKostenplaats',
    chiKot = 'chiKot',
    chiKotline = 'chiKotline',
    chiLand = 'chiLand',
    chiLayoutcontent = 'chiLayoutcontent',
    chiLayoutset = 'chiLayoutset',
    chiLeidsterNotitie = 'chiLeidsterNotitie',
    chiLocatie = 'chiLocatie',
    chiLocatiepakket = 'chiLocatiepakket',
    chiLocatiesluitingsdag = 'chiLocatiesluitingsdag',
    chiLrkpnummer = 'chiLrkpnummer',
    chiMaandfactuur = 'chiMaandfactuur',
    chiMailStatistic = 'chiMailStatistic',
    chiMedewerker = 'chiMedewerker',
    chiMedia = 'chiMedia',
    chiMediaAudience = 'chiMediaAudience',
    chiMediatimeline = 'chiMediatimeline',
    chiMeldingen = 'chiMeldingen',
    chiMunicipality = 'chiMunicipality',
    chiNewsrelease = 'chiNewsrelease',
    chiNewsreleasebv = 'chiNewsreleasebv',
    chiNewsreleaselocation = 'chiNewsreleaselocation',
    chiNotificationtrigger = 'chiNotificationtrigger',
    chiOpeningstijd = 'chiOpeningstijd',
    chiOperationalcapacity = 'chiOperationalcapacity',
    chiOpvangSoort = 'chiOpvangSoort',
    chiOpvangtijd = 'chiOpvangtijd',
    chiOpvangtijdlijn = 'chiOpvangtijdlijn',
    chiOpvangtijdlijncorrectie = 'chiOpvangtijdlijncorrectie',
    chiOrganization = 'chiOrganization',
    chiOuder = 'chiOuder',
    chiOuderNotificatie = 'chiOuderNotificatie',
    chiPakketlabel = 'chiPakketlabel',
    chiPersoneelsplanningsync = 'chiPersoneelsplanningsync',
    chiPersoonrol = 'chiPersoonrol',
    chiPlaatsing = 'chiPlaatsing',
    chiPlanbord = 'chiPlanbord',
    chiPlanning = 'chiPlanning',
    chiPlanningratio = 'chiPlanningratio',
    chiPlanningtijd = 'chiPlanningtijd',
    chiPlanningtijdblok = 'chiPlanningtijdblok',
    chiPlusitem = 'chiPlusitem',
    chiPost = 'chiPost',
    chiPostAudience = 'chiPostAudience',
    chiPostsubscription = 'chiPostsubscription',
    chiPresentie = 'chiPresentie',
    chiProduct = 'chiProduct',
    chiProductAfname = 'chiProductAfname',
    chiProductprijs = 'chiProductprijs',
    chiPublicatie = 'chiPublicatie',
    chiRatio = 'chiRatio',
    chiRatiorekenregel = 'chiRatiorekenregel',
    chiRealTimeBKR = 'chiRealTimeBKR',
    chiRegistration = 'chiRegistration',
    chiRegistrationtype = 'chiRegistrationtype',
    chiRide = 'chiRide',
    chiRideChild = 'chiRideChild',
    chiRuildag = 'chiRuildag',
    chiSchool = 'chiSchool',
    chiSchoolGroup = 'chiSchoolGroup',
    chiSchoolGroupCategory = 'chiSchoolGroupCategory',
    chiSchoolgroupmembership = 'chiSchoolgroupmembership',
    chiSchoolgrouptype = 'chiSchoolgrouptype',
    chiSchoolOpeningTime = 'chiSchoolOpeningTime',
    chiSchoolregion = 'chiSchoolregion',
    chiSchoolterm = 'chiSchoolterm',
    chiSchooltime = 'chiSchooltime',
    chiSchooltimebyweekday = 'chiSchooltimebyweekday',
    chiSchooltimeslot = 'chiSchooltimeslot',
    chiSchooltimeslotset = 'chiSchooltimeslotset',
    chiSchooltimeslottype = 'chiSchooltimeslottype',
    chiSchooltype = 'chiSchooltype',
    chiSchoolyear = 'chiSchoolyear',
    chiSibling = 'chiSibling',
    chiSluitingsdag = 'chiSluitingsdag',
    chiSoortDagactie = 'chiSoortDagactie',
    chiStrippenkaart = 'chiStrippenkaart',
    chiStudiedag = 'chiStudiedag',
    chiStudiedagenPakket = 'chiStudiedagenPakket',
    chiStudiedagRegistratie = 'chiStudiedagRegistratie',
    chiTarief = 'chiTarief',
    chiTariefgroep = 'chiTariefgroep',
    chiTegoeduren = 'chiTegoeduren',
    chiTijdblok = 'chiTijdblok',
    chiUitgevoerdeDagactie = 'chiUitgevoerdeDagactie',
    chiUsermediafavorite = 'chiUsermediafavorite',
    chiVakantieperiode = 'chiVakantieperiode',
    chiVehicle = 'chiVehicle',
    chiVerbruikteuren = 'chiVerbruikteuren',
    chiVgantwoord = 'chiVgantwoord',
    chiVraag = 'chiVraag',
    chiWachtlijstItem = 'chiWachtlijstItem',
    chiWeekregistration = 'chiWeekregistration'
}