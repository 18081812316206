ft.app.controller('swapModalController',
    ['$scope', '$element', 'close', 'item', '$timeout', '$translate', 'DataService', 'CheckStateChangeService', 'AppService',
        function ($scope, $element, close, item, $timeout, $translate, DataService, CheckStateChangeService, AppService) {

            CheckStateChangeService.checkFormOnStateChange($scope, 'swapForm');

            var initialized = false;
            item = item || {};

            $scope.appData = AppService.data;

            $scope.data = {
                attempts: 0,
                saveEnabled: true,
                saving: false,
                comments: '',
                format: 'dd-MM-yyyy',
                now: new Date(),
                from: null,
                till: null,
                fromOpened: false,
                tillOpened: false,
                fromValid: true,
                tillValid: true,
                loadingFrom: false,
                loadingTill: false,
                dateOptions: {
                    formatYear: 'yy'
                },
                selectedTimesFrom: [],
                possibleTimesFrom: [],
                selectedTimesFromValid: true,
                selectedTimesTill: [],
                possibleTimesTill: [],
                selectedTimesTillValid: true,
                fromTimesMessage: null,
                tillTimesMessage: null,
                errorMessage: null,
                successMessage: null
            };

            $scope.isChecked = function (item) {
                if ($scope.data.selectedTimesFrom.indexOf(item) !== -1) {
                    return true;
                }
                if ($scope.data.selectedTimesTill.indexOf(item) !== -1) {
                    return true;
                }
                return false;
            };

            $scope.resetTimes = function (time) {
                time.requestedStart = time.start.substring(0, 5);
                time.requestedEnd = time.end.substring(0, 5);
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                $scope.data.from = item.from ? new Date(item.from) : null;
                $scope.data.till = item.till ? new Date(item.till) : null;

                if ($scope.data.from) {
                    $scope.getPossibleTimes(true);
                }

                if ($scope.data.till) {
                    $scope.getPossibleTimes(false);
                }

                initialized = true;
            };

            $scope.isStudyDay = (time) => time.typeValue === 3463;

            $scope.getPossibleTimes = function (from) {
                var data = $scope.data;
                data.saveEnabled = false;
                data.fromTimesMessage = null;
                data.tillTimesMessage = null;

                if (from) {
                    data.loadingFrom = true;
                    data.possibleTimesFrom.length = 0;

                    const date = moment(data.from).format(ft.app.datetime.formats.toAPIDate);
                    const prm = DataService.getPossibleTimesForDateAsync(item.child, date, false, 4240);
                    prm.then($scope.handleGetPossibleTimesFrom, $scope.handleGetPossibleTimesFromError);
                    prm.finally(function () {
                        data.loadingFrom = false;
                        data.saveEnabled = true;
                    });
                } else {
                    data.loadingTill = true;
                    data.possibleTimesTill.length = 0;

                    const date = moment(data.till).format(ft.app.datetime.formats.toAPIDate);
                    const prm = DataService.getPossibleTimesForDateAsync(item.child, date, false, 4240);
                    prm.then($scope.handleGetPossibleTimesTill, $scope.handleGetPossibleTimesTillError);
                    prm.finally(function () {
                        data.loadingTill = false;
                        data.saveEnabled = true;
                    });
                }
            };

            $scope.handleGetPossibleTimesFrom = function (results) {

                var data = $scope.data;
                data.selectedTimesFrom.length = 0;

                if (results.tradableTimeslots) {

                    results.tradableTimeslots.sort(function (a, b) {

                        var aStart = parseInt(a.start);
                        var bStart = parseInt(b.start);

                        if (aStart > bStart) {
                            return 1;
                        } else if (aStart < bStart) {
                            return -1;
                        } else {
                            return 0;
                        }

                    });

                    // if the modal opened from item and the date is selected, then try to select the item timeslot
                    if (item.fromTimeslotId && ft.dates.compare($scope.data.from, item.from) == 0) {

                        results.tradableTimeslots.forEach(function (itm) {
                            if (itm.planningId == item.fromPlanningId && itm.timeslotId == item.fromTimeslotId) {
                                data.selectedTimesFrom.push(itm);
                            }
                        });

                    } else if (results.tradableTimeslots.length == 1) {
                        data.selectedTimesFrom.push(results.tradableTimeslots[0]);
                    }

                }

                $scope.data.fromTimesMessage = results.tradableTimeslotsMessage || null;
                $scope.data.possibleTimesFrom = results.tradableTimeslots || [];

                $scope.data.possibleTimesFrom.forEach(function (possibleTime) {
                    possibleTime.requestedStart = possibleTime.start.substring(0, 5);
                    possibleTime.requestedEnd = possibleTime.end.substring(0, 5);
                });
            };

            $scope.handleGetPossibleTimesTill = function (results) {

                var data = $scope.data;
                data.selectedTimesTill.length = 0;

                if (results.availableTimeslots) {

                    results.availableTimeslots.sort(function (a, b) {

                        var aStart = parseInt(a.start);
                        var bStart = parseInt(b.start);

                        if (aStart > bStart) {
                            return 1;
                        } else if (aStart < bStart) {
                            return -1;
                        } else {
                            return 0;
                        }

                    });

                    if (results.availableTimeslots.length == 1) {
                        data.selectedTimesTill.push(results.availableTimeslots[0]);
                    }

                }

                $scope.data.tillTimesMessage = results.availableTimeslotsMessage || null;
                $scope.data.possibleTimesTill = results.availableTimeslots || [];

                $scope.data.possibleTimesTill.forEach(function (possibleTime) {
                    possibleTime.requestedStart = possibleTime.start.substring(0, 5);
                    possibleTime.requestedEnd = possibleTime.start.substring(0, 5);
                });
            };

            $scope.handleGetPossibleTimesFromError = function (response) {
                $scope.data.selectedTimesFrom.length = 0;
                $scope.data.possibleTimesFrom.length = 0;
            };

            $scope.handleGetPossibleTimesTillError = function (result) {
                $scope.data.selectedTimesTill.length = 0;
                $scope.data.possibleTimesTill.length = 0;
            };

            $scope.open = function (from, $event) {
                $event.preventDefault();
                $event.stopPropagation();

                if (from) {
                    $scope.data.fromOpened = true;
                } else {
                    $scope.data.tillOpened = true;
                }
            };

            $scope.save = function () {

                var apiFrom, apiTill;
                $scope.data.errorMessage = '';
                $scope.data.successMessage = '';
                $scope.data.fromValid = true;
                $scope.data.tillValid = true;
                $scope.data.selectedTimesFromValid = true;
                $scope.data.selectedTimesTillValid = true;

                // if from is not defined or not valid if defined
                if (!$scope.data.from) {
                    $scope.data.fromValid = false;
                } else {
                    apiFrom = moment($scope.data.from).format(ft.app.datetime.formats.toAPIDate);

                    if (!moment(apiFrom).isValid()) {
                        $scope.data.fromValid = false;
                    }
                }

                // if till is not defined or not valid if defined
                if (!$scope.data.till) {
                    $scope.data.tillValid = false;
                } else {
                    apiTill = moment($scope.data.till).format(ft.app.datetime.formats.toAPIDate);

                    if (!moment(apiTill).isValid()) {
                        $scope.data.tillValid = false;
                    }
                }

                if (!$scope.data.selectedTimesFrom.length) {
                    $scope.data.selectedTimesFromValid = false;
                }

                if (!$scope.data.selectedTimesTill.length) {
                    $scope.data.selectedTimesTillValid = false;
                }

                if ($scope.data.selectedTimesFrom.length != $scope.data.selectedTimesTill.length) {
                    $scope.data.selectedTimesFromValid = false;
                    $scope.data.selectedTimesTillValid = false;
                }

                if (!$scope.data.fromValid || !$scope.data.tillValid || !$scope.data.selectedTimesFromValid || !$scope.data.selectedTimesTillValid) {
                    return;
                }

                apiFrom = moment($scope.data.from).format(ft.app.datetime.formats.toAPIDate);
                apiTill = moment($scope.data.till).format(ft.app.datetime.formats.toAPIDate);

                if (new Date().setHours(0, 0, 0, 0) > new Date(apiTill).setHours(0, 0, 0, 0)) {
                    $scope.data.tillValid = false;
                    return;
                }

                $scope.data.saving = true;
                var prm = DataService.saveSwapAsync(item.child, apiFrom, apiTill, $scope.data.selectedTimesFrom, $scope.data.selectedTimesTill, $scope.data.comments);
                prm.then($scope.handleSave, $scope.handleSaveError);
                prm.finally(function () {
                    $scope.data.saving = false;
                });
            };

            $scope.handleSave = function (results) {
                $scope.data.saveEnabled = false;
                $scope.data.attempts = 0;
                $scope.data.successMessage = $translate.instant('PLANNING-SWAP.SAVED');
                $scope.data.saving = false;
                $scope.close();
            };

            $scope.handleSaveError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('PLANNING-SWAP.ERROR-CONTINUES');
                } else {
                    $scope.data.errorMessage = $translate.instant('PLANNING-SWAP.ERROR-SAVE-SWAP');
                }

                if (response && !response.result && response.isReadable) {
                    $scope.data.saveEnabled = false;
                    $scope.data.errorMessage += ' ' + response.message;
                }
            };

            $scope.close = function () {
                if (!$scope.data.saving) {
                    ($scope.data.saveEnabled) ? close() : close({});
                }
            };

            $scope.cancel = function () {
                if (!$scope.data.saving) {
                    close();
                }
            };

            $timeout($scope.initialize);

        }]);