import * as moment from 'moment';

interface Address {
    street: string;
    number: string;
    zipCode: string;
    city: string;
    countryId: string;
    municipalityId?: string;
}

interface FullName {
    lastName: string;
    middleName: string;
    firstName: string;
    initials: string;
}

interface FullNameValidation {
    initialsValid: boolean;
    firstNameValid: boolean;
    lastNameValid: boolean;
}

interface AddressValidation {
    street: boolean;
    zipCode: boolean;
    zipCodeFormat: boolean;
    city: boolean;
    country: boolean;
    number: boolean;
}

interface ContactValidation extends FullNameValidation {
    dateOfBirthValid: boolean;
    genderValid: boolean;
    addressValid: AddressValidation;
    phoneValid?: boolean;
    emailValid?: boolean;
}

interface ContactInformation {
    gender?: 'male' | 'female';
    dateOfBirth: Date | string;
    phone?: string;
    email?: string;

    name: FullName;
    address: Address;
}

interface PostalCodeFormat {
    postcodeFormat?: string;
    postcodePattern?: string;
}

type Contact = ContactInformation & ContactValidation & { addressValid: PostalCodeFormat };

interface RegistrationForm2 {
    contact: Contact;
    parentOne: Contact;
    parentTwo: Contact;
    contactSameAsParent: boolean;
    fillInSecondParent: boolean;
    parentHasSameAddress: boolean;
}

const isNullOrWhitespace = (value: string) => (value || '').trim() === '';

const validateName = (name: FullName): FullNameValidation => {
    return {
        initialsValid: !isNullOrWhitespace(name?.initials),
        firstNameValid: !isNullOrWhitespace(name?.firstName),
        lastNameValid: !isNullOrWhitespace(name?.lastName)
    };
};


const validateAddress = (address: Address, postcodePattern: string): AddressValidation => {
    return {
        street: !isNullOrWhitespace(address?.street),
        zipCode: !isNullOrWhitespace(address?.zipCode) || isMatch(address?.zipCode, postcodePattern),
        zipCodeFormat: isNullOrWhitespace(address?.zipCode) || isMatch(address?.zipCode, postcodePattern),
        city: !isNullOrWhitespace(address?.city),
        country: !isNullOrWhitespace(address?.countryId),
        number: !isNullOrWhitespace(address?.number)
    };
};

const isAddressCompletelyValid = (validation: AddressValidation) => validation.street && validation.zipCode && validation.zipCodeFormat && validation.city && validation.country && validation.number;
const isNameCompletelyValid = (validation: FullNameValidation) => validation.initialsValid && validation.firstNameValid && validation.lastNameValid;
const isContactCompletelyValid = (validation: ContactValidation, skipPhoneAndEmail: boolean, skipAddress: boolean) => {

    let phoneAndEmailValid = validation.phoneValid && validation.emailValid;
    if (skipPhoneAndEmail) {
        phoneAndEmailValid = true;
    }

    let addressValid = isAddressCompletelyValid(validation.addressValid);
    if (skipAddress) {
        addressValid = true;
    }

    return validation.dateOfBirthValid && validation.genderValid && phoneAndEmailValid && addressValid && isNameCompletelyValid(validation);
};

const validateContactInfo = (info: ContactInformation, postcodePattern: string): ContactValidation => {

    const nameValidation = validateName(info.name);
    const addressValidation = validateAddress(info.address, postcodePattern);

    const result = {
        ...nameValidation,
        addressValid: addressValidation,
        dateOfBirthValid: true,
        genderValid: true,
        phoneValid: true,
        emailValid: true,
    };

    if (info.dateOfBirth == null) {
        result.dateOfBirthValid = false;
    } else {
        const date = moment(info.dateOfBirth);

        if (!date.isValid() || date > moment()) {
            result.dateOfBirthValid = false;
        }
    }

    result.genderValid = !isNullOrWhitespace(info.gender);
    result.phoneValid = !isNullOrWhitespace(info.phone);
    result.emailValid = !isNullOrWhitespace(info.email);

    return result;
};

const addValidationResult = function(contact: Contact, contactValidation: ContactValidation){
    return {
        ...contact,
        ...contactValidation,
        addressValid: {
            ...contactValidation.addressValid,
            postcodePattern: contact.addressValid?.postcodePattern,
            postcodeFormat: contact.addressValid?.postcodeFormat
        }
    };
};

const isMatch = function(value: string, pattern: string){
    if (pattern == null || pattern === '') {
        return true;
    }

    if (value == null || value === '') {
        return false;
    }

    const rx = new RegExp(pattern.trim(), 'g');
    return rx.test(value.trim());
};

export const validateRegistrationForm2 = (form: RegistrationForm2): boolean => {

    const contactValidation = validateContactInfo(form.contact, form.contact.addressValid?.postcodePattern);
    form.contact = addValidationResult(form.contact, contactValidation);

    let isValid = isContactCompletelyValid(form.contact, false, false);

    if (!form.contactSameAsParent) {
        const parentOneValidation = validateContactInfo(form.parentOne, form.parentOne.addressValid?.postcodePattern);
        form.parentOne = addValidationResult(form.parentOne, parentOneValidation);
        isValid = isContactCompletelyValid(form.parentOne, true, false) && isValid;
    }

    if (form.fillInSecondParent) {
        const parentTwoValidation = validateContactInfo(form.parentTwo, form.parentTwo.addressValid?.postcodePattern);
        form.parentTwo = addValidationResult(form.parentTwo, parentTwoValidation);
        isValid = isContactCompletelyValid(form.parentTwo, true, form.parentHasSameAddress) && isValid;
    }

    return isValid;
};