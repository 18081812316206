ft.app.controller('financialOverviewController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$timeout', '$filter', '$translate', 'LoginService', 'DataService', 'AppService',
        function ($rootScope, $scope, $state, $stateParams, $q, $timeout, $filter, $translate, LoginService, DataService, AppService) {

            var initialized = false;
            var offFamily, offDestroy;
            var groupByFilter = $filter('GroupByFilter');
            var documentTypes = ft.app.document.types;
            var invoices, annualStatements;
            var filterChildrenInitial;

            $scope.isLoading = false;
            $scope.appData = AppService.data;
            $scope.annualStatements = [];
            $scope.invoices = [];
            $scope.filterChildren = [];

            $scope.toggleChild = function (child) {

                child.checked = !child.checked;
                filterItems();
            };

            $scope.getDownloadLink = function (item) {

                if (item.loading) {
                    return;
                }

                getDownloadLink(item);
            };

            $scope.resetDownloadLink = function (item) {
                item.loading = false;
                item.downloadUrl = null;
            };

            var getData = function () {

                $scope.isLoading = true;

                var prmFamily = DataService.getFamilyAsync(true);
                prmFamily.then(handleGetFamily, handleGetFamilyError);

                var prmFinancial = DataService.getFinancialOverviewAsync();
                prmFinancial.then(handleGetFinancial, handleGetFinancialError);

                var prm = $q.all([prmFamily, prmFinancial]);
                prm.finally(function () {
                    $scope.filterChildren = filterChildrenInitial.filter(x => x.showOnParentApp || (!x.showOnParentApp && childHasFinancialDoc(x.id)));
                    filterItems();
                    $scope.isLoading = false;
                });

            };

            var handleGetFamily = function (results) {

                var children = results.filter(x => x.type == 'child') || [];

                if (children.length && !children.find(x => x.checked == true)) {
                    children.forEach(function (child) {
                        child.checked = true;
                    });
                }
                filterChildrenInitial = children;
            };

            var handleGetFamilyError = function () {
            };

            var childHasAnnualStatement = function (childId) {
                var statements = annualStatements.filter(x => x.childId === childId) || [];
                return statements.length > 0;
            };

            var childHasInvoices= function (childId) {
                var statements = invoices.filter(x => x.childId === childId) || [];
                return statements.length > 0;
            };

            var childHasFinancialDoc = function(childId) {
                return childHasAnnualStatement(childId) || childHasInvoices(childId);
            };

            var handleGetFinancial = function (results) {
                annualStatements = results.filter(x => x.type == documentTypes.annualStatement);
                invoices = results.filter(x => x.type == documentTypes.invoice);
            };

            var handleGetFinancialError = function (response) {
                $scope.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('FINANCIAL.ERROR-LOADING-DOCUMENTS');
            };

            var getDownloadLink = function (item) {
                item.loading = true;

                var prm = LoginService.refreshLoginAsync();
                prm.then(ft.createDelegate($scope, handleGetDownloadLink, item), ft.createDelegate($scope, handleGetDownloadLinkError, item));
                prm.finally(function () {
                    item.loading = false;
                });
            };

            var handleGetDownloadLink = function (item, results) {
                var tokenData = LoginService.getTokenData();
                item.downloadUrl = prepareItemUrl(item.url) + 'accessToken=' + encodeURIComponent(tokenData.token.value);
            };

            var handleGetDownloadLinkError = function (response) {

                $scope.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('DOCUMENTS.ERROR-GET-DOCUMENT-LINK');

            };

            var handleDestroy = function () {
                if (offFamily) {
                    offFamily();
                }
                if (offDestroy) {
                    offDestroy();
                }
            };

            var initialize = function () {

                if (initialized) {
                    return;
                }

                initializeEvents();
                getData();

                initialized = true;

            };

            var initializeEvents = function () {

                offFamily = $rootScope.$on(ft.app.events.family.get, handleGetFamily);
                offDestroy = $scope.$on('$destroy', handleDestroy);

            };

            var filterItems = function () {

                var appData = $scope.appData;
                var invoicingByDebtor = appData.settings.invoicingByDebtor;
                var selected = $scope.filterChildren.filter(x => x.checked == true);
                var ids = selected.map(function (child) {
                    return child.id;
                });

                var filteredStatements = [];
                var filteredInvoices = invoicingByDebtor ? invoices : [];

                if (ids && ids.length) {

                    filteredStatements = annualStatements.filter(function (item) {
                        return ids.indexOf(item.childId) >= 0;
                    });

                    if (!invoicingByDebtor) {
                        filteredInvoices = invoices.filter(function (item) {
                            return ids.indexOf(item.childId) >= 0;
                        });
                    }
                }

                setChildProperty(filteredStatements);
                $scope.annualStatements = filteredStatements;

                // don't bind for the second time when filtering is not possible due to setting
                if (!invoicingByDebtor || (invoicingByDebtor && !$scope.invoices.length)) {

                    if (!invoicingByDebtor) {
                        setChildProperty(filteredInvoices);
                    }

                    $scope.invoices = filteredInvoices;
                }
            };

            var setChildProperty = function (items) {

                if (!$scope.filterChildren || !$scope.filterChildren.length) {
                    return;
                }

                var filtered = items.filter(function (item) {
                    return item.childId;
                });

                filtered.forEach(function (item) {
                    if (item.childId) {
                        item.child = $scope.filterChildren.find(x => x.id == item.childId);
                    }
                });
            };

            var prepareItemUrl = function (url) {
                return url + (url.indexOf('?') >= 0 ? '&' : '?');
            };

            var sortDocuments = function (a, b) {
                var aDate = new Date(a.date);
                var bDate = new Date(a.date);

                return aDate > bDate;
            };


            $timeout(initialize);

        }]);
