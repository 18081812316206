import { ft } from '@js/definitions';
import moment, { Moment, Duration } from 'moment';
import { AbsenceType } from '@js/models';

export interface Params {
    childId: string;
    from: Moment;
    till: Moment;
    fromTime: Duration;
    tillTime: Duration;
    presence: AbsenceType;
}

export class PlanningModalService {
    public static $inject = ['ModalService'];

    private modalService: any;

    public constructor(modalService: any) {
        this.modalService = modalService;
    }

    private parseDate(date?: string, defaultValue?: Moment): Moment {
        if (date == null) {
            if (defaultValue == null) {
                return moment();
            }

            return defaultValue;
        }

        return moment(date);
    }

    private parseDuration(time?: string, defaultValue?: Duration): Duration {
        if (time == null) {
            if (defaultValue == null) {
                return moment.duration();
            }

            return defaultValue;
        }

        return moment.duration(time);
    }

    public openAbsentModal($event: MouseEvent, childId: string, from?: string, till?: string, fromTime?: string, tillTime?: string, presence?: AbsenceType) {
        let parsedFrom = this.parseDate(from);
        let parsedTill = this.parseDate(till);

        if (parsedTill.isBefore(parsedFrom)) {
            parsedTill = parsedFrom.clone();
        }

        let parsedFromTime = this.parseDuration(fromTime, moment.duration('00:00'));
        let parsedTillTime = this.parseDuration(tillTime, moment.duration(1, 'day').subtract(1, 'minute'));

        return this.modalService.showModal({
            elm: $event.currentTarget || $event.target,
            templateUrl: 'planning/absent-modal',
            controller: 'absentModalController',
            inputs: {
                params: {
                    childId: childId,
                    from: parsedFrom,
                    till: parsedTill,
                    fromTime: parsedFromTime,
                    tillTime: parsedTillTime,
                    presence
                } as Params
            }
        });
    }

    public openPresentModal($event: MouseEvent, childId: string, from?: string) {
        return this.modalService.showModal({
            elm: $event.currentTarget || $event.target,
            templateUrl: 'planning/present-modal',
            controller: 'presentModalController',
            inputs: {
                item: {
                    child: childId,
                    from: from || moment().format(ft.app.datetime.formats.toAPIDate)
                }
            }
        });
    };

    public openSwapModal($event: MouseEvent, childId: string, from?: string, till?: string, fromPlanningId?: string, fromTimeslotId?: string) {
        if (!from && !till) {
            from = moment().format(ft.app.datetime.formats.toAPIDate);
        }

        return this.modalService.showModal({
            elm: $event.currentTarget || $event.target,
            templateUrl: 'planning/swap-modal',
            controller: 'swapModalController',
            inputs: {
                item: {
                    child: childId,
                    from: from || null,
                    till: till || null,
                    fromPlanningId: fromPlanningId || null,
                    fromTimeslotId: fromTimeslotId || null
                }
            }
        });
    };

    public openIncidentalModal($event: MouseEvent, childId: string, from?: string) {
        return this.modalService.showModal({
            elm: $event.currentTarget || $event.target,
            templateUrl: 'planning/incidental-modal',
            controller: 'incidentalModalController',
            inputs: {
                item: {
                    child: childId,
                    from: from
                }
            }
        });
    };

    public openRequestModal($event: MouseEvent, childId: string, items, cancellation) {
        return this.modalService.showModal({
            elm: $event.currentTarget || $event.target,
            templateUrl: 'planning/request-modal',
            controller: 'requestModalController',
            inputs: {
                modalData: {
                    childId: childId,
                    cancellation: cancellation,
                    items: items
                }
            }
        });
    };
}

ft.app.service('PlanningModalService', PlanningModalService);