import { ft } from '@js/definitions';
import moment from 'moment';
import { IScope, IQService } from 'angular';
import { AppService, IAppData } from '../../services/FT.app.services.appservice';
import { DataService } from '../../services/FT.app.services.dataservice';
import { PersonModel, StrippenkaartBalanceResponse, StrippenkaartBalanceModel, PlanningDayType, PlanningBlock, EntityType } from '@js/models';

interface Item {
    date: string;
    dayType: PlanningDayType;
    block: PlanningBlock;
}

interface ModalData {
    childId: string;
    child: PersonModel;
    irregularHoursSummaries: any[];
    totalCreditHours: number;
    useCreditHours: boolean;
    creditHoursApplicable: boolean;
    isLoading: boolean;
    holidayBalanceValid: boolean;
    holidayBalance: number;
    requestedBalance: number;
    stripCardBalanceValid: boolean;
    stripCardBalances: StrippenkaartBalanceModel[];
    saving: boolean;
    useBalanceAs: boolean;
    saveEnabled: boolean;
    attempts: number;
    showHolidayBalance: boolean;
    showStripCardBalance: boolean;
    cancellation: boolean;
    items: Item[];

    errorMessage?: string;
    successMessage?: string;
}

interface Scope extends IScope {
    isLoading: boolean;
    data: ModalData;
    appData: IAppData;
    showIrregularTimeFields: boolean;
    initialize: () => void;
    validateHolidayBalance();
    getIrregularHoursSummary();
    resetTimes(block: PlanningBlock);
    handleGetIrregularHoursSummary(results: any);
    handleGetIrregularHoursSummaryError(response: { result: any; isReadable: any; message: string });
    filterStripCardItem(filterStripCardItem: any);
    save: () => void;
    handleSaveCancellation();
    handleSaveCancellationError(response: { result: any; isReadable: any; message: string });
    handleSave();
    handleSaveError(response: { result: any; isReadable: any; message: string });
    close();
    saveHolidayBalance: () => void;
    handleSaveHolidayBalance();
    handleSaveHolidayBalanceError(response: { result: any; isReadable: any; message: string });
    saveStripCardBalance: (balance: any) => void;
    cancel: () => void;
    filterItem: (item: any) => boolean;
    filterHolidayItem(item: any);
}

ft.app.controller('requestModalController',
    ['$scope', 'close', 'modalData', '$filter', '$timeout', '$q', '$translate', 'AppService', 'DataService',
        function ($scope: Scope, close: (result?: any, delay?: number) => void, modalData: ModalData, $filter, $timeout, $q: IQService, $translate, AppService: AppService, DataService: DataService) {

            // modalData consist of child id and boolean for cancellation
            // modalData also consist of 1 or more items

            var groupBy = $filter('GroupByFilter');

            var initialized = false;
            var holidayBalanceTypes = ft.app.holidayBalance.type;
            var startDate;

            $scope.appData = AppService.data;
            $scope.data = {
                childId: '',
                isLoading: false,
                attempts: 0,
                saveEnabled: true,
                saving: false,
                child: null,
                cancellation: modalData.cancellation,
                items: modalData.items,
                showHolidayBalance: false,
                holidayBalance: null,
                requestedBalance: null,
                showStripCardBalance: false,
                stripCardBalances: null,
                useBalanceAs: holidayBalanceTypes.requestNew,
                holidayBalanceValid: true,
                stripCardBalanceValid: true,
                irregularHoursSummaries: [],
                creditHoursApplicable: false,
                useCreditHours: true,
                totalCreditHours: 0
            };

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                var data = $scope.data;

                data.child = AppService.getUser(modalData.childId);

                if ($scope.appData.useHolidayBalance && hasHolidayRequestables()) {
                    $scope.validateHolidayBalance();
                }

                $scope.getIrregularHoursSummary();

                if (hasStripCardRequestables()) {
                    getStripCardBalance();
                }

                data.items.forEach(function (item) {
                    $scope.resetTimes(item.block);
                });

                $scope.showIrregularTimeFields = AppService.data.showIrregularTimeFields && $scope.data.items.every(x => x.block.type !== EntityType.chiStudiedag);

                initialized = true;
            };

            $scope.resetTimes = function (block) {

                block.requestedStart = block.start.substr(0, 5);
                block.requestedEnd = block.end.substr(0, 5);

            };

            $scope.getIrregularHoursSummary = function () {

                var data = $scope.data;
                var months = groupBy(data.items, 'date', function (val) {
                    return val.substr(0, 7) + '-01'; // YYYY-mm-01
                });

                var firstItem = data.items[0];
                var prms = months.map(x => DataService.getIrregularHoursSummaryAsync(data.child.id, x.title,firstItem.block.planningId));


                prms = $q.all(prms);
                prms.then($scope.handleGetIrregularHoursSummary, $scope.handleGetIrregularHoursSummaryError);
            };

            $scope.handleGetIrregularHoursSummary = function (results) {
                var data = $scope.data;
                data.irregularHoursSummaries = results;

                data.irregularHoursSummaries.forEach(function (summary) {
                    data.totalCreditHours = summary.creditHours;
                    data.creditHoursApplicable = summary.creditHoursApplicable;
                });
            };

            $scope.handleGetIrregularHoursSummaryError = function () {
            };

            $scope.validateHolidayBalance = function () {

                var itm, requestables = [];

                modalData.items.forEach(function (item) {

                    itm = {
                        requestableId: item.block.id || item.block.planningId,
                        requestableType: item.block.type,
                        date: item.date,
                        start: item.block.start,
                        end: item.block.end,
                        timeslotId: item.block.timeslotId
                    };

                    requestables.push(itm);

                });

                if (requestables.length) {

                    $scope.isLoading = true;

                    var prm = DataService.validateRequestAsync($scope.data.child.id, requestables);
                    prm.then(handleValidateHolidayBalance, handleValidateHolidayBalanceError);
                    prm.finally(function () {
                        $scope.data.isLoading = false;
                    });

                }

            };

            var handleValidateHolidayBalance = function (result) {

                if (result) {

                    if (result.requestables && result.requestables.length) {
                        getHolidayBalance();

                        var denied = result.requestables.find(x => x.validation.isSuccess == false);

                        if (denied) {
                            $scope.data.holidayBalanceValid = false;
                            startDate = denied.date; // first day of the new holiday balance term
                        } else {
                            $scope.data.holidayBalanceValid = true;
                        }

                    }

                }

            };

            var handleValidateHolidayBalanceError = function (response) {

                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('PLANNING-REQUEST.ERROR-VALIDATE-HOLIDAY-BALANCE');

                if ($scope.appData.useHolidayBalance) {
                    $scope.data.holidayBalanceValid = false;
                }

            };

            var getHolidayBalance = function () {

                $scope.isLoading = true;

                var date = modalData.items.length ? modalData.items[0].date : new Date();
                var mDate = moment(date).format(ft.app.datetime.formats.toAPIDate);

                var prm = DataService.getHolidayBalanceAsync($scope.data.child.id, mDate);
                prm.then(handleGetHolidayBalance, handleGetHolidayBalanceError);
                prm.finally(function () {
                    $scope.data.isLoading = false;
                });

            };

            var handleGetHolidayBalance = function (result) {

                if (result) {
                    var availableBalances = result.availableBalances;
                    $scope.data.holidayBalance = result;
                    $scope.data.requestedBalance = availableBalances && availableBalances.length ? availableBalances[0].id : null;
                }

            };

            var handleGetHolidayBalanceError = function (response) {

                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('PLANNING-REQUEST.ERROR-GET-HOLIDAY-BALANCE');

            };

            var getStripCardBalance = function () {

                $scope.isLoading = true;

                var prm = DataService.getStripCardBalanceAsync($scope.data.child.id);
                prm.then(handleGetStripCardBalance, handleGetStripCardBalanceError);
                prm.finally(function () {
                    $scope.data.isLoading = false;
                });

            };

            var handleGetStripCardBalance = function (result: StrippenkaartBalanceResponse) {

                var data = $scope.data;
                data.stripCardBalanceValid = true;
                data.stripCardBalances = result.items;

                if (!data.stripCardBalances || !data.stripCardBalances.length) {
                    return;
                }

                var blocks = data.items.filter($scope.filterStripCardItem).map(function (item) {
                    return item.block;
                });
                var block, balances, balance;
                var i = 0;

                while (data.stripCardBalanceValid && i < blocks.length) {

                    block = blocks[i];
                    balances = data.stripCardBalances.filter(function (item) {
                        return item.companyId == block.companyId && item.packageId == block.packageId;
                    });

                    if (balances && balances.length) {
                        balance = balances[0];

                        if (!balance.currentBalance) {
                            data.stripCardBalanceValid = false;
                        }
                    }

                    i++;
                }
            };

            var handleGetStripCardBalanceError = function (response) {

                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : $translate.instant('STRIP-CARD.ERROR-GET-BALANCE');

            };

            $scope.save = function () {

                var valid = true;
                var data = $scope.data;
                data.errorMessage = '';
                data.successMessage = '';

                if (data.cancellation) {

                    var item = modalData.items[0];
                    const prm = DataService.cancelRequestAsync(data.child.id, item.block.id || item.block.planningId, item.block.type, item.date, item.block.start, item.block.end, data.useCreditHours);
                    prm.then($scope.handleSaveCancellation, $scope.handleSaveCancellationError);
                    prm.finally(function () {
                        $scope.data.saving = false;
                    });

                } else {

                    var itm, requestables = [];

                    modalData.items.forEach(function (item) {

                        itm = {

                            requestableId: item.block.id || item.block.planningId,
                            requestableType: item.block.type,
                            date: item.date,
                            start: item.block.start,
                            end: item.block.end,
                            timeslotId: item.block.timeslotId

                        };

                        if ($scope.appData.showIrregularTimeFields) {

                            if (!item.block.requestedStart || !item.block.requestedEnd) {
                                valid = false;
                            } else {

                                // check if format is valid and if requestedStart is before requestedEnd

                                var regTime = /^(([0-1]?[0-9])|([2][0-3])):([0-5][0-9])(:[0-5][0-9])?$/;
                                let start = item.block.requestedStart;
                                let end = item.block.requestedEnd;

                                if (!item.block.requestedStart.match(regTime) || !item.block.requestedEnd.match(regTime)) {
                                    valid = false;
                                } else {
                                    var apiDate = moment(item.date).format(ft.app.datetime.formats.toAPIDate);
                                    start = apiDate + ' ' + item.block.requestedStart;
                                    end = apiDate + ' ' + item.block.requestedEnd;

                                    if (new Date(start) > new Date(end)) {
                                        valid = false;
                                    }
                                }

                                if (valid) {
                                    itm.requestedStart = item.block.requestedStart;
                                    itm.requestedEnd = item.block.requestedEnd;
                                }
                            }
                        }

                        requestables.push(itm);

                    });

                    if (!valid) {
                        data.errorMessage = $translate.instant('PLANNING-REQUEST.ERROR-TIMES-ARE-REQUIRED');
                        return;
                    }

                    var asIncidental;

                    if ($scope.appData.useHolidayBalance) {
                        asIncidental = data.holidayBalance && data.useBalanceAs == holidayBalanceTypes.incidental;
                    }

                    data.saving = true;
                    var prm = DataService.saveRequestAsync(data.child.id, requestables, asIncidental, data.useCreditHours);
                    prm.then($scope.handleSave, $scope.handleSaveError);
                    prm.finally(function () {
                        data.saving = false;
                        $scope.close();
                    });

                }

            };

            $scope.handleSave = function () {
                $scope.data.saveEnabled = false;
                $scope.data.attempts = 0;
                $scope.data.successMessage = $translate.instant('PLANNING-REQUEST.SAVED-REQUEST');
            };

            $scope.handleSaveError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('PLANNING-REQUEST.ERROR-CONTINUES-REQUEST');
                } else {
                    $scope.data.errorMessage = $translate.instant('PLANNING-REQUEST.ERROR-SAVE-REQUEST');
                }

                if (response && !response.result && response.isReadable) {
                    $scope.data.errorMessage += ' ' + response.message;
                }
            };

            $scope.handleSaveCancellation = function () {
                $scope.data.saveEnabled = false;
                $scope.data.attempts = 0;
                $scope.data.successMessage = $translate.instant('PLANNING-REQUEST.SAVED-CANCELLATION');
            };

            $scope.handleSaveCancellationError = function (response) {
                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('PLANNING-REQUEST.ERROR-CONTINUES-CANCELLATION');
                } else {
                    $scope.data.errorMessage = $translate.instant('PLANNING-REQUEST.ERROR-SAVE-CANCELLATION');
                }

                if (response && !response.result && response.isReadable) {
                    $scope.data.errorMessage += ' ' + response.message;
                }
            };

            $scope.saveHolidayBalance = function () {

                var data = $scope.data;
                data.errorMessage = '';
                data.successMessage = '';
                data.saving = true;

                var prm = DataService.saveHolidayBalanceAsync(data.child.id, startDate, data.requestedBalance);
                prm.then($scope.handleSaveHolidayBalance, $scope.handleSaveHolidayBalanceError);
                prm.finally(function () {
                    data.saving = false;
                });

            };

            $scope.handleSaveHolidayBalance = function () {

                $scope.data.successMessage = $translate.instant('PLANNING-REQUEST.SAVED-HOLIDAY-BALANCE');
                getHolidayBalance();

            };

            $scope.handleSaveHolidayBalanceError = function (response) {

                $scope.data.attempts += 1;

                if ($scope.data.attempts > 3) {
                    $scope.data.errorMessage = $translate.instant('PLANNING-REQUEST.ERROR-CONTINUES-HOLIDAY-BALANCE');
                } else {
                    $scope.data.errorMessage = $translate.instant('PLANNING-REQUEST.ERROR-SAVE-HOLIDAY-BALANCE');
                }

                if (response && !response.result && response.isReadable) {
                    $scope.data.errorMessage += ' ' + response.message;
                }

            };

            $scope.saveStripCardBalance = function (balance) {

                var data = $scope.data;
                data.errorMessage = '';
                data.successMessage = '';
                data.saving = true;

                var prm = DataService.saveStripCardBalanceAsync(data.child.id, balance.companyId, balance.packageId);
                prm.then(handleSaveStripCardBalance, handleSaveStripCardBalanceError);
                prm.finally(function () {
                    data.saving = false;
                });

            };

            var handleSaveStripCardBalance = function (results) {

                $scope.data.successMessage = $translate.instant('STRIP-CARD.SAVED-BALANCE');
                getStripCardBalance();

            };

            var handleSaveStripCardBalanceError = function (response) {

                var data = $scope.data;

                data.attempts += 1;

                if (data.attempts > 3) {
                    data.errorMessage = $translate.instant('STRIP-CARD.ERROR-CONTINUES-BALANCE');
                } else {
                    data.errorMessage = $translate.instant('STRIP-CARD.ERROR-SAVE-BALANCE');
                }

                if (response && !response.result && response.isReadable) {
                    data.errorMessage += ' ' + response.message;
                }

            };


            $scope.close = function () {
                if (!$scope.data.saving) {
                    ($scope.data.saveEnabled) ? close() : close({});
                }
            };

            $scope.cancel = function () {
                if (!$scope.data.saving) {
                    close();
                }
            };

            $scope.filterItem = function (item) {

                // when not everything else

                return !$scope.filterHolidayItem(item) && !$scope.filterStripCardItem(item);
            };

            $scope.filterHolidayItem = function (item) {

                // when holiday balance setting is true and item is requestable in a holiday and not a 'strippenkaart' package

                var planningConfig = ft.app.planning;
                var packageTypes = ft.app.contract.packages;

                return $scope.appData.useHolidayBalance && item.block.status == planningConfig.states.requestable && item.dayType == planningConfig.days.holiday && item.block.packageType != packageTypes.stripCard;
            };

            $scope.filterStripCardItem = function (item) {

                // when is requestable and a 'strippenkaart' package

                var planningConfig = ft.app.planning;
                var packageTypes = ft.app.contract.packages;

                return item.block.status == planningConfig.states.requestable && item.block.packageType == packageTypes.stripCard;
            };

            var hasHolidayRequestables = function () {

                var planningConfig = ft.app.planning;
                $scope.data.showHolidayBalance = false;

                if (modalData.items && modalData.items.length) {

                    var i = 0;
                    var item;

                    while ($scope.data.showHolidayBalance == false && i < modalData.items.length) {

                        item = modalData.items[i];

                        if (item.dayType == planningConfig.days.holiday && item.block.status == planningConfig.states.requestable && item.block.packageType != ft.app.contract.packages.stripCard) {
                            $scope.data.showHolidayBalance = true;
                        }

                        i++;
                    }

                }

                return $scope.data.showHolidayBalance;
            };

            var hasStripCardRequestables = function () {

                var i = 0;
                var data = $scope.data;

                if (!data.items || !data.items.length) {
                    return;
                }

                while (data.showStripCardBalance == false && i < data.items.length) {

                    var item = data.items[0];

                    if (item.block && item.block.packageType == ft.app.contract.packages.stripCard) {
                        data.showStripCardBalance = true;
                    }

                    i++;

                }

                return data.showStripCardBalance;

            };

            $timeout($scope.initialize);

        }]);