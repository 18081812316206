ft.app.filter('urlencode', function () {
    return function (input) {
        return window.encodeURIComponent(input);
    };
});

ft.app.filter('newlines', function () {
    return function (text) {
        if (!text) {
            return;
        }

        return text.split(/\n/g);
    };
});