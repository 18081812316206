import { ft } from '@js/definitions';
import { PersonModel } from '@js/models';
import { AppService } from '@js/services/FT.app.services.appservice';
import { DataService } from '@js/services/FT.app.services.dataservice';
import { IQService, IScope } from 'angular';
import { IStateParamsService } from 'angular-ui-router';

interface Scope extends IScope {
    openWaitinglist: any;
    isLoading: boolean;
    data: {
        errorMessage: string;
        child: PersonModel;
        waitinglistItems: any[];
        contractRequests: any[];
        planningRequests: any[];
        hasContractRequests: boolean;
        hasPlanningRequests: boolean;
    };
}

class ChildRequestsController {
    public static $inject: string[] = ['$scope', '$stateParams', '$q', '$translate', 'DataService', 'AppService', 'ModalService'];

    private $scope: Scope;
    private $stateParams: IStateParamsService;
    private $q: IQService;
    private $translate: angular.translate.ITranslateService;
    private dataService: DataService;
    private appService: AppService;
    private modalService: any;

    constructor($scope: Scope, $stateParams: IStateParamsService, $q: IQService, $translate: angular.translate.ITranslateService, dataService: DataService, appService: AppService, modalService: any) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$q = $q;
        this.$translate = $translate;
        this.dataService = dataService;
        this.appService = appService;
        this.modalService = modalService;

        this.$scope.openWaitinglist = this.openWaitinglist.bind(this);

        this.initialize();
    }

    private initialize() {
        this.$scope.isLoading = false;
        this.$scope.data = {
            errorMessage: '',
            child: this.appService.getUser(this.$stateParams.child),
            waitinglistItems: [],
            contractRequests: [],
            planningRequests: [],
            hasContractRequests: true,
            hasPlanningRequests: true
        };

        this.getData();
    }

    private async openWaitinglist(evt: InputEvent): Promise<void> {
        const modal = await this.modalService.showModal({
            elm: evt.currentTarget || evt.target,
            style: 'waiting-modal-settings',
            templateUrl: 'requests/waitinglist-modal',
            controller: 'waitinglistModalController',
            inputs: {
                childId: this.$stateParams.child,
                items: this.$scope.data.waitinglistItems
            }
        });

        modal.close.then(() => this.getData());
    }

    private async getData(): Promise<void> {
        this.$scope.data = { } as Scope['data'];

        this.$scope.data.errorMessage = '';
        this.$scope.isLoading = true;

        var child = this.$stateParams.child as string;

        try {
            const [requests, waitinglist] = await this.$q.all([
                this.dataService.getRequestsAsync(child),
                this.dataService.getWaitinglistAsync(child)
            ]);

            this.$scope.data.hasContractRequests = requests?.contractRequests?.length > 0;
            this.$scope.data.contractRequests = requests?.contractRequests ?? [];

            this.$scope.data.hasPlanningRequests = requests?.planningRequests?.length > 0;
            this.$scope.data.planningRequests = requests?.planningRequests ?? [];

            this.$scope.data.waitinglistItems = waitinglist || [];
        } catch {
            this.$scope.data.errorMessage = this.$translate.instant('REQUESTS.ERROR-GET-REQUESTS');
        } finally {
            this.$scope.isLoading = false;
        }

        this.$scope.$apply();
    }

}


ft.app.controller('childRequestsController', ChildRequestsController);