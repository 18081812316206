ft.app.controller('appContentController',
    ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'AppService',
        function ($rootScope, $scope, $state, $stateParams, $timeout, AppService) {

            var initialized = false;
            var offStateChangeSuccess;

            $scope.isLoading = false;
            $scope.menuIsOpen = false;
            $scope.appData = AppService.data;
            $scope.data = {
                errorMessage: ''
            };

            //#region init

            var initialize = function () {

                if (initialized) {
                    return;
                }

                offStateChangeSuccess = $rootScope.$on('$stateChangeSuccess', handleStateChangeSuccess);
                $scope.$on('$destroy', handleDestroy);

                handleStateChangeSuccess();
                getData();

                initialized = true;

            };

            //#endregion

            //#region screen handlers

            $scope.toggleMenu = function () {
                $scope.menuIsOpen = !$scope.menuIsOpen;
            };

            $scope.clearErrorMessage = function () {
                $scope.data.errorMessage = '';
            };

            //#endregion

            //#region get data

            var getData = function () {

                $scope.isLoading = true;

                var prm = AppService.getFamilyAsync();
                prm.then(handleGetFamily, handleGetFamilyError);
                prm.finally(function () {
                    $scope.isLoading = false;
                });

            };

            //#endregion

            //#region handlers

            var handleGetFamily = function (results) {
            };

            var handleGetFamilyError = function (response) {
                $scope.data.errorMessage = response && !response.result && response.isReadable ? response.message : 'Ophalen van de familiegegevens is mislukt.';
            };

            var handleStateChangeSuccess = function () {

                if ($state.current && $state.current.pageStyle) {
                    $scope.pageStyle = $state.current.pageStyle;
                } else {
                    $scope.pageStyle = null;
                }

            };

            var handleDestroy = function () {
                if (offStateChangeSuccess) {
                    offStateChangeSuccess();
                }
            };

            //#endregion

            //#region helpers

            //#endregion

            $timeout(initialize);
        }]);