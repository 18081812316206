ft.app.controller('contractEditModalController',
    ['$rootScope', '$scope', '$stateParams', '$element', 'close', 'item', '$timeout', '$filter', '$translate', 'DataService', 'AppService', 'CheckStateChangeService',
        function ($rootScope, $scope, $stateParams, $element, close, item, $timeout, $filter, $translate, DataService, AppService, CheckStateChangeService) {

            var groupByFilter = $filter('GroupByFilter');
            var initialized = false;

            $rootScope.checkForFormChange = true;
            CheckStateChangeService.checkFormOnStateChange($scope, 'childForm');

            var loadingKeyPackages = 'packages';
            var loadingKeyLocations = 'locations';

            var addLoading = function (key) {
                if ($scope.data.loading.indexOf(key) < 0) {
                    $scope.data.loading.push(key);
                }
            };

            var removeLoading = function (key) {
                var idx = $scope.data.loading.indexOf(key);
                if (idx >= 0) {
                    $scope.data.loading.splice(idx, 1);
                }
            };

            $scope.data = {
                reduceDays: false,
                appData: AppService.data,
                careConfig: ft.app.care,
                item: item,
                loading: [],
                maxLocations: 3,
                currentStep: parseInt($stateParams.step) || 0,
                steps: [
                    {
                        key: 'packages',
                        title: $translate.instant('NEW-CONTRACT.STEP-PACKAGES'),
                        description: 'pp-screen-contract-packages'
                    },
                    {
                        key: 'locations',
                        title: $translate.instant('NEW-CONTRACT.STEP-LOCATIONS'),
                        description: 'pp-screen-contract-locations'
                    },
                    {
                        key: 'comments',
                        title: $translate.instant('NEW-CONTRACT.STEP-COMMENTS'),
                        description: 'pp-screen-contract-comments'
                    }
                ],
                now: new Date(),
                registrationId: null,
                dateOfBirth: item.child.dateOfBirth,
                startdate: null,
                registerConfig: null,
                mergedPackages: [],
                locations: [],
                chosenRegisterOption: null,
                chosenLocations: [],
                chosenPackages: [],
                chosenSchool: null,
                chosenClass: null,
                configQuestions: null,
                questions: null,
                comments: '',
                startdateOpened: false,
                dateOptions: {
                    initDate: moment().add(1, 'day').toDate(),
                    minDate: moment().add(1, 'day').toDate(),
                    formatYear: 'yy'
                },
                formats: ['dd-MM-yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
                format: 'dd-MM-yyyy',
                startdateValid: true,
                chosenRegisterOptionValid: true,
                chosenSchoolValid: true,
                chosenPackagesValid: true,
                chosenLocationsValid: true,
                questionsValid: true,
                successMessage: '',
                errorMessage: '',
                saveEnabled: true,
                saving: false,
                attempts: 0
            };

            $scope.$watch('data.reduceDays', function (newVal, oldVal) {
                var datePickerDate = item.contract && AppService.data.settings.contractChangeUseCancelDate ?
                    moment(item.contract.cancelDate).toDate() :
                    moment().add(1, 'day').toDate();

                if (newVal === false) {
                    datePickerDate = moment().add(1, 'day').toDate();
                }

                $scope.data.startdate = null;
                $scope.data.dateOptions.initDate = datePickerDate;
                $scope.data.dateOptions.minDate = datePickerDate;
            });

            $scope.initialize = function () {

                if (initialized) {
                    return;
                }

                var data = $scope.data;

                if (data.item.contract) {
                    var contract = data.item.contract;

                    data.chosenRegisterOption = {
                        id: contract.careType
                    };
                } else {
                    data.steps.unshift({
                        key: 'registerOptions',
                        title: $translate.instant('NEW-CONTRACT.STEP-REGISTER-OPTIONS'),
                        description: 'pp-screen-contract-registeroptions'
                    });
                }

                $scope.setupStep();

                initialized = true;

            };

            $scope.changeStartDate = function () {
                mergePackages();
            };

            $scope.changeTimeslot = function (timeslots, time) {

                var overlapping = time.overlappingTimeslotIds;
                var overlapTime;

                if (overlapping && overlapping.length) {
                    overlapping.forEach(function (overlap) {

                        overlapTime = timeslots.find(x => x.timeslotId == overlap);

                        if (overlapTime) {
                            overlapTime.chosen = false;
                        }

                    });
                }

            };

            $scope.disableDay = function (date, mode) {
                var data = $scope.data;

                var minimal = $scope.getMinimalStartdate();
                date.setHours(0, 0, 0, 0);

                if (minimal > date) {
                    return true;
                } else if (mode === 'day' && data.registerConfig.validStartDays.length) {
                    var possibles = data.registerConfig.validStartDays;
                    return possibles.indexOf(date.getDate()) < 0;
                }
            };

            $scope.getMinimalStartdate = function () {
                var data = $scope.data;
                var start = new Date();
                start.setDate(start.getDate() + 1);
                start.setHours(0, 0, 0, 0);

                if (data.dateOfBirth && data.dateOfBirth > start) {
                    start = new Date(data.dateOfBirth);
                    start.setDate(start.getDate() + 1);
                    start.setHours(0, 0, 0, 0);
                }

                return start;
            };

            $scope.open = function (key, $event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.data[key + 'Opened'] = true;
            };

            $scope.previousStep = function (evt) {
                evt.target.blur();

                $scope.data.currentStep -= 1;
                $scope.setupStep();
            };

            $scope.nextStep = function (evt) {
                evt.target.blur();

                var data = $scope.data;

                if ($scope.finishStep()) {

                    if (data.currentStep == data.steps.length - 1) {
                        $scope.save();
                    } else {
                        data.currentStep += 1;
                        $scope.setupStep();

                        ng.element(evt.currentTarget).closest('.info-overlay-wrapper').scrollTop(0);
                    }

                }
            };

            $scope.setupStep = function () {
                var data = $scope.data;
                var step = data.steps[data.currentStep];

                switch (step.key) {
                    case 'registerOptions':
                        $scope.setupRegisterOptions();
                        break;
                    case 'packages':
                        $scope.setupPackages();
                        break;
                    case 'locations':
                        $scope.setupLocations();
                        break;
                    case 'comments':
                        $scope.setupComments();
                        break;
                }
            };

            $scope.finishStep = function () {
                var data = $scope.data;
                var step = data.steps[data.currentStep];
                var result = false;

                switch (step.key) {
                    case 'registerOptions':
                        result = $scope.finishRegisterOptions();
                        break;
                    case 'packages':
                        result = $scope.finishPackages();
                        break;
                    case 'locations':
                        result = $scope.finishLocations();
                        break;
                    case 'comments':
                        result = $scope.finishComments();
                        break;
                }

                return result;
            };

            $scope.setupRegisterOptions = function () {
                var data = $scope.data;
                var appData = AppService.data;

                data.registerOptions = appData.registerOptions;
                data.schools = appData.schools;
            };

            $scope.setupPackages = function () {
                var data = $scope.data;

                data.packageGroups = null;
                data.registerConfig = null;

                addLoading(loadingKeyPackages);
                var prm = DataService.getRegisterPackagesAsync(data.chosenRegisterOption.id, data.chosenSchool ? data.chosenSchool.id : null, data.item.contact ? data.item.contract.id : null);
                prm.then($scope.handleGetRegisterPackages, $scope.handleGetRegisterPackagesError);
            };

            $scope.setupLocations = function () {

                addLoading(loadingKeyLocations);

                var data = $scope.data;
                var chosenPackages = data.mergedPackages.filter(x => x.chosen == true).map(function (item) {
                    return item.id;
                });
                var schoolId = null;
                if (data.chosenSchool) {
                    schoolId = data.chosenSchool.id;
                }

                data.locations = [];

                var prm = DataService.getRegisterLocationsAsync(chosenPackages, schoolId, data.startdate);
                prm.then($scope.handleGetRegisterLocations, $scope.handleGetRegisterLocationsError);

            };

            $scope.setupComments = function () {

                var data = $scope.data;
                var configQuestions = data.registerConfig.questions;
                var chosenPackages = data.chosenPackages;
                var i, chosenPackage, question;
                var questions = [];

                for (i = 0; i < configQuestions.length; i++) {

                    question = configQuestions[i];

                    if (!question.packageId) {
                        questions.push(question);
                    } else {

                        chosenPackage = chosenPackages[question.packageId];

                        if (chosenPackage && chosenPackage['chosen']) {
                            questions.push(question);
                        }

                    }

                }

                data.configQuestions = questions;
            };

            $scope.finishRegisterOptions = function () {

                var data = $scope.data;
                data.chosenRegisterOptionValid = true;
                data.chosenSchoolValid = true;

                if (!data.chosenRegisterOption) {
                    data.chosenRegisterOptionValid = false;
                } else if (data.chosenRegisterOption.id == ft.app.care.types.outOfSchool && data.schools.length && !data.chosenSchool) {
                    data.chosenSchoolValid = false;
                }

                if (!data.chosenRegisterOptionValid || !data.chosenSchoolValid) {
                    return false;
                } else {
                    return true;
                }
            };

            $scope.finishPackages = function () {

                var data = $scope.data;
                data.startdateValid = true;
                data.chosenPackagesValid = true;

                // startdate must be after today, after date of birth (if given) and on a valid start day (if given)
                var startdate = data.startdate;
                var minimal = $scope.getMinimalStartdate();
                var today = new Date();
                today.setHours(0, 0, 0, 0);

                // validate startdate
                if (startdate < minimal || startdate < today) {
                    data.startdateValid = false;
                } else if (data.registerConfig.validStartDays.length && data.registerConfig.validStartDays.indexOf(startdate.getDate()) < 0) {
                    data.startdateValid = false;
                }

                if (data.dateOfBirth && data.dateOfBirth > data.startdate) {
                    data.startdateValid = false;
                }

                // validate packages
                var i = 0;
                var chosen = false;
                var chosenPackages, chosenTimeslots;

                chosenPackages = data.mergedPackages.filter(function (pack) {
                    if (pack.chosen) {
                        return pack;
                    }
                });

                if (chosenPackages && chosenPackages.length) {

                    while (i < chosenPackages.length && !chosen) {

                        chosenTimeslots = chosenPackages[i].timeslots.filter(function (time) {
                            if (time.chosen) {
                                return time;
                            }
                        });

                        if (chosenTimeslots && chosenTimeslots.length) {
                            chosen = true;
                        }

                        i += 1;
                    }

                }

                if (!chosen) {
                    data.chosenPackagesValid = false;
                }

                if (!data.startdateValid || !data.chosenPackagesValid) {
                    return false;
                }

                return true;
            };

            $scope.finishLocations = function () {
                var data = $scope.data;
                data.chosenLocationsValid = true;

                var i = 0;
                var chosen = false;
                var chosenLocationId;

                while (i < data.chosenLocations.length && !chosen) {

                    chosenLocationId = data.chosenLocations[i]['id'];

                    if (chosenLocationId && data.locations.find(x => x.id == chosenLocationId)) {
                        chosen = true;
                    }

                    i += 1;
                }

                if (!chosen) {
                    data.chosenLocationsValid = false;
                }

                if (!data.chosenLocationsValid) {
                    return false;
                }

                return true;
            };

            $scope.finishComments = function () {

                var valid = true;
                var data = $scope.data;

                if (data.item.contract) {
                    data.questionsValid = valid;
                    return valid; // only questions when new contract
                }

                var configQuestions = data.configQuestions;
                var questions = data.questions || {};
                var i, chosenPackage, configQuestion, question;

                for (i = 0; i < configQuestions.length; i++) {

                    configQuestion = configQuestions[i];
                    question = questions[configQuestion.questionId];

                    if (configQuestion.isRequired && !question) {
                        valid = false;
                    }

                }

                data.questionsValid = valid;
                return valid;

            };

            $scope.handleGetRegisterPackages = function (results) {
                var data = $scope.data;

                data.registerConfig = results;

                mergePackages();
                removeLoading(loadingKeyPackages);
            };

            $scope.handleGetRegisterPackagesError = function (response) {
            };

            $scope.handleGetRegisterLocations = function (results) {

                var data = $scope.data;
                data.locations = results || [];

                if (!data.locations.length) {
                    data.errorMessage = $translate.instant('NEW-CONTRACT.NO-LOCATIONS-AVAILABLE');
                    return;
                }

                if (data.maxLocations > data.locations.length) {
                    data.maxLocations = data.locations.length;
                }

                removeLoading(loadingKeyLocations);

            };

            $scope.handleGetRegisterLocationsError = function (response) {
            };


            $scope.getFilteredLocations = function (idx) {
                var data = $scope.data;

                var selected = data.chosenLocations.filter(function (itm) {
                    return (itm.id);
                });

                if (selected.length == data.locations.length) {
                    return data.locations;
                }

                if (data.chosenLocations[idx].id) {
                    return data.locations;
                }

                var mapped = data.chosenLocations.map(function (itm) {
                    return itm.id;
                });

                var selectedLocations = mapped.toString();
                var locs = data.locations.filter(function (loc) {
                    return selectedLocations.indexOf(loc.id) < 0;
                });

                return locs;

            };

            $scope.initChosenSchool = function () {

                var data = $scope.data;

                if (!data.chosenSchool && data.appData.schools.length) {
                    data.chosenSchool = data.appData.schools[0];
                }

            };

            $scope.distinctChosenLocations = function (idx) {
                var data = $scope.data;
                var chosen = data.chosenLocations[idx].id;
                var i;

                for (i = data.chosenLocations.length - 1; i >= 0; i--) {

                    if (i != idx && data.chosenLocations[i].id && data.chosenLocations[i].id == chosen) {
                        data.chosenLocations[i].id = null;
                    }

                }
            };

            $scope.showFrequencyColumn = function (pack) {
                var data = $scope.data;
                var result = false;
                var i, time, id;
                var chosen = data.chosenPackages[pack.id];

                for (i = 0; i < pack.timeslots.length; i++) {
                    time = pack.timeslots[i];
                    id = time.timeslotId;

                    if (chosen && chosen[id] && chosen[id]['chosen']) {
                        result = true;
                    }
                }

                return result;
            };

            $scope.save = function () {

                var data = $scope.data;
                var obj = {
                    'registrationId': data.registerConfig.registrationId,
                    'selectedPackages': $scope.getPackagesForSave(),
                    'preferredLocations': $scope.getLocationsForSave(),
                    'child': {
                        'id': data.item.child
                    },
                    'contacts': $scope.getContactsForSave(),
                    'start': moment(data.startdate).format(ft.app.datetime.formats.toAPIDate),
                    'remarks': data.comments,
                    'careType': data.chosenRegisterOption.id,
                    'reduceDays': data.reduceDays
                };

                data.saving = true;
                var prm;

                if (!data.item.contract) {
                    obj.answers = $scope.getAnswersForSave();

                    obj.school = data.chosenRegisterOption.id == data.careConfig.types.outOfSchool && data.chosenSchool ? {
                        'id': data.chosenSchool.id,
                        'class': {
                            'id': data.chosenClass ? data.chosenClass : null
                        }
                    } : null;

                    prm = DataService.newContractAsync(obj);
                } else {
                    obj.contractId = data.item.contract.id;

                    prm = DataService.editContractAsync(obj);
                }

                if (prm) {
                    prm.then($scope.handleSave, $scope.handleSaveError);
                    prm.finally(function () {
                        data.saving = false;
                    });
                }

            };

            $scope.handleSave = function (results) {
                var data = $scope.data;
                data.saveEnabled = false;
                data.attempts = 0;

                $rootScope.checkForFormChange = false; // disable form check before navigation
                close({success: true});
            };

            $scope.handleSaveError = function (response) {
                var data = $scope.data;
                data.attempts += 1;

                if (data.attempts > 3) {
                    data.errorMessage = $translate.instant('NEW-CONTRACT.ERROR-CONTINUES');
                } else {
                    data.errorMessage = $translate.instant('NEW-CONTRACT.ERROR-SAVE-CONTRACT');
                }

                if (response && !response.result && response.isReadable) {
                    data.errorMessage += ' ' + response.message;
                }
            };

            $scope.getLocationsForSave = function () {
                var data = $scope.data;
                var result = [];
                var i = 0;

                data.chosenLocations.sort(function (a, b) {
                    return a['preference'] > b['preference'];
                });

                for (i = 0; i < data.chosenLocations.length; i++) {

                    if (data.chosenLocations[i]['id']) {
                        result.push({id: data.chosenLocations[i]['id']});
                    }

                }

                return result;
            };

            $scope.getPackagesForSave = function () {

                var data = $scope.data;
                var result = [];

                var i = 0;
                var resultPackage, chosenPackage;

                var chosenPackages = data.mergedPackages.filter(function (pack) {
                    if (pack.chosen) {
                        return pack;
                    }
                });

                if (chosenPackages && chosenPackages.length) {

                    chosenPackages.forEach(function (chosenPackage) {

                        resultPackage = ng.copy(chosenPackage);
                        resultPackage.holidayBalance = resultPackage.chosenHolidayBalance;
                        resultPackage.timeslots = resultPackage.timeslots.filter(function (time) {
                            if (time.chosen) {
                                return time;
                            }
                        });

                        result.push(resultPackage);

                    });

                }

                /*

                for (i = 0; i < registerPackages.length; i++) {
                    registerPackage = registerPackages[i];

                    var chosenPackage = chosenPackages[registerPackage.id];

                    if (chosenPackage && chosenPackage['chosen']) {

                        obj = null;

                        for (t = 0; t < registerPackage.timeslots.length; t++) {
                            time = chosenPackage[registerPackage.timeslots[t].timeslotId];

                            if (time && time['chosen']) {

                                if (!obj) {
                                    obj = {
                                        id: registerPackage.id,
                                        timeslots: []
                                    }

                                    if (chosenPackage['holidayBalance'])
                                        obj.holidayBalance = chosenPackage['holidayBalance'];

                                    if (data.item.contract)
                                        obj.planningId = chosenPackage.planningId;

                                    result.push(obj);
                                }

                                obj.timeslots.push({
                                    timeslotId: registerPackage.timeslots[t].timeslotId,
                                    frequency: time['frequency'],
                                    extendedHours: time['expanded'] ? true : false
                                });

                            }
                        }

                    }

                }

                // */

                return result;

            };

            $scope.getContactsForSave = function () {
                var data = $scope.data;
                var members = data.appData.members;
                var i = 0;
                var result = [];

                for (i = 0; i < members.length; i++) {
                    result.push({
                        id: members[i].id,
                        roles: members[i].roles
                    });
                }

                return result;
            };

            $scope.getAnswersForSave = function () {
                var data = $scope.data;
                var i = 0;
                var result = [];

                var registerQuestions = data.registerConfig.questions;
                var questions = data.questions || [];
                var registerQuestion, question;

                if (questions.length == 0) {
                    return result;
                }

                for (i = 0; i < registerQuestions.length; i++) {
                    registerQuestion = registerQuestions[i];
                    question = questions[registerQuestion.questionId];

                    if (question) {
                        result.push({
                            questionId: registerQuestion.questionId,
                            answer: question
                        });
                    }

                }

                return result;
            };

            $scope.cancel = function () {
                close();
            };

            $scope.close = function () {
                close({
                    success: !$scope.data.saveEnabled
                });
            };

            var mergePackages = function () {

                var regPack;
                var data = $scope.data;
                var contract = data.item.contract;

                data.mergedPackages = [];

                // setup packages according to what is real

                data.registerConfig.packages.forEach(function (regPackage) {
                    regPack = ng.copy(regPackage);

                    if (data.appData.useHolidayBalance && regPack.holidayBalance && regPack.holidayBalance.length) {
                        regPack.chosenHolidayBalance = regPack.holidayBalance[0].id;
                    }

                    data.mergedPackages.push(regPack);
                });

                // set chosen to false on all other packages (if the user has chosen to go back to step 1)
                // not necessary if mergedPackages is always build up
                //var ids = data.registerConfig.packages.map(function (item) { return item.id; });
                //var key;
                //for (key in data.chosenPackages) {

                //	// package is from other register option
                //	if (ids.indexOf(key) < 0)
                //		data.chosenPackages[key].chosen = false;

                //}

                // setup packages according what is chosen in relation to real

                if (!contract) {
                    return;
                }

                var pack, times, notChosenTimes, slot, idx;
                var date, planningStart, planningEnd;
                date = new Date(moment(data.startdate).format(ft.app.datetime.formats.toAPIDate));

                contract.plannings.forEach(function (planning) {

                    planningStart = new Date(planning.start);
                    planningEnd = new Date(planning.end);

                    if (planningStart <= date && date <= planningEnd) {

                        // get current merged package
                        pack = data.mergedPackages.find(x => x.id == planning.package.id);

                        if (!pack && planning.package.label) {
                            var holidayPack = data.mergedPackages.find(x => x.label && x.label.id === planning.package.label.id);
                            if (holidayPack) {
                                return;
                            }
                        }
                        if (!pack) {

                            pack = ng.copy(planning.package);
                            data.mergedPackages.push(pack);

                        }

                        pack.currentHolidayBalance = data.appData.useHolidayBalance && planning.careType.id == data.careConfig.types.holiday && planning.holidayBalance >= 0 ? planning.holidayBalance : null;
                        pack.chosenHolidayBalance = ng.copy(pack.currentHolidayBalance);
                        pack.chosen = true;

                        planning.timeslots.forEach(function (timeslot) {

                            slot = ng.copy(timeslot);

                            slot.chosen = true;
                            slot.planningId = planning.id;

                            times = pack.timeslots.filter(function (t) {
                                if (t.timeslotId == timeslot.timeslotId) {
                                    return t;
                                }
                            });

                            if (times && times.length) {
                                slot = ng.extend(slot, times[0]);
                                notChosenTimes = times.filter(function (t) {
                                    if (!t.chosen) {
                                        return t;
                                    }
                                });

                                if (notChosenTimes && notChosenTimes.length) {
                                    idx = pack.timeslots.indexOf(notChosenTimes[0]);
                                    pack.timeslots[idx] = slot;
                                } else {
                                    idx = pack.timeslots.indexOf(times[0]);
                                    pack.timeslots.splice(idx, 0, slot);
                                }
                            } else {
                                pack.timeslots.push(slot);
                            }

                        });

                    }

                });

                data.mergedPackages.sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase();
                });

                //var i = 0, t = 0;
                //var planning, pack, time, timeslot;

                //for (i = 0; i < contract.plannings.length; i++) {
                //    planning = contract.plannings[i];

                //    pack = data.chosenPackages[planning.package.id] = {};
                //    pack.chosen = true;
                //    pack.planningId = planning.id;

                //    for (t = 0; t < planning.timeslots.length; t++) {
                //        time = planning.timeslots[t];

                //        timeslot = pack[time.timeslotId] = {};
                //        timeslot.chosen = true;
                //        ng.extend(timeslot, time);
                //    }
                //}

                data.item.contract.plannings.forEach(function (planning) {
                    var item = data.mergedPackages.find(x => x.id == planning.package.id);
                    if (!item) {
                        return true;
                    }

                    item.chosen = true;
                    planning.timeslots.forEach(function (timeslot) {
                        var otherItem = item.timeslots.find(x => x.timeslotId == timeslot.timeslotId);
                        if (otherItem) {
                            otherItem.chosen = true;
                        }
                    });
                });

            };

            $timeout($scope.initialize);

        }
    ]);
